import ProductSelect from "../../../Product/ProductSelect";
import Modal from "../../../Utilities/Modal";
import {useEffect, useState} from "react";
import ApiRoute from "../../../../helper/api";
import ShipmentSelect from "../../../Common/ShipmentSelect";
import PriceField from "../../../Common/PriceField";
import { formatNumberWithDots } from "../../../../helper/general";
import CurrencyInput from "react-currency-input-field";

export default function OrderItemWalletWithdrawModal({order, setUpdateState , closeModal}){
    const [isLoading,setIsLoading] = useState(false)
    const [update,setUpdate] = useState(0)
    const [data , setData] = useState({
        amount:'',

    })

    useEffect(() => {
        const withdrawAmount = Math.min(order.customer.wallet_balance, order.amounts.remaining);
        data.amount = withdrawAmount;
        setUpdate(Date.now());
    }, [order])

    async function handleSubmit(){
        setIsLoading(true)
        const res = await ApiRoute.order.payment.addWalletWithdraw.fn(order._id,data)
        if (res?.status_code === 200){
           setUpdateState(Date.now())
           closeModal()
        }
        setIsLoading(false)
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div className="page-title flex justify-between mb-5">
                <span className="inline-block bg-white pl-3 text-sm text-purple-1">برداشت از کیف پول</span>
{/*                 <button onClick={() => {
                            const withdrawAmount = Math.min(order.customer.wallet_balance, order.amounts.remaining);
                            data.amount = withdrawAmount;
                            setUpdate(Date.now());
                }} className="bg-blue-1 text-white px-1 py-1 rounded-md">محاسبه خودکار</button>    */} 
            </div>

               

            <div className="form">

                <div className="grid grid-cols-2 lg:grid-cols-3 justify-items-stretch mb-1  gap-4">
                    <div className="!grid col-span-full text-[18px]  font-bold">
                        <span className="text-right mb-3 pr-1">موجودی کیف پول: {formatNumberWithDots(order.customer.wallet_balance)} تومان</span>
                    </div>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-3 justify-items-stretch mb-5 text-[18px] font-bold gap-4">
                    <div className="!grid col-span-full">
                        <span className="text-right mb-3 pr-1">مقدار قابل برداشت: {formatNumberWithDots(data.amount)} تومان</span>
                    </div>
                </div>

               

{/*                 <div className="grid grid-cols-2 lg:grid-cols-3 justify-items-stretch mb-5 gap-4">
                                        
                    <label className="!grid col-span-full">
                                    <span className="flex items-center mb-3">
                                    <span className="text-right pr-1">مبلغ</span>
                                    </span>
           
                        <CurrencyInput
                            disabled={false}
                            className={`input-x !text-left `}
                            dir={`ltr`}
                            placeholder="مقدار را وارد کنید"
                            defaultValue={data.amount}
                            value={data.amount}
                            decimalsLimit={3}
                            min={10000}
                            onValueChange={(value, name, values) => {
                                data.amount = parseInt(value)
                                setUpdate(Date.now())
                            }}
                        />
                    </label>

                </div> */}

                <div className="grid grid-cols-8 items-end gap-4">
                    <div className="col-span-2">
                        <button onClick={handleSubmit} className="btn-purple py-2 w-full" 
                        disabled={isLoading || !data.amount || !order.customer.wallet_balance}>{isLoading ? 'در حال انتظار...' : 'برداشت'}</button>
                    </div>
                </div>

            </div>
        </Modal>
    )
}