import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faTruckFast,
    faTags,
    faLink,
    faWarehouse,
    faChevronDown,
    faCopy,
    faBarsStaggered
} from '@fortawesome/free-solid-svg-icons'
import {faCalendar} from '@fortawesome/free-regular-svg-icons'
import {Link} from 'react-router-dom';
import {useState} from "react";
import Modal from "./Utilities/Modal";

import DynamicTabComponent from "./Utilities/DynamicTabComponent";
import {toggleSidebar} from "./ReduxComponents/action";
import {connect} from 'react-redux';
import Toman from "./Utilities/Toman";
import useToken from "../useToken";
import QuickInventoryList from "./Common/TobBar/QuickInventoryList";
import QuickCouponCheck from "./Common/TobBar/QuickCouponCheck";
import CreateUtm from "./Common/TobBar/CreateUtm";
import QuickShippingClassCheck from "./Common/TobBar/QuickShippingClassCheck";
import QuickDeliveryTimeCheck from "./Common/TobBar/QuickDeliveryTimeCheck";
import AlertStockStats from "./Common/TobBar/AlertStockStats";
import {isPrimaryShop} from "../helper/api";
import {HasAccessComponent} from "../helper/permissions";
import IntegrateTaskStats from './Common/TobBar/IntegrateTaskStats';
import SearchTextInPageAndProductsContent from './Common/TobBar/SearchTextInPageAndProductsContent';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

function TopBar({sidebarToggle, onToggleSidebar}) {
    const { token,resetToken } = useToken();
    const [showAdminMenu, setShowAdminMenu] = useState(false)
    const menuToggle = () => {
        setShowAdminMenu(!showAdminMenu);
    }
    const [isModalOpen, setIsModalOpen] = useState(null);
    const openModal = (name) => setIsModalOpen(name)
    const closeModal = () => setIsModalOpen(null)
    return (
        <>
            <div className="grid lg:flex lg:justify-between bg-gray-1 h-[60px] lg:pr-[100px] fixed top-0 w-full z-30 mx-auto">
                <div
                    className="flex flex-row-reverse lg:flex-row gap-10 items-center justify-between order-2 lg:order-1 px-8 lg:px-5">
                    <Link className="py-2 lg:py-4 block w-max" to="/"><img src="/images/logo.jpg"
                                                                           className="w-[120px] lg:w-[163px]"
                                                                           alt="logo"/></Link>
                    <button onClick={onToggleSidebar}>
                        <FontAwesomeIcon className="text-gray-3 text-lg lg:text-2xl hover:text-purple-1"
                                         icon={faBarsStaggered}/>
                    </button>
                    {!isPrimaryShop() && <div
                        className="py-2 lg:py-4 text-[14px] font-bold block w-max">{token?.employee?.shop_info?.title}</div>}
                    {/*{sidebarToggle ? 'Open' : 'Closed'}*/}
                </div>
                <div
                    className='flex gap-6 items-center justify-center lg:justify-end order-1 lg:order-2 px-5 py-1 bg-gray-7 lg:bg-gray-1'>

                    <HasAccessComponent permission={`search_text_in_page_and_products_content`} children={ <button onClick={() => openModal('searchTextInPageAndProductsContent')}>
                        <FontAwesomeIcon className='text-purple-4 hover:text-purple-3 text-md lg:text-xl'
                                         icon={faSearchengin}/>
                    </button> }/>


                    <HasAccessComponent permission={`delivery_check`} children={ <button onClick={() => openModal('quickDeliveryTimeCheck')}>
                        <FontAwesomeIcon className='text-purple-4 hover:text-purple-3 text-md lg:text-xl'
                                         icon={faCalendar}/>
                    </button> }/>

                    <HasAccessComponent permission={`shipment_check`} children={ <button onClick={() => openModal('quickShippingClassCheck')}>
                        <FontAwesomeIcon className='text-purple-4 hover:text-purple-3 text-md lg:text-xl'
                                         icon={faTruckFast}/>
                    </button> }/>

                    <HasAccessComponent permission={`discount_check`} children={ isPrimaryShop() && <button onClick={() => openModal('quickCouponCheck')}>
                        <FontAwesomeIcon className='text-purple-4 hover:text-purple-3 text-md lg:text-xl'
                                         icon={faTags}/>
                    </button> }/>

                    <HasAccessComponent permission={`utm`} children={ isPrimaryShop() && <button onClick={() => openModal('createUtm')}>
                        <FontAwesomeIcon className='text-purple-4 hover:text-purple-3 text-md lg:text-xl'
                                         icon={faLink}/>
                    </button> }/>

                    <HasAccessComponent permission={`quick_inventory`} children={ isPrimaryShop() && <button onClick={() => openModal('searchInventory')}>
                        <FontAwesomeIcon className='text-purple-4 hover:text-purple-3 text-md lg:text-xl'
                                         icon={faWarehouse}/>
                    </button> }/>

                    <HasAccessComponent permission={`integrate`} children={ isPrimaryShop() && <IntegrateTaskStats/> }/>
                    <HasAccessComponent permission={`alerts`} children={ isPrimaryShop() && <AlertStockStats/> }/>

                    <div className='text-sm relative cursor-pointer group' onClick={menuToggle}>
                        <span className='hidden lg:block text-purple-2'>{token?.employee?.role?.label}</span>
                        <button className='text-gray-2 flex items-center group-hover:text-purple-1'>
                            {token?.employee?.name}
                            <FontAwesomeIcon className='text-gray-2 group-hover:text-purple-1 text-9 lg:text-xs mr-2'
                                             icon={faChevronDown}/>
                        </button>
                        <ul className={`dropDownMenu ${showAdminMenu ? 'show' : ''}`}>
                            <li className="pt-3 px-4"><Link to={`/profile`} className="hover:text-purple-1">مشاهده پروفایل</Link>
                            </li>
                            <li className="py-2 px-4"><button onClick={ () => {
                                resetToken()
                                window.location.replace("/")
                            } } className="hover:text-purple-1">خروج</button></li>
                        </ul>
                    </div>
                </div>
                <div className={`fixed w-screen h-screen ${showAdminMenu ? 'block' : 'hidden'}`}
                     onClick={menuToggle}></div>
            </div>

            {/*------------Modals------------*/}
            {isModalOpen === 'searchTextInPageAndProductsContent' && <SearchTextInPageAndProductsContent closeModal={ () => setIsModalOpen(null) }/>}
            {isModalOpen === 'quickDeliveryTimeCheck' && <QuickDeliveryTimeCheck closeModal={ () => setIsModalOpen(null) }/>}
            {isModalOpen === 'quickShippingClassCheck' && <QuickShippingClassCheck closeModal={ () => setIsModalOpen(null) }/>}

            {isModalOpen === 'createUtm' && <CreateUtm closeModal={ () => setIsModalOpen(null) }/>}
            {isModalOpen === 'searchInventory' && <QuickInventoryList closeModal={ () => setIsModalOpen(null) }/>}
            {isModalOpen === 'quickCouponCheck' && <QuickCouponCheck closeModal={ () => setIsModalOpen(null) }/>}

        </>
    );
}

const mapStateToProps = state => ({
    sidebarToggle: state.toggle,
});

const mapDispatchToProps = dispatch => ({
    onToggleSidebar: () => dispatch(toggleSidebar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);