import SwitchToggle from "../Utilities/SwitchToggle";
import {useEffect, useState} from "react";
import CategorySelect from "../Product/Category/CategorySelect";
import BrandSelect from "../Product/Category/BrandSelect";
import SelectCoordinates from "../Coordinates/SelectCoordinates";
import TransportSelect from "../Common/TransportSelect";
import ShopSelect from "../Shop/ShopSelect";
import EmployeeSelect from "../Common/EmployeeSelect";

export default function FilterOrder({SetFilterQuery,asStore = false,asShipping = false,status = false}) {

    const [query, setQuery] = useState({});
    const [filter, setFilter] = useState({});
    const [updateData, setUpdateData] = useState('0')
    const [applyFilter, setApplyFilter] = useState(false)

    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);
    const [coordinate, setCoordinate] = useState([])

    useEffect(() => {
        let arr = []
        selectedCategory.map(category => arr.push(category.slug))
        changeQuery(arr, "category")
    }, [selectedCategory]);

    useEffect(() => {
        let arr = []
        selectedBrand.map(brand => arr.push(brand.value))
        changeQuery(arr, "brand")
    }, [selectedBrand]);

    useEffect(() => {
        setFilter(query)
    }, [query]);

    useEffect(() => {
        SetFilterQuery(filter)
    }, [updateData]);


    function changeQuery(value, name) {
        query[name] = value;
        setQuery(query)
    }

    return (
        <div className={`show`}>
            <div className="pt-3 pb-4">
                <div className="main-content p-3">
                    <div
                        className="grid-form text-xs grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-9 gap-3 2xl:gap-4 2xl:gap-y-5">
                        <div>
                            <label>
                                <span className="block">حواله</span>
                                <select
                                    onChange={(e) => {
                                        changeQuery(e.target.value, "transference_status")
                                    }}
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="submitted">ثبت شده</option>
                                    <option value="not_submitted">ثبت نشده</option>
                                    <option value="reset">ریست شده</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">فاکتور</span>
                                <select
                                    onChange={(e) => {
                                        changeQuery(e.target.value, "factor_status")
                                    }}
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="submitted">ثبت شده</option>
                                    <option value="not_submitted">ثبت نشده</option>
                                    <option value="reset">ریست شده</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">باربری</span>
                                <TransportSelect multiple={false} assignItem={ (v) => {
                                    changeQuery(v?.title, "transport_title")
                                } }/>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">نوع ثبت سفارش</span>
                                <select
                                    onChange={(e) => {
                                        changeQuery(e.target.value, "order_type")
                                    }}
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="n">سفارشات</option>
                                    <option value="y">پیش سفارشات</option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">نوع سفارش</span>
                                <select onChange={(e) => {
                                    changeQuery(e.target.value, "order_state")
                                }} className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="n">حقیقی</option>
                                    <option value="y">حقوقی</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">تایید انبار</span>
                                <select onChange={(e) => {
                                    changeQuery(e.target.value, "inventory_is_confirm")
                                }} className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">تایید حسابدار</span>
                                <select onChange={(e) => {
                                    changeQuery(e.target.value, "accountants_confirmation")
                                }} className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">تایید حقوقی</span>
                                <select onChange={(e) => {
                                    changeQuery(e.target.value, "official_confirmation")
                                }} className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">روش پرداخت</span>
                                <select onChange={(e) => {
                                    changeQuery(e.target.value, "payment_method_inline")
                                }} className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="shaparak">آنلاین</option>
                                    <option value="cash_on_delivery">در محل</option>
                                    <option value="ezpay">ایزی پی</option>
                                    <option value="snapp_pay">اسنپ پی</option>
                                    <option value="deposit">شناسه</option>
                                    <option value="cheque">پرداخت با چک</option>
                                    <option value="debit_card">واريز وجه يا کارت به کارت</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">مانده سفارش</span>
                                <select onChange={(e) => {
                                    changeQuery(e.target.value, "order_remaining")
                                }} className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">حضوری/ارسالی</span>
                                <select onChange={(e) => {
                                    changeQuery(e.target.value, "is_shipping_in_person")
                                }} className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="n">ارسالی</option>
                                    <option value="y">حضوری</option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">اعتبار بازگشتی</span>
                                <select onChange={(e) => {
                                    changeQuery(e.target.value, "has_reward_credit")
                                }} className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>


                        {/*relations*/}
                        <div>
                            <label>
                                <span className="block mb-1">موقعیت</span>
                            </label>
                            <SelectCoordinates multiple={false} SetCoordinate={ (v) => {
                                changeQuery(v, "coordinate")
                            } }/>

                        </div>

                        {asStore && <div>
                            <label>
                                <span className="block mb-1">فروشگاه</span>
                            </label>
                            <ShopSelect multiple={false} assignItem={(v) => {
                               changeQuery(v?._id ?? '', "shop_id")
                            }}/>

                        </div>}

                        <div>
                            <label>
                                <span className="block mb-1">ویزیتور</span>
                            </label>
                            <EmployeeSelect asRole="order_operator" multiple={false} assignItem={ (s) => {
                                changeQuery('', "operator_id")
                                s.map( employee => {
                                    changeQuery(employee._id, "operator_id")
                                } )
                            } }/>
                        </div>
                        {/*relations*/}

                        {status && <div>
                            <label>
                                <span className="block">وضعیت</span>
                                <select onChange={(e) => {
                                    changeQuery(e.target.value, "status")
                                }} className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">پیشفرض</option>
                                    <option value="process">در حال انجام</option>
                                    <option value="shipment">در حال ارسال</option>
                                </select>
                            </label>
                        </div>}

                        {asShipping && <div>
                            <label>
                                <span className="block">وضعیت انبار ایران</span>
                                <select onChange={(e) => {
                                    changeQuery(e.target.value, "iran_stock_status")
                                }} className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">منفی</option>
                                </select>
                            </label>
                        </div>}

                        <div className="flex items-end">
                            <button
                                onClick={e => {
                                    setApplyFilter(true)
                                    setUpdateData(Date.now().toString())
                                }}
                                className="btn-orange w-full py-2">اعمال فیلتر
                            </button>

                            {applyFilter && (
                                <button
                                    onClick={() => {
                                        window.location.reload()
                                        setApplyFilter(false)
                                    }}
                                    className="btn-gray w-full py-2 mr-2"
                                >
                                    حذف فیلتر
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}