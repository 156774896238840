import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faRectangleList} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import { formatNumberWithDots } from "../../../helper/general";

export default function OrderUserInfo({order, setUpdateState}){
    const customer = order.customer
    return (
        <div
            className="main-content before-table-style before-purple border border-gray-7 relative rounded-md p-5 mt-4">
            <div className="page-title mb-3">
                                <span className="inline-block bg-white pl-3 text-sm">
                                    اطلاعات مشتری
                                <Link to={`/customers/view/${customer._id}`}><FontAwesomeIcon className="mr-2 text-green-1" icon={faEdit}/></Link>
                                <Link className="mx-1" target="_blank" to={`/orders?customer_id=${customer._id}`}  title="سفارشات"><FontAwesomeIcon className="text-blue-1 mr-3" icon={faRectangleList} /></Link>

                                {customer.is_foreigner && <span className={`inline-block w-max mr-2 text-xs px-3 !text-[#b53f3f] green-tag !bg-[#b53f3f3d]`}>
                                    اتباع
                                </span>}

                                </span>
            </div>


            <div
                className="grid sm:grid-cols-2 xl:flex justify-between gap-3 lg:gap-5 text-xs mt-5">
                <div>
                    <span className="text-gray-2">نام مشتری:</span>
                    <span className="text-gray-5 mr-1">{customer.full_name}</span>
                </div>

                {!customer.is_foreigner && <div>
                    <span className="text-gray-2">کد ملی:</span>
                    <span className="text-gray-5 mr-1">{customer.personal.national_code}</span>
                </div>}

                {customer.is_foreigner && <div>
                    <span className="text-gray-2">کد اتباع:</span>
                    <span className="text-gray-5 mr-1">{customer.personal.foreigner_code}</span>
                </div>}

                <div>
                    <span className="text-gray-2">تاریخ تولد:</span>
                    <span className="text-gray-5 mr-1">{customer.personal.age}</span>
                </div>

                <div>
                    <span className="text-gray-2">شماره همراه:</span>
                    <span className="text-gray-5 mr-1">{customer.contacts.cellphone}</span>
                </div>

                <div>
                    <span className="text-gray-2">شماره اضطراری:</span>
                    <span className="text-gray-5 mr-1">{customer.contacts.emergency_phone}</span>
                </div>

                <div>
                    <span className="text-gray-2">شناسه آسان:</span>
                    <span className="text-gray-5 mr-1">{customer.asan_id}</span>
                </div>

                <div>
                    <span className="text-blue-1">کیف پول:</span>
                    <span className="text-blue-1 mr-1">{formatNumberWithDots(customer.wallet_balance)} ت</span>
                </div>

            </div>
        </div>
    )
}