import SwitchToggle from "../Utilities/SwitchToggle";
import {useState} from "react";
import CategorySelect from "../Product/Category/CategorySelect";
import BrandSelect from "../Product/Category/BrandSelect";
import SelectCoordinates from "../Coordinates/SelectCoordinates";
import TransportSelect from "../Common/TransportSelect";
import ShopSelect from "../Shop/ShopSelect";
import EmployeeSelect from "../Common/EmployeeSelect";

export default function FilterCoupon({SetFilterQuery, asStore = false, status = false}) {
    // فقط یک state برای نگهداری مقادیر فیلتر
    const [filter, setFilter] = useState({});
    const [applyFilter, setApplyFilter] = useState(false);

    // تغییر مقادیر فیلتر بدون اعمال آن‌ها
    const changeQuery = (value, name) => {
        setFilter(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // فقط با کلیک روی دکمه اعمال فیلتر، فیلترها اعمال می‌شوند
    const handleApplyFilter = () => {
        setApplyFilter(true);
        SetFilterQuery(filter);
    };

    // ریست کردن فیلترها
    const handleResetFilter = () => {
        setFilter({});
        setApplyFilter(false);
        SetFilterQuery({});
    };

    return (
        <div className={`show`}>
            <div className="pt-3 pb-4">
                <div className="main-content p-3">
                    <div
                        className="grid-form text-xs grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-9 gap-3 2xl:gap-4 2xl:gap-y-5">
                        
                        <div>
                            <label>
                                <span className="block">وضعیت</span>
                                <select 
                                    onChange={(e) => changeQuery(e.target.value, "status")} 
                                    className="bordered-input-7 bg-gray-1 w-full mt-1"
                                    value={filter.status || ""}
                                >
                                    <option value="">همه</option>
                                    <option value="y">فعال</option>
                                    <option value="n">غیرفعال</option>
                                </select>
                            </label>
                        </div>
                       
                        <div className="flex items-end">
                            <button
                                onClick={handleApplyFilter}
                                className="btn-orange w-full py-2">اعمال فیلتر
                            </button>

                            {applyFilter && (
                                <button
                                    onClick={handleResetFilter}
                                    className="btn-gray w-full py-2 mr-2"
                                >
                                    حذف فیلتر
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}