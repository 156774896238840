import {useEffect, useState} from "react";
import Layout from "../../components/Layout";
import {Link, useNavigate} from "react-router-dom";
import SearchBox from "../../components/SearchBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faFilter, faSpinner, faTrash} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/Pagination";
import ShipmentClassTable from "../../components/Shipment/Table/ShipmentClassTable";
import ApiRoute, {shopId} from "../../helper/api";
import Modal from "../../components/Utilities/Modal";
import ShipmentClassFilterBox from "../../components/Shipment/ShipmentClassFilterBox";
import ReservationTable from "../../components/Reservation/ReservationTable";
import ProductSelect from "../../components/Product/ProductSelect";


export default function Reservation() {

    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [title, setTitle] = useState('')
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'cellphone'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setLoading(true)
            const  res  = await ApiRoute.reservation.index.fn({
                shop_id: shopId(),
                page : pageNumber,
                model:'\\App\\ReservationManager\\Model\\ReservationExpended',
                product_id: params.has('product_id') ? params.get('product_id') : ''
            },filterQuery)
            setData(res)
            setLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);
    // watch filterQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    async function handleCreate() {
        const res = await ApiRoute.shipment.classes.create.fn( {
            title: title,
        })
        if (res.results){
            setSearchQuery({
                term : res.results.title,
                by: ''
            })
            setCreateModal(false)
        }
    }


    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <a href="/reservations" className="page-title text-purple-4 font-bold flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">رزرو ها</span>
                    </a>
                </div>


                <div className="main-content p-3 mt-3">
                    {loading ? (
                                           <div className="flex justify-center items-center py-10">
                                           <FontAwesomeIcon icon={faSpinner} spin className="text-3xl text-purple-600" />
                                       </div>
                    ) : (
                        <>
                            <ReservationTable SetUpdateData={setUpdateData} params={params} tableData={tableData}/>
                            <Pagination Data={paginationData} SetPageNumber={setPageNumber} />
                        </>
                    )}
                </div>


            </div>
        </Layout>
    )
}