import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import Toman from "../../Utilities/Toman";
import {formatNumberWithDots, getDateAsReadableFormat} from "../../../helper/general";
import {useEffect, useState} from "react";
import ApiRoute from "../../../helper/api";

export default function OrderAmounts({order, setUpdateState}){
    let [onlinePaid,setOnlinePaid] = useState(0)
    const [orderProductsWithRewardCredit, setOrderProductsWithRewardCredit] = useState(null)
    const [hasRewardCredits, setHasRewardCredits] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [transaction_history, setTransactionHistory] = useState([])

    useEffect(() => {
        order.payment_transactions?.length > 0 && order.payment_transactions
            .map(item => {
                if (  item.is_verify && item.payment_method === "shaparak" ){
                    setOnlinePaid(item.amount)
                }
            } )

    }, []);

    useEffect(() => {
        ( async () => {
            const response = await ApiRoute.order.getTransactionHistory.fn(order._id)
            if(response?.status_code === 200){
                setTransactionHistory(response?.results)
            }
        } )()
    }, [order])

    useEffect(() => {
        let hasRewardCredits = false
        order.items?.length > 0 && order.items
            .filter(item => 
                item.item_type === "product" && 
                item.item_product?.prices?.reward_credit > 0
            )
            .map(item => {
                hasRewardCredits = true
                setHasRewardCredits(true)
            } )

        if (hasRewardCredits){
            getOrderProductsWithRewardCredit()
        }
    }, []);

    function getTransactionDate(transaction) {
        const metadataDate = transaction?.metadata?.date;
        return getDateAsReadableFormat(metadataDate || transaction.created_at, true);
    }

    function payment_gateway(transaction) {
        let gateway = '';
        switch (transaction.payment_gateway) {
            case "PaymentGatewaySadad":
                gateway = "درگاه سداد";
                break;
            case "PaymentGatewayMellat":
                gateway = "درگاه ملت";
                break;
            case "PaymentGatewayBankDeposit":
                gateway = "واریز وجه";
                break;
            case "PaymentGatewayWalletWithdraw":
                gateway = "کیف پول";
                break;
            case "PaymentGatewaySnappPay":
                gateway = "اسنپ پی";
                break;
            default:
                gateway = transaction.payment_gateway;
        }
        if(transaction?.metadata?.extract_type === "credit"){
            gateway += " (اعتباری)";
        }
        return gateway;
    }


    async function getOrderProductsWithRewardCredit() {
        setIsLoading(true)
        const response = await ApiRoute.order.getOrderProductsWithRewardCredit.fn(order._id)
        if(response?.status_code === 200){
            setOrderProductsWithRewardCredit(response?.results)
        }else{
            setOrderProductsWithRewardCredit(null)
        }
        setIsLoading(false)
    }

    return (
        <div>
            <div
                className="grid sm:grid-cols-2 lg:grid-cols-3 2xl:flex gap-2 justify-between border border-purple-1 rounded-md bg-gray-7 text-sm p-3 mt-4">
                <div className="flex items-center">
                    <span className="text-gray-2">جمع کل:</span>
                    <span className="text-gray-5 mr-1">{formatNumberWithDots(order.amounts.total)}</span>
                    <Toman customClass={'fill-gray-5 mr-0.5'}/>
                </div>
                <div className="flex items-center">
                    <span className="text-gray-2">کسورات:</span>
                    <span className="text-gray-5 mr-1">{formatNumberWithDots(order.amounts.subtraction)}</span>
                    <Toman customClass={'fill-gray-5 mr-0.5'}/>
                </div>
                <div className="flex items-center">
                    <span className="text-gray-2">مانده:</span>
                    <span className="text-gray-5 mr-1">{formatNumberWithDots(order.amounts.remaining)}</span>
                    <Toman customClass={'fill-gray-5 mr-0.5'}/>
                </div>
                <div className="flex items-center">
                    <span className="text-gray-2">آنلاین:</span>
                    <span className="text-gray-5 mr-1">{onlinePaid}</span>
                    <Toman customClass={'fill-gray-5 mr-0.5'}/>
                </div>
                <div className="flex items-center">
                    <span className="text-gray-2">کیف پول:</span>
                    <span className="text-gray-5 mr-1">{formatNumberWithDots(order.amounts.wallet)}</span>
                    <Toman customClass={'fill-gray-5 mr-0.5'}/>
                </div>
                <div className="flex items-center">
                    <span className="text-gray-2">بستانکار:</span>
                    <span className="text-gray-5 mr-1">{formatNumberWithDots(order?.amounts?.creditor)}</span>
                    <Toman customClass={'fill-gray-5 mr-0.5'}/>
                </div>
            </div>


            {order.items.filter(item => item.item_type === "wallet").map(item => {
                return (
                    <div className="border border-red-1 text-red-1 p-2 mt-3 text-sm rounded-md">
                        در حال استفاده از کیف پول به مبلغ {formatNumberWithDots(item.item_value)} تومان (نهایی نشده است)
                    </div>
                )
            })}

            <div className={`flex gap-2 mt-4 text-13 flex-col`}>
                <div className={`text-[14px] font-bold`}>تراکنش ها / پرداخت ها</div>
                {order?.payment_transactions && order.payment_transactions.map( (transaction) => <div className={`flex flex-col lg:flex-row flex-wrap border rounded p-2 gap-2`}>
                    <div className={`flex items-center gap-2`}>
                        <span>روش پرداخت:</span>
                        <span>{payment_gateway(transaction)}</span>
                        {transaction?.metadata?.bank_account?.title && <span> | {transaction?.metadata?.bank_account?.title}</span>}
                    </div>
                    <span className={`hidden lg:block`}>|</span>
                    <div className={`flex gap-2`}>
                        <span>مقدار</span>
                        <span>{formatNumberWithDots(transaction.amount)}</span>
                    </div>
                    <span className={`hidden lg:block`}>|</span>
                    <div className={`flex gap-2`}>
                        <span>شماره تراکنش</span>
                        <span>{transaction.transaction_id}</span>
                    </div>
                    <span className={`hidden lg:block`}>|</span>
                    <div className={`flex gap-2`}>
                        <span>شماره پیگیری</span>
                        <span>{transaction.reference_id}</span>
                    </div>
                    <span className={`hidden lg:block`}>|</span>
                    <div className={`flex gap-2`}>
                        <span>تاریخ و ساعت</span>
                        <span className={`text-[12px]`}>{getTransactionDate(transaction)}</span>
                    </div>
                    <span className={`hidden lg:block`}>|</span>
                    <div className={`flex gap-2 items-center`}>
                        { transaction?.metadata?.sub_type === 'prepayment' && <span className={`purple-tag text-[11px] py-0 px-0 text-center`}>پیش پرداخت آنلاین</span> }
                        { transaction.is_verify && <span className={`green-tag text-[11px] py-0 px-0 text-center`}>پرداخت شده</span> }
                        { !transaction.is_verify && <span className={`orange-tag text-[11px] py-0 px-0 text-center`}>پرداخت نشده</span> }
                    </div>

                    {(transaction.is_verify && transaction.payment_method !== "shaparak" && transaction.payment_method !== "wallet_withdraw" && !order.is_transference) && <button onClick={ async () => {
                        if (window.confirm('آیا مطمئن هستید که می‌خواهید این تراکنش را حذف کنید؟')) {
                            await ApiRoute.order.deleteTransaction.fn(order._id, transaction._id)
                            setUpdateState(Date.now())
                        }
                    } } className={`btn-gray bg-red-2 py-1 mr-auto  text-xs w-max`}>حذف</button>}

                </div> )}
            </div>

            <div className={`flex gap-2 mt-4 text-13 flex-col`}>
                <div className={`text-[14px] font-bold`}>تاریخچه پرداختی ها</div>
                {transaction_history && transaction_history.map( (transaction) => <div className={`flex flex-col lg:flex-row flex-wrap border rounded p-2 gap-2`}>
                    <div className={`flex items-center gap-2`}>
                        <span>روش پرداخت:</span>
                        <span>{payment_gateway(transaction)}</span>
                        {transaction?.metadata?.bank_account?.title && <span> | {transaction?.metadata?.bank_account?.title}</span>}
                    </div>
                    <span className={`hidden lg:block`}>|</span>
                    <div className={`flex gap-2`}>
                        <span>مقدار</span>
                        <span>{formatNumberWithDots(transaction.amount)}</span>
                    </div>
                    <span className={`hidden lg:block`}>|</span>
                    <div className={`flex gap-2`}>
                        <span>شماره تراکنش</span>
                        <span>{transaction.transaction_id}</span>
                    </div>
                    <span className={`hidden lg:block`}>|</span>
                    <div className={`flex gap-2`}>
                        <span>شماره پیگیری</span>
                        <span>{transaction.reference_id}</span>
                    </div>
                    <span className={`hidden lg:block`}>|</span>
                    <div className={`flex gap-2`}>
                        <span>تاریخ و ساعت</span>
                        <span className={`text-[12px]`}>{getTransactionDate(transaction)}</span>
                    </div>
                    <span className={`hidden lg:block`}>|</span>
                    <div className={`flex gap-2 items-center`}>
                        { transaction?.metadata?.sub_type === 'prepayment' && <span className={`purple-tag text-[11px] py-0 px-0 text-center`}>پیش پرداخت آنلاین</span> }
                        { transaction.is_verify && <span className={`green-tag text-[11px] py-0 px-0 text-center`}>پرداخت شده</span> }
                        { !transaction.is_verify && <span className={`orange-tag text-[11px] py-0 px-0 text-center`}>پرداخت نشده</span> }
                    </div>

                </div> )}
            </div>

            <div className={`flex gap-2 mt-4 text-13 flex-col`}>
                <div className={`text-[14px] font-bold`}>اعتبار بازگشتی خرید محصول</div>
                {orderProductsWithRewardCredit && orderProductsWithRewardCredit.map(item => 
                        <div className={`flex flex-col lg:flex-row flex-wrap border rounded p-2 gap-2`}>
                            <div className={`flex items-center gap-2`}>
                                <span>نام محصول:</span>
                                <span>{item.item_product.title}</span>
                            </div>
                            <span className={`hidden lg:block`}>|</span>
                            <div className={`flex gap-2`}>
                                <span>مقدار اعتبار:</span>
                                <span>{formatNumberWithDots(item.item_product.prices.reward_credit)}</span>
                                <Toman customClass={'fill-gray-5 mr-0.5'}/>
                            </div>
                            <span className={`hidden lg:block`}>|</span>
                            <div className={`flex gap-2`}>
                                <span>وضعیت:</span>
                                {item?.item_reward?.status === "waiting" && <span className={`orange-tag text-[11px] py-0 px-0 text-center`}>در صف تخصیص</span>}
                                {item?.item_reward?.status === "completed" && <span className={`green-tag text-[11px] py-0 px-0 text-center`}>تخصیص یافته</span>}
                                {item?.item_reward?.status === "pending" && <span className={`red-tag text-[11px] py-0 px-0 text-center`}>در انتظار رویداد</span>}
                            </div>
                            <span className={`hidden lg:block`}>|</span>
                            <div className={`flex gap-2`}>
                                <span>تاریخ ثبت:</span>
                                <span className={`text-[12px]`}>{getDateAsReadableFormat(item?.item_reward?.created_at,true)}</span>

                            </div>

        
                        </div>
                    )
                }
                {!hasRewardCredits && <div className={`text-gray-5 border rounded p-2`}>اعتبار بازگشتی وجود ندارد</div>}
                {isLoading && <div className={`text-gray-5 border rounded p-2`}>در حال دریافت اطلاعات...</div>}
            </div>

        </div>
    )
}