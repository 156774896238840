import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRemove} from "@fortawesome/free-solid-svg-icons/faRemove";
import { getDateAsReadableFormat } from '../../helper/general';
import { useState } from 'react';
import ApiRoute from '../../helper/api';
import { Link } from 'react-router-dom';


export default function ReservationTable(props) {
    const tableTitles = [
        'محصول',
        'مشتری',
        'سفارش',
        'تعداد',
        'تاریخ',
    ]
    const [editModal,setEditModal] = useState(false)
    const [selected,setSelected] = useState([])

    function changeSelectedItem(value , name , tableData){
        if (!selected[tableData.uid]){
            selected[tableData.uid] = [];
        }
        selected[tableData.uid][name] = value;
        setSelected(selected)

    }

    async function deleteHandle(reservation){
        window.confirm('آیا از حذف این رزرو مطمئن هستید؟') && await ApiRoute.reservation.delete.fn(reservation._id) && props.SetUpdateData(Date.now().toString())
    }



    return (
        <>
            <table className="coupon-table table-auto w-full mt-4 block sm:table">
                <thead>
                <tr className="border-b border-b-gray-7">
                    {tableTitles.map((item, id) => (
                        <td className="text-13 text-right text-gray-6 pb-2" key={id}>{item}</td>
                    ))}
                    <td></td>
                </tr>
                </thead>
                <tbody>
                {
                    props.tableData.map((tableData, id) => (<tr className={`text-xs py-2`} key={id}>

                            <td className={`text-right`}><Link target="_blank" to={`/inventory?id=${tableData.product_id}`}>{tableData.product.title}</Link></td>
                            <td className={`text-right`}><Link target="_blank" to={`/customers/view/${tableData.customer_id}`}>{tableData.customer.name}</Link></td>
                            <td className={`text-right`}><Link target="_blank" to={`/orders/edit?id=${tableData.order_id}`}>{tableData.order?.order_key}</Link></td>
                            <td className={`text-right`}>{tableData.quantity}</td>
                            <td className={`text-right`}>{getDateAsReadableFormat(tableData.created_at)}</td>
                            <td className="flex justify-center items-center py-3">

                                <button onClick={async () => {await deleteHandle(tableData)}} className="mx-2" title="حذف"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faRemove}/></button>
                            </td>

                        </tr>)
                    )}
                </tbody>
            </table>

        </>
    )
}