import Layout from "../../components/Layout";
import {useState} from "react";
import ApiRoute from "../../helper/api";
import Swal from "sweetalert2";
import SelectCoordinates from "../../components/Coordinates/SelectCoordinates";
import {redirect} from "react-router-dom";
import {DatePicker} from "zaman";
import moment from "jalali-moment";
import {faNumberToEn} from "../../helper/general";

function CreateCustomer() {
    const [isForeigner, setIsForeigner] = useState(false)
    const [isLoading , setIsLoading] = useState(false)
    const [selectedTab , setSelectedTab] = useState('natural')
    const [info , setInfo] = useState({
        first_name : '',
        last_name : '',
        national_code: '',
        foreigner_code: '',
        is_foreigner: false,
        age: '',
        cellphone : '',
        emergency_phone:'',
        is_legal: selectedTab == 'legal',
        legal_info: {
            org_name: '',
            phone:'',
            national_number:'',
            economic_code:'',
            register_number:'',
            state: {},
            city:{}
        }
    })

    function IfIsNaturalFields({children}){
        if ( selectedTab == 'legal' ) { return ''; }
        return children
    }


    function CommonFields(){
        return (
            <>
            <div>
                <label className="inline-block mb-4">
                    <span className="text-right pr-1">اتباع خارجی</span>
                    <input 
                        className="!shadow-none !flex-none"
                        type="checkbox" 
                        checked={isForeigner} 
                        onChange={(e) => {
                            info.is_foreigner = e.target.checked
                            setIsForeigner(e.target.checked)
                        }} 
                    />
                </label>
            </div>

            <div className="grid sm:grid-cols-2 xl:grid-cols-3 justify-items-stretch mb-10 gap-5 lg:gap-10">
                <div><label className="!grid">
                    <span className="text-right mb-3 pr-1">نام </span>
                    <input 
                        className="!flex-none !mr-0" 
                        type="text"
                        defaultValue={info.first_name}
                        onKeyDown={(e) => {
                            if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Tab' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                                return;
                            }
                            
                            const persianRegex = /^[\u0600-\u06FF\s]+$/;
                            if (!persianRegex.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        onChange={e => {
                            info.first_name = e.target.value
                            setInfo(info)
                        }}
                        placeholder="نام را وارد کنید"
                        tabIndex="1"
                    />
                </label></div>
                <div><label className="!grid">
                    <span className="text-right mb-3 pr-1">نام خانوادگی</span>
                    <input className="!flex-none !mr-0" type="text"
                        defaultValue={info.last_name}
                        onKeyDown={(e) => {
                            if (e.key === 'Backspace' || e.key === 'Tab' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                                return;
                            }
                        
                            const persianRegex = /^[\u0600-\u06FF\s]+$/;
                            if (!persianRegex.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        onChange={ e => {
                            info.last_name = e.target.value
                            setInfo(info)
                        } }
                        placeholder="نام خانوادگی را وارد کنید"
                        tabIndex="2"
                    />
                </label></div>

                {isForeigner && <IfIsNaturalFields>
                    <div><label className="!grid">
                        <span className="text-right mb-3 pr-1">کد اتباع</span>
                        <input 
                            className="!flex-none !mr-0" 
                            type="text"
                            pattern="[0-9]*"
                            required
                            title="لطفا فقط عدد وارد کنید"
                            onInvalid={(e) => {
                                e.target.setCustomValidity('لطفا فقط عدد وارد کنید')
                            }}
                            onInput={(e) => {
                                e.target.setCustomValidity('')
                            }}
                            defaultValue={info.foreigner_code}
                            onChange={e => {
                                if (!/^\d*$/.test(e.target.value)) {
                                    e.target.setCustomValidity('لطفا فقط عدد وارد کنید')
                                    e.target.reportValidity()
                                } else {
                                    e.target.setCustomValidity('')
                                    info.foreigner_code = e.target.value
                                    setInfo(info)
                                }
                                if (e.target.value.length > 0){
                                    setIsForeigner(true)
                                }else{
                                    setIsForeigner(false)
                                }
                            }}
                            placeholder="کد اتباع را وارد کنید"
                            tabIndex="3"
                        />
                    </label></div>
                </IfIsNaturalFields>}

                {!isForeigner && <IfIsNaturalFields>
                    <div><label className="!grid">
                        <span className="text-right mb-3 pr-1">کد ملی</span>
                        <input 
                            className="!flex-none !mr-0" 
                            type="text"
                            pattern="[0-9]*"
                            required
                            title="لطفا فقط عدد وارد کنید"
                            onInvalid={(e) => {
                                e.target.setCustomValidity('لطفا فقط عدد وارد کنید')
                            }}
                            onInput={(e) => {
                                e.target.setCustomValidity('')
                            }}
                            defaultValue={info.national_code}
                            onChange={e => {
                                if (!/^\d*$/.test(e.target.value)) {
                                    e.target.setCustomValidity('لطفا فقط عدد وارد کنید')
                                    e.target.reportValidity()
                                } else {
                                    e.target.setCustomValidity('')
                                    info.national_code = e.target.value
                                    setInfo(info)
                                }
                            }}
                            placeholder="کد ملی را وارد کنید"
                            tabIndex="3"
                        />
                    </label></div>
                </IfIsNaturalFields>}

                <IfIsNaturalFields>
                    <div><label className="!grid">
                        <span className="text-right mb-3 pr-1">تاریخ تولد</span>
                        {info.age.length > 0 ?
                            <DatePicker
                                defaultValue={ info.age.length > 0 ? moment.from(info.age, 'fa', 'YYYY/MM/DD').format('YYYY/MM/DD') : null }
                                onChange={(e) => {
                                    info.age = faNumberToEn(moment(e.value).toDate().toLocaleDateString('fa'));
                                    setInfo(info)
                                } }
                                tabIndex="4"
                            />
                            : <DatePicker
                                onChange={(e) => {
                                    info.age = faNumberToEn(moment(e.value).toDate().toLocaleDateString('fa'));
                                    setInfo(info)
                                } }
                                tabIndex="4"
                            /> }

                    </label></div>
                </IfIsNaturalFields>

                <div><label className="!grid">
                    <span className="text-right mb-3 pr-1">شماره همراه</span>
                    <input 
                        className="!flex-none !mr-0" 
                        type="text"
                        pattern="[0-9]*"
                        required
                        title="لطفا فقط عدد وارد کنید"
                        onInvalid={(e) => {
                            e.target.setCustomValidity('لطفا فقط عدد وارد کنید')
                        }}
                        onInput={(e) => {
                            e.target.setCustomValidity('')
                        }}
                        defaultValue={info.cellphone}
                        onChange={e => {
                            if (!/^\d*$/.test(e.target.value)) {
                                e.target.setCustomValidity('لطفا فقط عدد وارد کنید')
                                e.target.reportValidity()
                            } else {
                                e.target.setCustomValidity('')
                                info.cellphone = e.target.value
                                setInfo(info)
                            }
                        }}
                        placeholder="09xxx"
                        tabIndex="5"
                    />
                </label></div>
                <div><label className="!grid">
                    <span className="text-right mb-3 pr-1">شماره تماس اظطراری</span>
                    <input 
                        className="!flex-none !mr-0" 
                        type="text"
                        pattern="[0-9]*"
                        required
                        title="لطفا فقط عدد وارد کنید"
                        onInvalid={(e) => {
                            e.target.setCustomValidity('لطفا فقط عدد وارد کنید')
                        }}
                        onInput={(e) => {
                            e.target.setCustomValidity('')
                        }}
                        defaultValue={info.emergency_phone}
                        onChange={e => {
                            if (!/^\d*$/.test(e.target.value)) {
                                e.target.setCustomValidity('لطفا فقط عدد وارد کنید')
                                e.target.reportValidity()
                            } else {
                                e.target.setCustomValidity('')
                                info.emergency_phone = e.target.value
                                setInfo(info)
                            }
                        }}
                        placeholder="09xxx"
                        tabIndex="6"
                    />
                </label></div>
                </div>
            </>
        )
    }
    function LegalFields(){
        if ( selectedTab == 'natural' ) { return ''; }
        return (
            <div className="grid sm:grid-cols-2 xl:grid-cols-3 justify-items-stretch mb-10 gap-5 lg:gap-10">
                <div><label className="!grid">
                    <span className="text-right mb-3 pr-1">نام سازمان </span>
                    <input className="!flex-none !mr-0"
                           defaultValue={info.legal_info.org_name}
                           onKeyDown={(e) => {
                            if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Tab' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                                return;
                            }
                        
                            const persianRegex = /^[\u0600-\u06FF\s]+$/;
                            if (!persianRegex.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                           onChange={ e => {
                               info.legal_info.org_name = e.target.value
                               setInfo(info)
                           } }
                           type="text" placeholder="نام  را وارد کنید"
                           tabIndex="7"
                    />
                </label></div>
                <div><label className="!grid">
                    <span className="text-right mb-3 pr-1">کد اقتصادی</span>
                    <input className="!flex-none !mr-0"
                           defaultValue={info.legal_info.economic_code}
                           onKeyDown={(e) => {
                                if (e.key === 'Backspace' || 
                                    e.key === 'Delete' || 
                                    e.key === 'Tab' ||
                                    e.key === 'ArrowLeft' || 
                                    e.key === 'ArrowRight') {
                                    return;
                                }
                                
                                if (!/^\d$/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                           onChange={ e => {
                               info.legal_info.economic_code = e.target.value
                               setInfo(info)
                           } }
                           type="text"
                           placeholder="نام خانوادگی را وارد کنید"
                           tabIndex="8"
                    />
                </label></div>
                <div><label className="!grid">
                    <span className="text-right mb-3 pr-1">شناسه ملی</span>
                    <input className="!flex-none !mr-0"
                           defaultValue={info.legal_info.national_number}
                           onKeyDown={(e) => {
                            if (e.key === 'Backspace' || 
                                e.key === 'Delete' || 
                                e.key === 'Tab' ||
                                e.key === 'ArrowLeft' || 
                                e.key === 'ArrowRight') {
                                return;
                            }
                            
                            if (!/^\d$/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                           onChange={ e => {
                               info.legal_info.national_number = e.target.value
                               setInfo(info)
                           } }
                           type="text" placeholder="کد ملی را وارد کنید"
                           tabIndex="9"
                    />
                </label></div>
                <div><label className="!grid">
                    <span className="text-right mb-3 pr-1">شناسه ثبت</span>
                    <input className="!flex-none !mr-0 text-left"
                           defaultValue={info.legal_info.register_number}
                           onKeyDown={(e) => {
                            if (e.key === 'Backspace' || 
                                e.key === 'Delete' || 
                                e.key === 'Tab' ||
                                e.key === 'ArrowLeft' || 
                                e.key === 'ArrowRight') {
                                return;
                            }
                            
                            if (!/^\d$/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                           onChange={ e => {
                               info.legal_info.register_number = e.target.value
                               setInfo(info)
                           } }
                           type="text" placeholder="09xxx"
                           tabIndex="10"
                    />
                </label></div>
                <div><div className="!grid">
                    <span className="text-right text-xs mb-3 pr-1">موقعیت</span>
                    <SelectCoordinates
                        defaultValue={ {
                            state: info.legal_info.state,
                            city: info.legal_info.city,
                            area:{}
                        } }
                        SetCoordinate={ (coordinates) => {
                        info.legal_info.state = coordinates.state[0]
                        info.legal_info.city = coordinates.city[0]
                        setInfo(info)
                    } }
                    tabIndex="11"
                    />
                </div></div>
                <div><label className="!grid">
                    <span className="text-right mb-3 pr-1">شماره تلفن ثبت</span>
                    <input className="!flex-none !mr-0"
                           defaultValue={info.legal_info.phone}
                           onKeyDown={(e) => {
                            if (e.key === 'Backspace' || 
                                e.key === 'Delete' || 
                                e.key === 'Tab' ||
                                e.key === 'ArrowLeft' || 
                                e.key === 'ArrowRight') {
                                return;
                            }
                            
                            if (!/^\d$/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                           onChange={ e => {
                               info.legal_info.phone = e.target.value
                               setInfo(info)
                           } }
                           type="tel"
                           tabIndex="12"
                    />
                </label></div>
            </div>
        )
    }



    async function validateAndStoreUserOnEdge() {
        setIsLoading(true)
        const res = await ApiRoute.customer.create.fn(info,(res) => {
            if (res?.results?._id){
                return window.location.replace('/customers/addresses/create/'+res.results._id)
            }

        })
        setIsLoading(false)
    }



    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ایجاد مشتری جدید</span>
                </div>


                    <div className="main-content xl:w-3/4 mx-auto p-6 lg:p-8 mt-12">
                        <div className="form 2xl:w-3/5 mx-auto">
                            <div className="grid grid-cols-2 lg:flex justify-center pt-4 pb-7 xl:pb-12 items-center gap-4 xl:gap-10 text-13">
                                <span className="max-lg:col-span-2">نوع مشتری را انتخاب کنید</span>
                                <div className="flex items-center gap-3">
                                    <button onClick={e => {
                                        info.is_legal = false
                                        setInfo(info)
                                        setSelectedTab('natural')
                                    }}
                                            className={(selectedTab === 'natural' ? ' bg-[#673bb7] text-white ' : ' text-[#673bb7] ')+`border-[#673bb7] border-2 px-3 py-1 rounded`}>حقیقی</button>
                                    <button onClick={e => {
                                        info.is_legal = true
                                        setInfo(info)
                                        setSelectedTab('legal')
                                    }}
                                            className={(selectedTab == 'legal' ? ' bg-[#673bb7] text-white ' : ' text-[#673bb7] ')+`border-[#673bb7] text-[#673bb7] border-2 px-3 py-1 rounded`}>حقوقی</button>
                                </div>
                            </div>

                            <CommonFields/>

                            <LegalFields/>


                        </div>
                    </div>
                    <div className="flex justify-center my-8 lg:mt-16">
                        <button
                            tabIndex="13"
                            disabled={isLoading}
                            type="button" onClick={
                            e => {
                                validateAndStoreUserOnEdge()
                            }
                        } className="btn-purple w-50">ذخیره و افزودن آدرس</button>
                    </div>

            </div>
        </Layout>
    )
}

export default CreateCustomer