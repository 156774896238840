import Layout from "../../components/Layout"
import SearchBox from "../../components/SearchBox";
import {faFilter, faHand, faCommentDots, faUser, faFileLines, faTrashCan, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Pagination from "../../components/Pagination";
import Filter from "../../components/Filter";
import {Link} from "react-router-dom";
import ShippingOrderTable from "../../components/Tables/ShippingOrderTable";
import {useEffect, useState} from "react";
import FilterBox from "../../components/FilterBox";
import ApiRoute, {shopId} from "../../helper/api";
import FilterOrder from "../../components/Order/FilterOrder";
import DriverSelect from "../../components/Common/DriverSelect";
import OrderFilterTab from "../../components/Order/OrderFilterTab";
import OrderShippingFilterTab from "../../components/Order/OrderShippingFilterTab";

function ShippingOrders() {

    const filters1 = [
        {
            title: 'همه',
            quantity: 1000,
            active: true
        }, {
            title: 'آماده ارسال',
            quantity: 250
        }, {
            title: 'ارسال شده',
            quantity: 300
        }, {
            title: 'ارسال امروز',
            quantity: 186
        }, {
            title: 'ارسال امروز به بعد',
            quantity: 186
        }, {
            title: 'ارسال هفت روز اخیر',
            quantity: 1432
        }, {
            title: 'ارسال امروز و فردا',
            quantity: 951
        }, {
            title: 'ارسال فردا',
            quantity: 951
        },
    ]

    const tableOptions = {rowLink: false, rowSatus: false}
    const operations = [
        {
            title: 'هولد سفارش',
            icon: faHand
        },
        {
            title: 'توضیحات اضافی',
            icon: faCommentDots
        },
        {
            title: 'ثبت کاربر در آسان',
            icon: faUser
        },
        {
            title: 'مشاهده لاگ ها',
            icon: faFileLines
        },
        {
            title: 'حذف سفارش',
            icon: faTrashCan,
            color: 'red'
        },
    ]

    const [showFilterBox, setShowFilterBox] = useState(false)
    const toggleFilterBox=()=> setShowFilterBox(!showFilterBox)


    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'cellphone'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering

    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('all')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.order.index.fn({
                ready_to_shipping:1,
                state:selectedStatsTab,
                custom_status:selectedStatsTab == 'all' ? '' : selectedStatsTab,
                model:'\\App\\OrderManager\\Model\\OrderShipping',
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery;
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    //fetch stats-data from server
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.order.shippingStats.fn({
                ready_to_shipping:1,
                quick_search : searchQuery
            },filterQuery)
            setStatsTab(res.results)
        })();
    }, [data]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    // watch filterQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    // watch statsTab and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [selectedStatsTab]);

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);


    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">سفارشات در حال ارسال</span>
                    </div>
                </div>

                <div className="grid md:flex justify-between items-center gap-3">
                    <div className="flex gap-3">
                    <SearchBox
                            SearchIn={true}
                            SetSearchQuery={setSearchQuery}
                            SearchByDefault={`order_key`}
                            SearchByOptions={[
                                {
                                    label: 'شماره سفارش',
                                    value: 'order_key'
                                },
                            
                            ]}/>
                    </div>
                </div>

                <FilterOrder asStore={true} asShipping={true} status={true} SetFilterQuery={ (s) => {
                    setFilterQuery(s)
                    setUpdateData(Date.now().toString())
                } }/>


                <div className="main-content p-1 mt-3">

                    <OrderShippingFilterTab
                        selectedTab={selectedStatsTab}
                        setSelectedTab={(s) => {
                            setSelectedStatsTab(s)
                        }}
                        options={statsTab}
                    />

                    <div className="border-b border-b-gray-7 p-3 lg:p-2 max-lg:flex">
                        <div>
                            <DriverSelect assignItem={ (s) => {
                                let arr = []
                                s.map( (d) => {
                                    arr.push(d._id)
                                })
                                filterQuery.drivers = arr;
                                setUpdateData(Date.now().toString())
                            } }/>
                        </div>
                    </div>

                    {isLoading ? (
                        <div className="flex justify-center items-center py-10">
                         <FontAwesomeIcon icon={faSpinner} spin className="text-3xl text-purple-600" />
                        </div>
                    ) : (
                        <ShippingOrderTable
                            onSort={ (name , dir) => {
                                filterQuery.auto_sort = []
                                filterQuery.auto_sort.push({
                                    name: name,
                                    value: dir
                                })
                                console.log(filterQuery)
                                setUpdateData(Date.now().toString())

                            } }
                            tableData={tableData}
                            tableOptions={tableOptions}/>
                    )}
                    {data && <Pagination SetPageNumber={setPageNumber} Data={paginationData}/>}
                </div>
            </div>
        </Layout>
    );
}

export default ShippingOrders;