import {BrowserRouter, Route, Routes} from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Customers from "./pages/customer/customers";
import Orders from "./pages/order/orders";
import PreOrder from "./pages/order/pre-orders";
import ShippingOrders from "./pages/order/shipping-orders";
import Inventory from "./pages/inventory";
import Campaigns from "./pages/campaign/campaigns";
import Coupons from "./pages/coupon/coupons";
import PriceList from "./pages/price-list/price-list";
import Users from "./pages/users/users";
import Products from "./pages/product/products";
import PricingRules from "./pages/price-rule/pricing-rules";
import Export from "./pages/export";
import Pages from "./pages/pages/pages";
import AlertStock from "./pages/product/stock/alert-stock";
import CreateCoupon from "./pages/coupon/create-coupon";
import EditCoupon from "./pages/coupon/edit-coupon";
import ViewCoupon from "./pages/coupon/view-coupon";
import EditCampaign from "./pages/campaign/edit-campaign";
import CreateCustomer from "./pages/customer/create-customer";
import ViewCustomer from "./pages/customer/view-customer";

import CreateProductStep1 from "./pages/product/create-product/create-product-step-1";
import CreateProductStep2 from "./pages/product/create-product/create-product-step-2";
import CreateProductStep3 from "./pages/product/create-product/create-product-step-3";
import CreateProductStep4 from "./pages/product/create-product/create-product-step-4";
import CreateProductStep5 from "./pages/product/create-product/create-product-step-5";
import EditProduct from "./pages/product/edit-product";

import Menus from "./pages/menu/menus";
import ManageStock from "./pages/product/stock/manage-stock";
import Payments from "./pages/payment-methods/payments";
import EditOnlineMethod from "./pages/payment-methods/edit-online-method";
import EditOnSpotMethod from "./pages/payment-methods/edit-onSpot-method";
import Attributes from "./pages/product/attributes/attributes";
import AttributesGroups from "./pages/product/attributes/attributes-groups";
import AttributeFeatures from "./pages/product/attributes/attribute-features";
import InPersonDeliveryTime from "./pages/shipment/in-person-delivery-time";
import InPersonAddressesSetting from "./pages/shipment/in-person-addresses-setting";
import EmployeesStore from "./pages/employee/employees-store";
import EmployeeNewStore from "./pages/employee/employee-new-store";
import Employees from "./pages/employee/employees";
import EmployeeNew from "./pages/employee/employee-new";
import EmployeeEdit from "./pages/employee/employee-edit";
import Stores from "./pages/store/stores";
import EditStore from "./pages/store/edit-store";
import UsersAccess from "./pages/users/access";
import EmployeesAccess from "./pages/employee/access";
import AccessEdit from "./pages/employee/access-edit";
import Forms from "./pages/form/forms";
import States from "./pages/coordinates/states";
import Cities from "./pages/coordinates/cities";
import Neighborhoods from "./pages/coordinates/neighborhoods";
import Comments from "./pages/comment/comments";
import CommentEdit from "./pages/comment/comment-edit";
import Faqs from "./pages/faqs";
import GeneralSetting from "./pages/setting/general-setting";
import LogicoSetting from "./pages/shipment/logico-setting";
import HavaleSetting from "./pages/setting/havale-setting";
import SendDeliveryTime from "./pages/shipment/send-delivery-time";
import SendDeliveryTimeReport from "./pages/reports/send-delivery-time-report";
import CreatePriceList from "./pages/price-list/create-price-list";
import CreatePricingRules from "./pages/price-rule/create-pricing-rules";
import CreateBrandRule from "./pages/price-rule/create-brand-rule";
import PriceUpdateRules from "./pages/price-rule/price-update-rules";
import Templates from "./pages/template/templates";
import IndexSetting from "./pages/setting/index-setting";
import EditTemplate from "./pages/template/edit-template";
import {QueryClient} from "@tanstack/react-query";
import CreateAddress from "./pages/customer/address/create-address";
import EditAddress from "./pages/customer/address/edit-address";
import DepositWallet from "./pages/customer/wallet/deposit-wallet";
import Tags from "./pages/product/tags/tags";
import EditTag from "./pages/product/tags/edit-tag";
import Categories from "./pages/product/category/categories";
import EditCategory from "./pages/product/category/edit-category";
import AttributesProxy from "./pages/product/attributes/attributes-proxy";
import EditPriceList from "./pages/price-list/edit-price-list";
import Shipping from "./pages/shipment/shipping";
import EditPage from "./pages/pages/edit-page";
import Login from "./components/Auth/Login";
import useToken from './useToken';
import ProfileEdit from "./pages/employee/profile";
import EditPricingRules from "./pages/price-rule/edit-pricing-rules";
import StorageSetting from "./pages/setting/storage-setting";
import TransportSetting from "./pages/setting/transport-setting";
import OrderCreate from "./pages/order/new-order/order-create";
import OrderEdit from "./pages/order/edit/order-edit";
import OrderDeliveryTimeClass from "./pages/order/edit/order-delivery-time";
import DeliveryDateClassStart from "./pages/shipment/delivery-date-class-start";
import DeliveryDateClassUnavailable from "./pages/shipment/delivery-date-class-unavailable";
import EditMethod from "./pages/payment-methods/edit-method";
import StoreOrders from "./pages/order/store-orders";
import SnappShop from "./pages/integrate/snapp-shop";
import {HasAccess, hasAccess} from "./helper/permissions";
import {updateVersion} from "./helper/general";
import DgShahrShop from "./pages/integrate/dgshahr-shop";
import IntegrateTask from "./pages/integrate-task";
import ViewForms from "./pages/form/view-forms";
import ProductNotification from "./pages/product/notification/product-notification";
import Reservation from "./pages/order/reservation";
const queryClient = new QueryClient()


function App() {
  const { token, resetToken, setToken } = useToken();

  if ( localStorage.getItem("update") !== updateVersion() ){
    resetToken()
  }

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <BrowserRouter>
    <Routes>

      <Route path="/profile" element={<ProfileEdit />} />

      {/*--------Lists-----------*/}
      <Route path="" element={<Dashboard />} />
      <Route path="/inventory" element={ <HasAccess permission={`inventory`} children={<Inventory />}/> } />
      <Route path="/integrate-task" element={ <HasAccess permission={`integrate`} children={<IntegrateTask />}/> } />
      <Route path="/export" element={ <HasAccess permission={`export`} children={<Export />}/> } />
      <Route path="/forms" element={ <HasAccess permission={`form setting`} children={<Forms />}/> } />
      <Route path="/forms/view" element={ <HasAccess permission={`form setting`} children={<ViewForms />}/> } />
      <Route path="/faqs" element={ <HasAccess permission={`fag`} children={<Faqs />}/> } />

      {/*--------SHIPMENT-----------*/}
      <Route path="/shipping" element={ <HasAccess permission={`shipment setting`} children={<Shipping />}/> } />
      <Route path="/shipping/delivery-time" element={ <HasAccess permission={`delivery_time setting`} children={<SendDeliveryTime />}/> } />
      <Route path="/shipping/delivery-time-start" element={ <HasAccess permission={`delivery_time_inactive setting`} children={<DeliveryDateClassStart />}/> } />
      <Route path="/shipping/delivery-time-unavailable" element={ <HasAccess permission={`delivery_time_inactive setting`} children={<DeliveryDateClassUnavailable />}/> } />
      <Route path="/shipping/drivers-setting" element={ <HasAccess permission={`logico setting`} children={<LogicoSetting />}/> } />
      <Route path="/shipping/in-person-delivery-time" element={ <HasAccess permission={`delivery_time_in_person setting`} children={<InPersonDeliveryTime />}/> } />
      <Route path="/shipping/in-person-addresses" element={ <HasAccess permission={`delivery_time_in_person_address setting`} children={<InPersonAddressesSetting />}/> } />

      {/*--------Coupon-----------*/}
      <Route path="/coupons" element={ <HasAccess permission={`discount`} children={<Coupons />}/> } />
      <Route path="/coupons/create" element={ <HasAccess permission={`discount`} children={<CreateCoupon />}/> } />
      <Route path="/coupons/view" element={ <HasAccess permission={`discount`} children={<ViewCoupon />}/> } />
      <Route path="/coupons/edit" element={ <HasAccess permission={`discount`} children={<EditCoupon />}/> } />

      {/*--------Campaign-----------*/}
      <Route path="/campaigns" element={ <HasAccess permission={`campaigns`} children={<Campaigns />}/> } />
      <Route path="/campaigns/edit" element={ <HasAccess permission={`campaigns`} children={<EditCampaign />}/> } />

      {/*--------Customer-----------*/}

      <Route path="/customers" element={ <HasAccess permission={`customer`} children={<Customers />}/> } />
      <Route path="/customers/create" element={ <HasAccess permission={`customer`} children={<CreateCustomer />}/> } />
      <Route path="/customers/view/:id" element={ <HasAccess permission={`customer`} children={<ViewCustomer />}/> } />
      <Route path="/customers/addresses/create/:id" element={ <HasAccess permission={`customer`} children={<CreateAddress />}/> } />
      <Route path="/customers/addresses/edit/:customer_id/:address_id" element={ <HasAccess permission={`customer`} children={<EditAddress />}/> } />
      <Route path="/customers/wallet/deposit/:customer_id" element={ <HasAccess permission={`customer`} children={<DepositWallet />}/> } />


      {/*--------Orders-----------*/}
      <Route path="/orders" element={ <HasAccess permission={'order'} children={<Orders/>}/> }/>
      <Route path="/pre-order" element={ <HasAccess permission={'pre order'} children={<PreOrder/>}/> }/>
      <Route path="/shipping-order" element={ <HasAccess permission={'shop shipment_orders'} children={<ShippingOrders/>}/> }/>
      <Route path="/orders/create" element={ <HasAccess permission={'create order'} children={<OrderCreate/>}/> }/>
      <Route path="/orders/edit" element={ <HasAccess permission={'order'} children={<OrderEdit />}/> } />
      <Route path="/orders/delivery-time-class" element={ <HasAccess permission={'order'} children={<OrderDeliveryTimeClass />}/> } />
     {/* <Route path="/orders/create/step-2" element={<OrderStep2 />} />
      <Route path="/orders/create/step-3" element={<OrderStep3 />} />
      <Route path="/orders/create/step-4" element={<OrderStep4 />} />
      <Route path="/orders/create/step-5-final" element={<OrderStep5 />} />*/}

      {/*--------Products-----------*/}
      <Route path="/products" element={ <HasAccess permission={'product'} children={<Products />}/> } />
      <Route path="/products/create/step-1" element={ <HasAccess permission={'product'} children={<CreateProductStep1 />}/> } />
      <Route path="/products/create/step-2" element={ <HasAccess permission={'product'} children={<CreateProductStep2 />}/> } />
      <Route path="/products/create/step-3" element={ <HasAccess permission={'product'} children={<CreateProductStep3 />}/> } />
      <Route path="/products/create/step-4" element={ <HasAccess permission={'product'} children={<CreateProductStep4 />}/> } />
      <Route path="/products/create/step-5-save" element={ <HasAccess permission={'product'} children={<CreateProductStep5 />}/> } />
      <Route path="/products/edit" element={ <HasAccess permission={'edit product'} children={<EditProduct />}/> } />
      <Route path="/products/tags" element={ <HasAccess permission={'tag product'} children={<Tags />}/> } />
      <Route path="/products/edit-tag" element={ <HasAccess permission={'tag product'} children={<EditTag />}/> } />

      <Route path="/products/categories" element={ <HasAccess permission={'category product'} children={<Categories />}/> } />
      <Route path="/products/edit-category" element={ <HasAccess permission={'category product'} children={<EditCategory />}/> } />
      <Route path="/products/notification" element={ <HasAccess permission={'product_notification'} children={<ProductNotification />}/> } />

      <Route path="/alert-stock" element={ <HasAccess permission={'alerts'} children={<AlertStock />}/> } />
      <Route path="/manage-stock" element={ <HasAccess permission={'manage_stock setting'} children={<ManageStock />}/> } />
      <Route path="/products/attributes" element={ <HasAccess permission={'attribute product'} children={<Attributes />}/> } />
      <Route path="/products/attributes/features" element={ <HasAccess permission={'attribute product'} children={<AttributeFeatures />}/> } />
      {/*<Route path="/products/attributes/sorting" element={<AttributesSorting />} />*/}
      <Route path="/products/attributes/proxy" element={ <HasAccess permission={'attribute sort product'} children={<AttributesProxy />}/> } />
      <Route path="/products/attributes/groups" element={ <HasAccess permission={'attribute group product'} children={<AttributesGroups />}/> } />


      {/*--------Menus-----------*/}
      <Route path="/menus" element={ <HasAccess permission={'menu'} children={<Menus />}/> } />

      <Route path="/reservations" element={ <HasAccess permission={'reservation'} children={<Reservation />}/> } />


      {/*--------Payment Methods-----------*/}
      <Route path="/payment-methods" element={ <HasAccess permission={'payment_method setting'} children={<Payments />}/> } />
      <Route path="/payment-methods/edit" element={ <HasAccess permission={'payment_method setting'} children={<EditMethod />}/> } />
{/*      <Route path="/payment-methods/online-method/edit" element={<EditOnlineMethod />} />
      <Route path="/payment-methods/on-spot-method/edit" element={<EditOnSpotMethod />} />*/}

      {/*--------Setting-----------*/}
      <Route path="/setting/general-setting" element={ <HasAccess permission={'general setting'} children={<GeneralSetting />}/> } />
      {/*<Route path="/setting/index-setting" element={<IndexSetting />} />*/}

      <Route path="/setting/storages" element={ <HasAccess permission={'transference setting'} children={<StorageSetting />}/> } />
      <Route path="/setting/transports" element={ <HasAccess permission={'transference setting'} children={<TransportSetting />}/> } />
      {/*<Route path="/setting/havale-setting" element={<HavaleSetting />} />*/}


      {/*--------Users-----------*/}
{/*      <Route path="/users" element={<Users />} />
      <Route path="/users/access" element={<UsersAccess />} />*/}


      {/*--------Employees-----------*/}
      <Route path="/employees" element={ <HasAccess permission={'employee'} children={<Employees />}/> } />
      <Route path="/employees/access" element={ <HasAccess permission={'access'} children={<EmployeesAccess />}/> } />
      <Route path="/employees/access/edit" element={ <HasAccess permission={'access'} children={<AccessEdit />}/> } />
      <Route path="/employees/new" element={ <HasAccess permission={'employee'} children={<EmployeeNew />}/> } />
      <Route path="/employees/edit" element={ <HasAccess permission={'employee'} children={<EmployeeEdit />}/> } />
{/*      <Route path="/employees-store" element={<EmployeesStore />} />
      <Route path="/employees-store/new" element={<EmployeeNewStore />} />*/}


      {/*--------Stores-----------*/}
      <Route path="/stores" element={ <HasAccess permission={'shop'} children={<Stores />}/> } />
      <Route path="/stores/orders" element={ <HasAccess permission={'shop orders'} children={<StoreOrders />}/> } />
      <Route path="/stores/edit" element={ <HasAccess permission={'shop'} children={<EditStore />}/> } />

      {/*--------Coordinates-----------*/}
      <Route path="/coordinates/states" element={ <HasAccess permission={'coordinates setting'} children={<States />}/> } />
      <Route path="/coordinates/cities" element={ <HasAccess permission={'coordinates setting'} children={<Cities />}/> } />
      <Route path="/coordinates/neighborhoods" element={ <HasAccess permission={'coordinates setting'} children={<Neighborhoods />}/> } />

      {/*--------Comments-----------*/}
      <Route path="/comments" element={ <HasAccess permission={'comment'} children={<Comments />}/> } />
      <Route path="/comments/edit" element={ <HasAccess permission={'comment'} children={<CommentEdit />}/> } />

      {/*--------Reports-----------*/}
      <Route path="/send-delivery-time-report" element={<SendDeliveryTimeReport />} />


      {/*--------Pages-----------*/}
      <Route path="/pages" element={ <HasAccess permission={'page'} children={<Pages />}/> } />
      <Route path="/pages/edit" element={ <HasAccess permission={'page'} children={<EditPage />}/> } />

      {/*--------Price List-----------*/}
      <Route path="/price-list" element={ <HasAccess permission={'pricelist'} children={<PriceList />}/> } />
      <Route path="/price-list/edit" element={ <HasAccess permission={'pricelist'} children={<EditPriceList />}/> } />
      <Route path="/price-list/create" element={ <HasAccess permission={'pricelist'} children={<CreatePriceList />}/> } />

      {/*--------Price Rule-----------*/}
      <Route path="/pricing-rules" element={ <HasAccess permission={'pricemanage'} children={<PricingRules />}/> } />
      <Route path="/pricing-rules/edit" element={ <HasAccess permission={'pricemanage'} children={<EditPricingRules />}/> } />
      <Route path="/pricing-rules/create" element={ <HasAccess permission={'pricemanage'} children={<CreatePricingRules />}/> } />
      <Route path="/pricing-rules/brand/create" element={ <HasAccess permission={'pricemanage'} children={<CreateBrandRule />}/> } />
      <Route path="/pricing-rules/price-update-rules" element={ <HasAccess permission={'price_change_timing setting'} children={<PriceUpdateRules />}/> } />

      {/*--------Templates-----------*/}
      <Route path="/templates" element={ <HasAccess permission={'appearance'} children={<Templates />}/> } />
      <Route path="/templates/edit" element={ <HasAccess permission={'appearance'} children={<EditTemplate />}/> } />

      <Route path="/integrate/snapp-shop" element={ <HasAccess permission={'integrate'} children={<SnappShop />}/> } />
      <Route path="/integrate/dgshahr-shop" element={ <HasAccess permission={'integrate'} children={<DgShahrShop />}/> } />

    </Routes>
    </BrowserRouter>
    
  );
}

export default App;
