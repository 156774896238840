import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../../helper/api";
import Layout from "../../Layout";
import SearchBox from "../../SearchBox";
import FilterInventory from "../FilterInventory";
import {formatNumberWithDots} from "../../../helper/general";
import InventoryFilterStateTab from "../InventoryFilterStateTab";
import InventoryTable from "../../Tables/InventoryTable";
import Pagination from "../../Pagination";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export default function PrimaryInventoryList(){
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [showFilterBox, setShowFilterBox] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'cellphone'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')
    const [id , setId] = useState(params.get('id') ?? '')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.product.inventory.index.fn({
                shop_id: shopId(),
                state:selectedStatsTab,
                id:id,
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setPageNumber(1)
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    //fetch stats-data from server
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.product.inventory.stats.fn({
                shop_id: shopId(),
            },filterQuery)
            setStatsTab(res.results)
        })();
    }, [data]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    // watch filterQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    // watch statsTab and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [selectedStatsTab]);

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);


    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    return (
        <div>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">انبارداری</span></div>
                <div className="flex gap-3">
                    <SearchBox SearchByOptions={[
                        { label: "نام محصول" , value : "title" },
                        { label: "کد آسان" , value : "asan_id" }
                    ]} SearchIn={true} SetSearchQuery={setSearchQuery} SearchByDefault={`title`}/>
                </div>
                <FilterInventory filterQuery={filterQuery} SetFilterQuery={(s) => {
                    setFilterQuery(s)
                    setUpdateData(Date.now().toString())
                }} showHide={showFilterBox}/>

                { id?.length > 0 && <div className={`purple-2-tag flex items-center gap-3 rounded w-auto !max-w-max`}>
                    <span>محصول به شناسه : {id}</span>
                    <FontAwesomeIcon onClick={() => {
                        window.location.replace('/inventory')
                    }} className={`text-white cursor-pointer bg-red-1 px-2 py-2 rounded`} icon={faTimes}/>
                </div>}

                <p className="text-left lg:pl-2 text-xs text-gray-5 pt-6 lg:pt-0">شما {formatNumberWithDots(paginationData?.total)} تعداد محصول ثبت شده دارید</p>
                    <div className="main-content p-1 mt-2">
                    <InventoryFilterStateTab
                        selectedTab={selectedStatsTab}
                        setSelectedTab={setSelectedStatsTab}
                        options={statsTab}
                    />
                    
                    {isLoading ? (
                        <div className="flex justify-center items-center py-10">
                            <FontAwesomeIcon icon={faSpinner} spin className="text-3xl text-purple-600" />
                        </div>
                    ) : (
                        <InventoryTable
                            onSort={ (name , dir) => {
                                filterQuery.auto_sort = []
                                filterQuery.auto_sort.push({
                                    name: name,
                                    value: dir
                                })
                                console.log(filterQuery)
                                setUpdateData(Date.now().toString())
                            } }
                            setUpdateData={setUpdateData}
                            tableData={tableData}/>
                    )}
                    <Pagination SetPageNumber={setPageNumber} Data={paginationData}/>
                </div>
            </div>
        </div>
    )
}