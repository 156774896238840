import React, {useEffect, useState} from "react";
import CustomMultiSelect from "../Utilities/CustomMultiSelect";
import moment from "jalali-moment";
import {DatePicker} from "zaman";
import CategorySelect from "../Product/Category/CategorySelect";
import ProductSelect from "../Product/ProductSelect";
import CustomerSelect from "../Customer/CustomerSelect";
import Select from 'react-select';
import SelectCoordinates2 from "../Coordinates/SelectCoordinates2";

export default function AdvanceForm({editData}){

    return (
        <div className="main-content mx-auto py-6 px-5 lg:px-8 my-5 lg:my-12">
            <div className="page-title mb-3"><span className="inline-block bg-white text-purple-1 pl-3 mb-5">تعیین محدودیت ها</span>
            </div>
            <div className="form">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3 2xl:gap-10 justify-items-stretch">
                    <div><label>
                        سقف قیمت محصول
                        <input defaultValue={editData.cost_ceiling} onKeyUp={ (e) => {
                            editData.cost_ceiling = parseInt(e.target.value)
                        } } type="text"/>
                    </label></div>
                    <div><label>
                        کف قیمت محصول
                        <input defaultValue={editData.cost_floor} onKeyUp={ (e) => {
                            editData.cost_floor = parseInt(e.target.value)
                        } } type="text"/>
                    </label></div>
                    <div><label>
                        سقف کد تخفیف
                        <input defaultValue={editData.discount_ceiling} onKeyUp={ (e) => {
                            editData.discount_ceiling = parseInt(e.target.value)
                        } } type="text"/>
                    </label></div>
                    <div><label>
                        شروع
                        <DatePicker
                            defaultValue={editData.start_at ? moment(editData.start_at).format('YYYY-MM-DD') : undefined}
                            onChange={(e) => {
                                editData.start_at = moment(e.value).format('YYYY-MM-DD');
                            }}
                        />
                    </label></div>
                    <div><label>
                        پایان
                        <DatePicker
                            defaultValue={editData.end_at ? moment(editData.end_at).format('YYYY-MM-DD') : undefined}
                            onChange={(e) => {
                                editData.end_at = moment(e.value).format('YYYY-MM-DD');
                            }}
                        />
                    </label></div>
                </div>
                <div className="flex items-center mt-8">
                    <label className="ml-3 ">
                        روی این دسته ها اعمال شود
                    </label>
                    <div className="flex-auto">
                       <CategorySelect defaultValue={editData.categories} assignItem={ (v) => {
                           let temp = []
                           v.map( (c) => {
                               temp.push(c.slug);
                           } )
                           editData.categories = temp
                       } }/>
                    </div>
                </div>

                <div className="flex items-center mt-8">
                    <label className="ml-3 ">
                        روی این موقعیت ها اعمال شود
                    </label>
                    <div className="flex-auto">
                       <SelectCoordinates2 defaultValue={editData?.coordinates ?? []} defaultValueMultiple={true} multiple={true} setCoordinate={ (v) => {
                           editData.coordinates = v
                       } }/>
                    </div>
                </div>

                <div className="flex items-center mt-8">
                    <label className="ml-3 ">
                        روی این محصولات اعمال شود
                    </label>
                    <div className="flex-auto">
                        <ProductSelect defaultValue={editData.products} defaultValueAs={"id"} assignItem={ (v) => {
                            let temp = []
                            v.map( (c) => {
                                temp.push(c._id);
                            } )
                            editData.products = temp
                        } }/>
                    </div>

                </div>
                <div className="flex items-center mt-8">
                    <label className="ml-3 ">
                        روی این کاربران اعمال شود
                    </label>
                    <div className="flex-auto">
                        <CustomerSelect multiple={true} defaultValue={editData.customers} defaultValueAs={`id`} assignItem={ (v) => {
                            let temp = []
                            v.map( (c) => {
                                temp.push(c._id);
                            } )
                            editData.customers = temp
                        } }/>
                    </div>

                </div>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 2xl:flex gap-3 2xl:gap-10 justify-between mt-8">
                    <div><label>
                        تعداد استفاده
                        <input defaultValue={editData.limit_use} onKeyUp={ (e) => {
                            editData.limit_use = parseInt(e.target.value);
                        } } type="text"/>
                    </label></div>
                    <div><label>
                        تعداد سفارشات تکمیل شده
                        <input defaultValue={editData.order_count} onKeyUp={ (e) => {
                            editData.order_count = parseInt(e.target.value);
                        } } type="text"/>
                    </label></div>
                    <div><label>
                        روش پرداخت
                        <Select
                            isMulti
                            defaultValue={editData.payment_method ? 
                                (Array.isArray(editData.payment_method) ? editData.payment_method : [editData.payment_method]).map(method => ({
                                    value: method,
                                    label: method === "cod" ? "پرداخت در محل" : 
                                           method === "debit_card" ? "کارت به کارت" : 
                                           "آنلاین"
                                })) : 
                                []
                            }
                            options={[
                                {value: "cod", label: "پرداخت در محل"},
                                {value: "debit_card", label: "کارت به کارت"},
                                {value: "shaparak", label: "آنلاین"}
                            ]}
                            onChange={(selected) => {
                                editData.payment_method = selected ? selected.map(item => item.value) : [];
                            }}
                            placeholder="انتخاب کنید"
                            noOptionsMessage={() => "موردی یافت نشد"}
                        />
                    </label></div>
                    <div><label>
                        پکیج اعمال تخفیف
                        <select onChange={ (e) => {
                            editData.code_type = e.target.value;
                        } }>
                            <option selected={ editData.code_type === "products" } value="products">محصولات</option>
                            <option selected={ editData.code_type === "shipment" } value="shipment">حمل و نقل</option>
                        </select>
                    </label></div>
                    <div><label>
                        اعمال قیمت ویژه
                        <select onChange={ (e) => {
                            editData.sale_price = e.target.value;
                        } }>
                            <option selected={editData.sale_price} value={true}>بله</option>
                            <option selected={~editData.sale_price} value={false}>خیر</option>
                        </select>
                    </label></div>
                    <div><label>
                        محلی / سراسری
                        <select onChange={ (e) => {
                            editData.state = e.target.value;
                        } }>
                            <option selected={ editData.state === "local" } value="local">محلی</option>
                            <option selected={ editData.state === "global" } value="global">سراسری</option>
                        </select>
                    </label></div>
                </div>

            </div>
        </div>
    )
}