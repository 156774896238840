import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import ApiRoute from '../../helper/api';

function PricingRulesTable(props) {
    const tableTitles = [
        'عنوان',
    ]

    const handleDelete = async (id) => {
        try {
            if (window.confirm('آیا از حذف این قانون قیمت‌گذاری اطمینان دارید؟')) {
                await ApiRoute.price.rule.delete.fn(id);
                props.setUpdateData(Date.now())
            }

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <table className="coupon-table table-auto w-full mt-4 block md:table">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
                <td></td>
            </tr>
            </thead>
            <tbody>
            {props.tableData.map((tableData, id) => (
                        <tr className={`text-xs py-2`} key={id}>
                            <td>{tableData.title}</td>
                            <td className="flex justify-center items-center py-3">
                                <Link className="mx-2" to={`/pricing-rules/edit?id=${tableData._id}`} title="ویرایش"><FontAwesomeIcon
                                    className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></Link>
                                <button className="mx-2" onClick={() => handleDelete(tableData._id)} title="حذف"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                            </td>
                        </tr>
                )
            )}
            </tbody>
        </table>
    )
}

export default PricingRulesTable