import React, { useState } from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import { faCheckDouble, faCircleExclamation, faCommentDots, faHand, faRectangleXmark, faRecycle, faSquareCheck, faTruckMedical, faUserAlt, faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import { hasAccess } from '../../../helper/permissions';
import ApiRoute, { isPrimaryShop } from '../../../helper/api';
import OrderPrintList from '../../../helper/order_print_list';
import OrderExtraDescriptionModal from '../Modal/OrderExtraDescriptionModal';
import OrderShipmentDescriptionModal from '../Modal/OrderShipmentDescriptionModal';
import OrderTransferenceCanceledDescriptionModal from '../Modal/OrderTransferenceCanceledDescriptionModal';
import OrderTransferenceSubmitModal from '../Modal/OrderTransferenceSubmitModal';
import OrderFactorSubmitModal from '../Modal/OrderFactorSubmitModal';
import OrderHoldSubmitModal from '../Modal/OrderHoldSubmitModal';
import OrderAccountantConfirmationSubmitModal from '../Modal/OrderAccountantConfirmationSubmitModal';
import OrderInventoryConfirmationSubmitModal from '../Modal/OrderInventoryConfirmationSubmitModal';
import OrderOfficialConfirmationSubmitModal from '../Modal/OrderOfficialConfirmationSubmitModal';
import './OrderOperations.css';

function OrderOperations({
    tableData, 
    setUpdateData, 
    className = ''
}) {
    const [modal, setModal] = useState(null)
    const [orderId, setOrderId] = useState(tableData._id)
    const operations = [
        {
            title: 'مشاهده سفارش',
            icon: faEye,
            name:'show',
            nonPrimaryShopAccess:true,
            button_type:"link",
            submit: (order_id) => {
                if (window.location.href.includes("stores")){
                    return '../orders/edit?id=' + order_id
                }
                return './edit?id=' + order_id
            }
        },
        {
            title: 'هولد سفارش',
            icon: faHand,
            hasAccess: hasAccess('hold order'),
            name:'hold',
            submit: async (order_id) => {
                setOrderId(order_id)
                setModal('hold')
            }
        },
        {
            title: 'تایید تامین کننده',
            name:'factor/submit',
            nonPrimaryShopAccess:true,
            hasAccess: hasAccess('factor order'),
            icon: faSquareCheck,
            submit: async (order_id) => {
                setOrderId(order_id)
                setModal('factor/submit')
            }
        },
        {
            title: 'ریست تامین کننده',
            name:'factor/reset',
            hasAccess:hasAccess('factor order'),
            icon: faRectangleXmark,
            submit: async (order_id) => {
                await ApiRoute.order.action.fn("factor/reset",order_id)
                setUpdateData(Date.now().toString())
            }
        },

        {
            title: 'تائید حسابدار',
            name:'accountant-confirmation/set',
            hasAccess: hasAccess('accountants_confirmation order'),
            icon: faSquareCheck,
            submit: async (order_id) => {
                setOrderId(order_id)
                setModal('accountant-confirmation/set')
            }
        },
        {
            title: 'ریست حسابدار',
            name:'accountant-confirmation/reset',
            hasAccess: hasAccess('accountants_confirmation order'),
            icon: faRectangleXmark,
            submit: async (order_id) => {
                await ApiRoute.order.action.fn("accountant-confirmation/reset",order_id)
                setUpdateData(Date.now().toString())
            }
        },


        {
            title: 'تائید حقوقی',
            name:'official-confirmation/set',
            hasAccess:hasAccess('official_order_confirmation order'),
            icon: faSquareCheck,
            submit: async (order_id) => {
                setOrderId(order_id)
                setModal('official-confirmation/set')
            }
        },
        {
            title: 'ریست حقوقی',
            name:'official-confirmation/reset',
            hasAccess:hasAccess('official_order_confirmation_reset order'),
            icon: faRectangleXmark,
            submit: async (order_id) => {
                await ApiRoute.order.action.fn("official-confirmation/reset",order_id)
                setUpdateData(Date.now().toString())
            }
        },

        {
            title: 'عدم پرینت (حقوقی)',
            name:'official_print/allow',
            hasAccess: hasAccess('ban_print_official_order'),
            icon: faRectangleXmark,
            submit: async (order_id) => {
                await ApiRoute.order.action.fn("official_print/allow",order_id)
                setUpdateData(Date.now().toString())
            }
        },
        {
            title: 'ریست عدم پرینت (حقوقی)',
            name:'official_print/disallow',
            hasAccess: hasAccess('ban_print_official_order'),
            icon: faRectangleXmark,
            submit: async (order_id) => {
                await ApiRoute.order.action.fn("official_print/allow",order_id)
                setUpdateData(Date.now().toString())
            }
        },

        {
            title: 'تائید انبار',
            name:'inventory-confirmation/set',
            hasAccess: hasAccess('inventory_confirmation order'),
            icon: faSquareCheck,
            submit: async (order_id) => {
                setOrderId(order_id)
                setModal('inventory-confirmation/set')
            }
        },
        {
            title: 'ریست انبار',
            name:'inventory-confirmation/reset',
            hasAccess: hasAccess('inventory_confirmation order'),
            icon: faRectangleXmark,
            submit: async (order_id) => {
                await ApiRoute.order.action.fn("inventory-confirmation/reset",order_id)
                setUpdateData(Date.now().toString())
            }
        },

        {
            title: 'ثبت حواله',
            icon: faCheckDouble,
            nonPrimaryShopAccess:true,
            hasAccess: hasAccess('submit_transference order'),
            name:'transference/submit',
            submit: async (order_id) => {
                //await ApiRoute.order.action.fn("extra-description",order_id)
                ///setUpdateData(Date.now().toString())
                setOrderId(order_id)
                setModal('transference/submit')
            }
        },
        {
            title: 'ریست حواله',
            icon: faRecycle,
            name:'transference/reset',
            hasAccess: hasAccess('reset_transference order'),
            color: 'red',
            submit: async (order_id) => {
                await ApiRoute.order.action.fn("transference/reset",order_id)
                setUpdateData(Date.now().toString())
            }
        },
        {
            title: 'دلیل عدم حواله',
            icon: faCircleExclamation,
            name:'transference/canceled-description',
            hasAccess: hasAccess('reason_transference order'),
            submit: async (order_id) => {
                //await ApiRoute.order.action.fn("extra-description",order_id)
                ///setUpdateData(Date.now().toString())
                setOrderId(order_id)
                setModal('transference/canceled-description')
            }
        },
        {
            title: 'توضیحات ارسال',
            icon: faTruckMedical,
            nonPrimaryShopAccess:true,
            name:'shipment-description',
            submit: async (order_id) => {
                //await ApiRoute.order.action.fn("extra-description",order_id)
                ///setUpdateData(Date.now().toString())
                setOrderId(order_id)
                setModal('shipment-description')
            }
        },
        {
            title: 'توضیحات اضافی',
            icon: faCommentDots,
            nonPrimaryShopAccess:true,
            hasAccess: hasAccess(`change_operator_desc order`),
            name:'extra-description',
            submit: async (order_id) => {
                //await ApiRoute.order.action.fn("extra-description",order_id)
                ///setUpdateData(Date.now().toString())
                setOrderId(order_id)
                setModal('extra-description')
            }
        },
        {
            title: 'ثبت کاربر در آسان',
            icon: faUserAlt,
            hasAccess: hasAccess(`change_operator_desc order`),
            showIf: (order) => {
                return !order.customer_info?.asan_id || order.customer_info.asan_id === "0";
            },
            name:'register_user_in_asan',
            submit: async (order_id , order) => {
                await ApiRoute.customer.updateAsanId.fn(order.customer_id)
                setUpdateData(Date.now().toString())
            }
        },
    ]

    function showPrint(order){
        if (order.is_official){
            if (order?.metadata?.show_print_official == null){
                return true;
            }
            return order?.metadata?.show_print_official !== true;
        }
        return true;
    }

    return (

        <>
        {modal === 'extra-description' && <OrderExtraDescriptionModal event={ () => {
            setUpdateData(Date.now().toString())
            setModal(null)
        } } order={orderId} closeModal={() => {
            setModal(null)
        }}/>}

        {modal === 'shipment-description' && <OrderShipmentDescriptionModal event={ () => {
            setUpdateData(Date.now().toString())
            setModal(null)
        } } order={orderId} closeModal={() => {
            setModal(null)
        }}/>}

        {modal === 'transference/canceled-description' && <OrderTransferenceCanceledDescriptionModal event={ () => {
            setUpdateData(Date.now().toString())
            setModal(null)
        } } order={orderId} closeModal={() => {
            setModal(null)
        }}/>}

        {modal === 'transference/submit' && <OrderTransferenceSubmitModal event={ (res) => {

            if (res.status_code >= 200){
                setUpdateData(Date.now().toString())
                setModal(null)
            }

        } } order={orderId} closeModal={() => {
            setModal(null)
        }}/>}

        {modal === 'factor/submit' && <OrderFactorSubmitModal event={ () => {
            setUpdateData(Date.now().toString())
            setModal(null)
        } } order={orderId} closeModal={() => {
            setModal(null)
        }}/>}

        {modal === 'hold' && <OrderHoldSubmitModal event={ () => {
            setUpdateData(Date.now().toString())
            setModal(null)
        } } order={orderId} closeModal={() => {
            setModal(null)
        }}/>}


        {modal === 'accountant-confirmation/set' && <OrderAccountantConfirmationSubmitModal event={ () => {
            setUpdateData(Date.now().toString())
            setModal(null)
        } } order={orderId} closeModal={() => {
            setModal(null)
        }}/>}

        {modal === 'inventory-confirmation/set' && <OrderInventoryConfirmationSubmitModal event={ () => {
            setUpdateData(Date.now().toString())
            setModal(null)
        } } order={orderId} closeModal={() => {
            setModal(null)
        }}/>}

        {modal === 'official-confirmation/set' && <OrderOfficialConfirmationSubmitModal event={ () => {
            setUpdateData(Date.now().toString())
            setModal(null)
        } } order={orderId} closeModal={() => {
            setModal(null)
        }}/>}

        <ul className={className}>
            {operations.map((operation, id) => {

                if( !isPrimaryShop() ){ 
                    if( operation?.nonPrimaryShopAccess !== true ){
                        return null
                    }
                 }

                if (tableData.is_held && operation.name == 'hold') {
                    return (
                        <>
                            <li className={`${operation.name}__item`} key={"xc"+id}>
                                <Link
                                    to={`/orders/edit?id=${tableData._id}`}
                                    className={`flex items-center text-blue-1 hover:text-yellow-2`}>
                                    <FontAwesomeIcon className="ml-1" icon={faEye}/>
                                    مشاهده سفارش
                                </Link>
                            </li>
                            <li className={`${operation.name}__item`} key={"xc"+id+"_hold"}>
                                <button
                                    onClick={async () => {
                                        await ApiRoute.order.action.fn("hold", tableData._id)
                                        setUpdateData(Date.now().toString())
                                    }}
                                    className={`flex items-center text-red-1 hover:text-yellow-2 ${operation.color === 'red' ? 'text-red-1' : ''}`}>
                                    <FontAwesomeIcon className="ml-1" icon={operation.icon}/>
                                    خروج از هولد
                                </button>
                            </li>
                        </>
                    )
                }

                // ... سایر شرط‌های نمایش عملیات ...
                if (tableData.is_held && operation.name != 'hold') return null;
                if (operation?.hasAccess && operation?.hasAccess === false) return null;
                if (operation?.showIf && operation?.showIf(tableData) === false) return null;
                
                // شرط‌های مربوط به سفارش رسمی
                if (tableData.is_official) {
                    if (operation.name === "official_print/disallow" && tableData?.metadata?.show_print_official !== true) return null;
                    if (operation.name === "official_print/allow" && tableData?.metadata?.show_print_official === true) return null;
                    if (operation.name === "official-confirmation/reset" && tableData?.metadata?.official_confirmation?.status !== "submitted") return null;
                    if (operation.name === "official-confirmation/set" && tableData?.metadata?.official_confirmation?.status === "submitted") return null;
                } else {
                    if (operation.name === "official_print/disallow" || operation.name === "official_print/allow") return null;
                    if (operation.name === "official-confirmation/set" || operation.name === "official-confirmation/reset") return null;
                }

                // سایر شرط‌های نمایش
                if (operation.name === "transference/reset" && tableData?.transference?.status !== "submitted") return null;
                if (operation.name === "transference/submit" && tableData?.transference?.status === "submitted") return null;
                if (operation.name === "factor/reset" && tableData?.factor?.status !== "submitted") return null;
                if (operation.name === "factor/submit" && tableData?.factor?.status === "submitted") return null;

                return (
                    <li className={`${operation.name}__item`} key={"vx"+id}>
                        {operation.button_type === "link" ? (
                            <Link
                                to={operation.submit(tableData._id)}
                                className={`flex cursor-pointer items-center hover:text-yellow-2 ${operation.color === 'red' ? 'text-red-1' : ''}`}>
                                <FontAwesomeIcon className="ml-1" icon={operation.icon}/>
                                {operation.title}
                            </Link>
                        ) : (
                            <button
                                onClick={() => operation?.submit(tableData._id , tableData)}
                                className={`flex items-center hover:text-yellow-2 ${operation.color === 'red' ? 'text-red-1' : ''}`}>
                                <FontAwesomeIcon className="ml-1" icon={operation.icon}/>
                                {operation.title}
                            </button>
                        )}
                    </li>
                )
            })}

            {OrderPrintList(tableData).map((operation, id) => {
                //if ((operation.name === "print_official" || operation.name === "print_pre_official") && !showPrint(tableData)) return null;
                return (
                    <li className={`${operation.name}__item`} key={"vx"+id}>
                        <button
                            onClick={() => operation?.submit(tableData._id)}
                            className={`flex items-center hover:text-yellow-2 ${operation.color === 'red' ? 'text-red-1' : ''}`}>
                            <FontAwesomeIcon className="ml-1" icon={operation.icon}/>
                            {operation.title}
                        </button>
                    </li>
                )
            })}
        </ul>
        </>
    );
}

export default OrderOperations;