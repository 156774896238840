import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import ApiRoute, { shopId } from "../../../helper/api";
import { getDateAsReadableFormat } from "../../../helper/general";
import Pagination from "../../../components/Pagination";
import SearchBox from "../../../components/SearchBox";
import Layout from "../../../components/Layout";
import CustomerSelect from "../../../components/Customer/CustomerSelect";
import ProductSelect from "../../../components/Product/ProductSelect";

export default function ProductNotification(){
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const [newData, setNewData] = useState({
        dgshahr_shop_product_id: '',
        product:{}
    })

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.product.notifications.fn({
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    const tableTitles = [
        {
            title: 'محصول',
        },
        {
            title: 'مشتری',
        },
        {
            title: 'تاریخ',
        }
    ]

    async function handleSubmit(){
       const res = await ApiRoute.integrate.dgShahrShop.create.fn(newData)
       if (res?.status_code === 200){
           setCreateModal(false)
           setNewData({
               dgshahr_shop_product_id: '',
               product:{}
           })
           setUpdateData(Date.now())
       }
    }
    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">اطلاع رسانی کالا</span>
                    </div>
                </div>

                <div>
                <div className="flex items-center gap-3 justify-between">
                    <div className="w-1/2">
                        <CustomerSelect  assignItem={ (v) => {
                            filterQuery.customer_id = v?._id ?? ''
                        } }/>
                    </div>
                    <div className="w-1/2">
                        <ProductSelect allowOutStock={true} assignItem={ (v) => {
                            filterQuery.product_id = ''
                            v.map(item => {
                               filterQuery.product_id = item._id
                            })
                        } }/>
                    </div>

                </div>
                <button onClick={() => {
                    setUpdateData(Date.now())
                }} className="btn btn-purple py-1">اعمال فیلتر</button>

                </div>

                {!isLoading && <div className="main-content p-1 mt-2">

                    <table className="table-auto w-full block mt-4">
                        <thead>
                        <tr className="border-b border-b-gray-7">
                            {tableTitles.map((item, id) => (
                                <td className={` text-13 relative text-gray-6 pb-2`} key={id}>
                                    {item.title}
                                </td>
                            ))}
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {tableData?.map(tableData => (
                                <tr className={`text-xs py-2`} key={tableData?.id}>

                                    <td>
                                        <Link to={`/inventory?id=${tableData?.product_id}`} target="_blank">{tableData?.product_info?.title}</Link>
                                    </td>

        
                                    <td>
                                        <Link to={`/customers/view/${tableData?.customer_id}`} target="_blank">{tableData?.customer_info?.name}</Link>
                                    </td>

                                    <td>
                                        {getDateAsReadableFormat(tableData?.created_at)}
                                    </td>

                           {/*          <td>
                                        <div className={`flex items-center gap-3 text-[15px]`}>
                                            <button onClick={ async () => {
                                                await ApiRoute.integrate.dgShahrShop.update.fn(tableData._id, tableData)
                                            } } className={`text-green-1`}>
                                                <FontAwesomeIcon icon={faEdit}/>
                                            </button>
                                            <button onClick={ async () => {
                                                if (!window.confirm('آیا از حذف این محصول اطمینان دارید؟')) {
                                                    return;
                                                }
                                                await ApiRoute.integrate.dgShahrShop.delete.fn(tableData._id)
                                                setUpdateData(Date.now())
                                            } } className={`text-red-1`}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </button>
                                        </div>
                                    </td> */}

                                </tr>
                            )
                        )}
                        </tbody>
                    </table>

                    <Pagination SetPageNumber={setPageNumber} Data={paginationData}/>
                </div>}

            </div>


        </Layout>
    )
}