import Toman from "../../Utilities/Toman";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTimes, faTruckFast} from "@fortawesome/free-solid-svg-icons";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import {formatNumberWithDots} from "../../../helper/general";
import ApiRoute from "../../../helper/api";
import {useState} from "react";
import OrderDirectDiscountModal from "../Modal/OrderDirectDiscountModal";
import { HasAccessComponent } from "../../../helper/permissions";


export default function OrderItems({order, setUpdateState}){
    const [modal,setModal] = useState(false)
    const [item,setItem] = useState(null)
    async function handleDeleteProduct(item){
        const res = await ApiRoute.order.item.deleteProduct.fn(order._id,item.id)
        if (res?.status_code === 200){
            setUpdateState(Date.now());
        }
    }
    async function deleteDirectDiscount(item){
        const res = await ApiRoute.order.item.deleteDirectDiscount.fn(order._id,item.id)
        if (res?.status_code === 200){
            setUpdateState(Date.now());
        }
    }
    function checkRemoveShipmentAccess(){
        if(order?.is_transference === true){
            return false
        }
        if( order.is_official && order?.metadata?.official_confirmation?.status === "submitted" ){
            return false
        }
        return true
    }
    return (
        <div
            className="main-content before-table-style before-purple border border-gray-7 relative rounded-md p-5 mt-4">
            <div className="page-title mb-3">
                <span className="inline-block bg-white pl-3 text-sm">آیتم های سفارش</span>
            </div>
            { order.items.filter( (item) => item.item_type === "product" ).map( (item) => <div className="rounded-md border border-gray-7 p-3 bg-gray-1 text-xs mb-3">
                <div
                    className="grid md:grid-cols-2 xl:grid-cols-3 2xl:flex justify-between items-center gap-3">
                    <p className="md:col-span-2 xl:col-span-3 text-purple-1">{item.item_name}</p>
                    <div>
                        <span className="text-gray-2">تعداد:</span>
                        <span className="text-gray-5 mr-1">{item.quantity} عدد</span>
                    </div>
                    <div>
                        <div className="flex items-center">
                            <span className="text-gray-2">قیمت محصول:</span>
                            <span className="text-gray-5 flex items-center mr-1">
                                {item?.direct_discount?.amount && formatNumberWithDots(item.direct_discount.item_value)}
                                {!item?.direct_discount?.amount && formatNumberWithDots(item.item_value)}
                                <Toman
                                customClass={'fill-gray-5 mr-0.5'}/></span>
                        </div>
                    </div>

                    { (item?.direct_discount?.amount ) && <div>
                        <div className="flex items-center">
                            <span className="text-gray-2">مبلغ تخفیف خطی:</span>
                            <span className="text-gray-5 flex items-center mr-1">{formatNumberWithDots(item.direct_discount.amount)}<Toman
                                customClass={'fill-gray-5 mr-0.5'}/></span>
{/*                            <button className="btn-outline-gray mr-1 py-0.5 px-1 w-6">
                                <FontAwesomeIcon
                                    icon={faEdit}/></button>*/}
                            {order?.is_transference !== true && <button onClick={() => {
                                deleteDirectDiscount(item)
                            }} className="btn-outline-gray mr-1 py-0.5 px-1 w-6">
                                <FontAwesomeIcon
                                    icon={faTimes}/></button>}
                        </div>
                        { item.direct_discount.description &&
                            <p className="mx-auto max-xl:ml-auto max-xl:mr-0 text-white bg-gray-5 w-max px-1 mt-1 rounded text-9 max-xl:mb-4">{item.direct_discount.description}</p>}
                    </div>}

                    { item?.direct_discount?.item_value && <div className="flex items-center">
                        <span className="text-gray-2">مبلغ کل:</span>
                        <span className="text-gray-5 mr-1">{formatNumberWithDots(item.item_value)}</span>
                        <Toman customClass={'fill-gray-5 mr-0.5'}/>
                    </div>}

                </div>

                <div
                    className="grid md:grid-cols-2 xl:flex justify-between items-center mt-3 gap-3 max-xl:mt-3">
                    <div className="grid xl:flex gap-3 2xl:gap-20">
                        <div>
                            <span className="text-gray-2">رنگ:</span>
                            <span className="text-gray-5 mr-1">
                                { item.item_product?.attributes?.color?.values.map( i => i.label ) }
                            </span>
                        </div>
                        <span className="text-gray-3 mr-1">
                                        <FontAwesomeIcon className="ml-2 text-sm" icon={faCircleCheck}/>
                            {item.item_product?.metadata?.guarantee}
                                    </span>
                        <span className="text-gray-3 mr-1">
                                        <FontAwesomeIcon className="ml-2 text-sm" icon={faTruckFast}/>
                            {item.item_product?.metadata?.stock_name}
                                    </span>
                    </div>

                    <div>

                        {(!item?.direct_discount?.amount && order.is_transference !== true) && <button onClick={() => {
                            setModal(true)
                            setItem(item)
                        }} className="btn-outline-gray">تخفیف خطی</button>}

                        {(order.is_transference !== true && order.status != "completed") && <button onClick={() => {
                            handleDeleteProduct(item)
                        }} className="btn-outline-gray text-red-1 mr-2">حذف آیتم</button>}
                    </div>
                </div>
            </div> ) }


            <div className="rounded-md border border-gray-7 p-3 bg-gray-1 text-xs mb-3">

                { order.items.filter( item => item.item_type === "custom" ).map( (item) => <div className="grid sm:flex gap-2 justify-between mb-3">
                    <div className="flex items-center">
                        <span className="text-purple-1">{item.item_asan_id ? `محصول معرفی نشده` : `آیتم سفارشی`}:</span>
                        <span className="text-gray-5 mr-1">{item.item_name}</span>
                        {item?.item_asan_id && <span className="text-gray-5 mr-1">(شناسه آسان: {item.item_asan_id})</span>}
                    </div>
                    <div className="flex items-center">
                        <span className="text-gray-2">مبلغ </span>
                        <span className="text-gray-5 flex items-center mr-1">{formatNumberWithDots(item.item_value)} <Toman
                            customClass={'fill-gray-5 mr-0.5'}/></span>
                       {order?.is_transference !== true && <button onClick={ async () => {
                            const res = await ApiRoute.order.item.deletePrivateProduct.fn(order._id , item.id)
                            if (res?.status_code === 200){
                                setUpdateState(Date.now())
                            }
                        } } className="btn-outline-gray mr-1 py-0.5 px-1 w-6"><FontAwesomeIcon
                            icon={faTimes}/></button>}
                    </div>
                </div> ) }

                { order.items.filter( item => item.item_type === "shipment" ).map( (item) => <div className="grid sm:flex gap-2 justify-between mb-3">
                    <div>
                        <span className="text-purple-1">حمل و نقل:</span>
                        <span className="text-gray-5 mr-1">مبلغ کرایه حمل مشتری</span>
                    </div>
                    <div className="flex items-center">
                        <span className="text-gray-2">مبلغ </span>
                        <span className="text-gray-5 flex items-center mr-1">{formatNumberWithDots(item.item_value)} <Toman
                            customClass={'fill-gray-5 mr-0.5'}/></span>
                       <HasAccessComponent showIf={checkRemoveShipmentAccess()} permission={"add_shipment order"}>
                        <button onClick={ async () => {
                            const res = await ApiRoute.order.item.deleteShipment.fn(order._id , item.id)
                            if (res?.status_code === 200){
                                setUpdateState(Date.now())
                            }
                        } } className="btn-outline-gray mr-1 py-0.5 px-1 w-6"><FontAwesomeIcon
                            icon={faTimes}/></button>
                        </HasAccessComponent>
                    </div>
                </div> ) }

                { order.items.filter( item => item.item_type === "coupon" ).map( (item) => <div className="grid sm:flex gap-2 justify-between mb-3">
                    <div>
                        <span className="text-purple-1">کد تخفیف:</span>
                        <span className="text-gray-5 mr-1">{item.item_name}</span>
                    </div>
                    <div className="flex items-center">
                        <span className="text-gray-2">مبلغ </span>
                        <span className="text-gray-5 flex items-center mr-1">{formatNumberWithDots(item.item_value)} <Toman
                            customClass={'fill-gray-5 mr-0.5'}/></span>
                        {order?.is_transference !== true && <button onClick={ async () => {
                            const res = await ApiRoute.order.item.deleteCoupon.fn(order._id , item.id)
                            if (res?.status_code === 200){
                                setUpdateState(Date.now())
                            }
                        } } className="btn-outline-gray mr-1 py-0.5 px-1 w-6"><FontAwesomeIcon
                            icon={faTimes}/></button>}
                    </div>
                </div> ) }

            </div>


            {(modal && item) && <OrderDirectDiscountModal order={order} item={item} closeModal={ () => {setModal(false)} } event={ () => {
                setModal(false)
                setUpdateState(Date.now())
            } } />}

        </div>
    )
}