import Layout from "../../components/Layout";
import React, {useEffect, useState, createContext, useContext} from "react";
import ImageUploader from "../../components/Utilities/ImageUploader";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { register } from 'swiper/element/bundle';
import {Link, useNavigate} from "react-router-dom";
import { faCheckDouble, faChevronDown, faChevronLeft, faChevronRight, faChevronUp,faTrash, faGripVertical} from '@fortawesome/free-solid-svg-icons';
import {Editor} from "@tinymce/tinymce-react";
import ImageSlider from "../../components/Template/Component/ImageSlider";
import IconSlider from "../../components/Template/Component/IconSlider";
import Banner from "../../components/Template/Component/Banner";
import CompactBox from "../../components/Template/Component/CompactBox";
import BlogBox from "../../components/Template/Component/BlogBox";
import TabBox from "../../components/Template/Component/TabBox";
import TopSellProducts from "../../components/Template/Component/TopSellProducts";
import CampaignBox from "../../components/Template/Component/CampaignBox";
import ApiRoute from "../../helper/api";
import templateComponents from "../../helper/template-components";
import {v4 as uuidv4} from "uuid";
import ContentBox from "../../components/Template/Component/ContentBox";
import { toast } from "react-toastify";
import ContentImageBox from "../../components/Template/Component/ContentImageBox";
import HtmlBox from "../../components/Template/Component/HtmlBox";
import { DndContext, pointerWithin } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// Create a context to share the dragging state
const DragContext = createContext({ isDragging: false });

function SortableItem({ id, children }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative',
    opacity: isDragging ? 0.5 : 1
  };

  return (
    <DragContext.Provider value={{ isDragging }}>
      <div ref={setNodeRef} style={style} className="sortable-item">
        <div className="drag-handle" {...attributes} {...listeners} style={{
          position: 'absolute',
          top: '10px',
          left: '170px',
          zIndex: 100,
          cursor: 'grab',
          background: '#f0f0f0',
          padding: '5px',
          borderRadius: '3px',
          height: '30px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <FontAwesomeIcon icon={faGripVertical} />
        </div>
        {children}
      </div>
    </DragContext.Provider>
  );
}

// Higher-order component to wrap buttons with drag context awareness
function withDragContext(Component) {
  return function WrappedComponent(props) {
    const { isDragging } = useContext(DragContext);
    
    // Disable click events when dragging
    const enhancedProps = {
      ...props,
      onClick: isDragging ? (e) => e.preventDefault() : props.onClick,
      style: {
        ...props.style,
        pointerEvents: isDragging ? 'none' : 'auto'
      }
    };
    
    return <Component {...enhancedProps} />;
  };
}

// Create drag-aware button components
const DragAwareButton = withDragContext((props) => <button {...props} />);

function EditTemplate() {

    const navigate = useNavigate()
    const [data,setData] = useState(null)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [components, setComponents] = useState([]);


    const [updateState, setUpdateState] = useState(1)
    const [newComponent, setNewComponent] = useState(null)


    const [componentList, setComponentList] = useState(templateComponents())

    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if( params.get('id') == null ){
            return navigate("/templates")
        }

        (async () => {
            const response = await ApiRoute.template.single.fn( params.get('id'),params.get('as-slug') )
            setData(response.results)
            setComponents(response.results.components)
        })()

    }, []);

    function lastOrder() {
        let lastItem = null;
        components.sort((firstItem, secondItem) => firstItem.order - secondItem.order).map(item => {
            lastItem = item
            return
        })
        if (lastItem?.order) {
            return lastItem.order + 1;
        }
        return 0
    }

    function moveData(data, index, action = 'up') {
        let inx = index - 1
        if (action != 'up') {
            inx = index + 1
        }

        if (index == 0 && action == 'up') {
            return;
        }

        let el = data.order;
        data.order = components[inx].order;

        components.map((item, i) => {
            if (i == (inx)) {
                item.order = el
            }
        })
        setUpdateState(Date.now())
        
    }

    function reorderComponents() {
        components.sort((a, b) => a.order - b.order).forEach((component, index) => {
            console.log(index)
            component.order = index;
        });
        console.log(components)

        setUpdateState(Date.now());
    }

    async function handleSave() {
       await ApiRoute.template.update.fn(data._id, {
            title: data.title,
            slug: data.slug,
            components: components
       });
    }    
    async function handleReorder() {
        reorderComponents();
        toast.success('چیدمان با موفقیت نرمالایز شد. میتوانید ذخیره کنید')
    }

    const handleDragStart = (event) => {
        setIsDragging(true);
    };

    const handleDragEnd = (event) => {
        setIsDragging(false);
        const { active, over } = event;

        if (active.id !== over.id) {
            setComponents((items) => {
                const oldIndex = items.findIndex(item => item.id === active.id);
                const newIndex = items.findIndex(item => item.id === over.id);

                return arrayMove(items, oldIndex, newIndex);
            });
            //reorderComponents();
        }

        
    };

    return (
        <Layout>
            <div>

                <div className="page-title mb-5 flex justify-between">
                    <span className="inline-block bg-white pl-3">قالب ({data?.title})</span>
                    {data && <div className="pr-2 lg:hidden bg-white">
                        <button onClick={handleSave} className="btn-green text-xs py-2.5 rounded px-8">ذخیره قالب</button>
                    </div>}
                </div>

                {data && <div className={`flex gap-3 items-center`}>
                    <input onKeyUp={ e => { data.title = e.target.value } } className={`input-x`} type={`text`} defaultValue={data.title}/>
                    <input onKeyUp={ e => { data.slug = e.target.value } } className={`input-x text-left`} dir={`ltr`} type={`text`} defaultValue={data.slug}/>
                </div>}

                <div className="page-title mb-3 text-13"><span className="inline-block bg-white pl-3">سکشن ها</span></div>

                <div className="form">
                    <div className="grid grid-cols-1 lg:grid-cols-2 text-xs">
                        <div className="flex flex-auto gap-3">
                            <select onChange={ (e) => {
                                setNewComponent(e.target.value)
                            } } className="!mr-0">
                                <option>سکشن موردنظر را انتخاب کنید</option>
                                {componentList.map( (component) => <option value={component.name} key={component.name}>{component.label}</option> )}
                            </select>
                            <DragAwareButton onClick={ () => {

                                let c = newComponent

                                if (c === null){alert('انتخاب سکشن اجباری است')}

                                templateComponents().map( (ct) => {
                                    if (ct.name === c){
                                        alert(ct.name)
                                        const copiedObject = ct.data;
                                        copiedObject.id = uuidv4()
                                        copiedObject.order = lastOrder()
                                        console.log(copiedObject)
                                        setComponents(prevList => [...prevList, copiedObject]);
                                    }
                                } )


                            } } className="btn-blue-light text-xs py-1.5">افزودن سکشن جدید</DragAwareButton>
                            
                            <DragAwareButton onClick={handleReorder} className="btn-info text-red-4 border-2 border-red-4 text-xs py-2.5 rounded px-2">نرمالایز سازی چیدمان</DragAwareButton>

                        </div>
                        {data && <div className="text-left hidden lg:block">
                            <DragAwareButton onClick={handleSave} className="btn-green text-xs py-2.5 rounded px-8">ذخیره قالب</DragAwareButton>
                        </div>}
                    </div>
                </div>

                <DndContext 
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    modifiers={[]}
                >
                    <SortableContext items={components ? components.map(component => component.id) : []}>
                        {components && components.map((component, index) => (
                            <SortableItem key={component.id} id={component.id}>
                                {component.component == 'image_slider' && <ImageSlider remove={!isDragging ? () => {
                                    components.splice(index, 1)
                                    setUpdateState(Date.now())
                                } : null} up={!isDragging ? () => {
                                    moveData(component,index)
                                } : null} down={!isDragging ? () => {
                                    moveData(component,index,'down')
                                } : null} component={component}/>}
                                {component.component == 'icon_slider' && <IconSlider remove={!isDragging ? () => {
                                    components.splice(index, 1)
                                    setUpdateState(Date.now())
                                } : null} up={!isDragging ? () => {
                                    moveData(component,index)
                                } : null} down={!isDragging ? () => {
                                    moveData(component,index,'down')
                                } : null} component={component}/>}
                                {component.component == 'banner' && <Banner refresh={ () => {
                                    setComponents(components)
                                    setUpdateState(Date.now())
                                } } remove={!isDragging ? () => {
                                    components.splice(index, 1)
                                    setUpdateState(Date.now())
                                } : null} up={!isDragging ? () => {
                                    moveData(component,index)
                                } : null} down={!isDragging ? () => {
                                    moveData(component,index,'down')
                                } : null} component={component}/>}
                                {component.component == 'compact_box' && <CompactBox remove={!isDragging ? () => {
                                    components.splice(index, 1)
                                    setUpdateState(Date.now())
                                } : null} up={!isDragging ? () => {
                                    moveData(component,index)
                                } : null} down={!isDragging ? () => {
                                    moveData(component,index,'down')
                                } : null} component={component}/>}
                                {component.component == 'tabs' && <TabBox remove={!isDragging ? () => {
                                    components.splice(index, 1)
                                    setUpdateState(Date.now())
                                } : null} up={!isDragging ? () => {
                                    moveData(component,index)
                                } : null} down={!isDragging ? () => {
                                    moveData(component,index,'down')
                                } : null} component={component}/>}
                                {component.component == 'blog' && <BlogBox remove={!isDragging ? () => {
                                    components.splice(index, 1)
                                    setUpdateState(Date.now())
                                } : null} up={!isDragging ? () => {
                                    moveData(component,index)
                                } : null} down={!isDragging ? () => {
                                    moveData(component,index,'down')
                                } : null} component={component}/>}
                                {component.component == 'top_sell_products' && <TopSellProducts remove={!isDragging ? () => {
                                    components.splice(index, 1)
                                    setUpdateState(Date.now())
                                } : null} up={!isDragging ? () => {
                                    moveData(component,index)
                                } : null} down={!isDragging ? () => {
                                    moveData(component,index,'down')
                                } : null} component={component}/>}
                                {component.component == 'campaigns' && <CampaignBox remove={!isDragging ? () => {
                                    components.splice(index, 1)
                                    setUpdateState(Date.now())
                                } : null} up={!isDragging ? () => {
                                    moveData(component,index)
                                } : null} down={!isDragging ? () => {
                                    moveData(component,index,'down')
                                } : null} component={component}/>}                        
                                {component.component == 'content_box' && <ContentBox remove={!isDragging ? () => {
                                    components.splice(index, 1)
                                    setUpdateState(Date.now())
                                } : null} up={!isDragging ? () => {
                                    moveData(component,index)
                                } : null} down={!isDragging ? () => {
                                    moveData(component,index,'down')
                                } : null} component={component}/>}
                                {component.component == 'content_image_box' && <ContentImageBox remove={!isDragging ? () => {
                                    components.splice(index, 1)
                                    setUpdateState(Date.now())
                                } : null} up={!isDragging ? () => {
                                    moveData(component,index)
                                } : null} down={!isDragging ? () => {
                                    moveData(component,index,'down')
                                } : null} component={component}/>}
                                {component.component == 'html_box' && <HtmlBox remove={!isDragging ? () => {
                                    components.splice(index, 1)
                                    setUpdateState(Date.now())
                                } : null} up={!isDragging ? () => {
                                    moveData(component,index)
                                } : null} down={!isDragging ? () => {
                                    moveData(component,index,'down')
                                } : null} component={component}/>}
                            </SortableItem>
                        ))}
                    </SortableContext>
                </DndContext>

                { (data && components) && <div className="text-left">
                    <DragAwareButton onClick={handleSave} className="btn-green text-xs py-2.5 rounded px-10">ذخیره قالب</DragAwareButton>
                </div>}

            </div>


        </Layout>
    )
}

export default EditTemplate