import Layout from "../../components/Layout";
import {Link} from "react-router-dom";
import SearchBox from "../../components/SearchBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faFilter, faTrash} from "@fortawesome/free-solid-svg-icons";
import CustomMultiSelect from "../../components/Utilities/CustomMultiSelect";
import {useEffect, useRef, useState} from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ApiRoute from "../../helper/api";
import CategorySelect from "../../components/Product/Category/CategorySelect";
import RuleForm from "../../components/Price/RuleForm";
import {faRemove} from "@fortawesome/free-solid-svg-icons/faRemove";
import CreatePriceListModal from "../../components/PriceList/CreatePriceListModal";
import CreatePriceRuleBrandModal from "../../components/Price/CreatePriceRuleBrandModal";
import BrandSelect from "../../components/Product/Category/BrandSelect";

export default function EditPricingRules() {

    const MySwal = withReactContent(Swal)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [createModal, setCreateModal] = useState(false);
    const [update, setUpdate] = useState(0);
    const [updateQuery, setUpdateQuery] = useState(0);
    const [data, setData] = useState(null);
    const [showBrands, setShowBrands] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [productType, setProductType] = useState(false);

    const [editData,setEditData] = useState({});


    useEffect(() => {
        getData()
    }, [updateQuery]);

    useEffect(() => {
        if (data){

            setEditData({
                title : data.title,
                id : data.id,
                price_rules : data.price_rules,
                brands : data.brands,
                categories: data.categories
            })
            setProductType( data?.products?.length > 0 )

        }

    }, [data]);


    async function getData(){
        const response = await ApiRoute.price.rule.single.fn(params.get('id'))
        setData(response.results)
    }

    async function handleSubmit() {
       if (productType){
           await ApiRoute.price.rule.updateAsProduct.fn(data._id,editData)
       }else {
           await ApiRoute.price.rule.update.fn(data._id,editData)
       }
    }

    return (
        <Layout>
            <div>
                {data && <div className="page-title mb-3"><span className="inline-block bg-white pl-3">قوانین قیمت گذاری ({editData.title})</span></div>}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:flex justify-between items-center gap-3">
                    <div className="flex order-2 sm:order-1">
                        {!productType && <button onClick={ () => {
                            setShowBrands(!showBrands)
                        } } className="btn-green flex items-center gap-2 px-8 py-2 mr-3">
                            <span>برندها</span>
                            <FontAwesomeIcon icon={!showBrands ? faEyeSlash : faEye}/>
                        </button>}
                    </div>
                    {!productType && <div className="flex justify-end gap-2 order-1 sm:order-2">
                        <button onClick={ () => { setCreateModal(true); setShowBrands(true) } } className="btn-purple rounded max-sm:w-1/2 max-lg:py-2">افزودن برند</button>
                        {/*<button className="btn-purple rounded max-sm:w-1/2 max-lg:py-2">افزودن فروشگاه جدید</button>*/}
                    </div>}
                    <button onClick={ async () => { 
                        if(!window.confirm('آیا از دریافت مجدد فروشگاه‌ها اطمینان دارید؟')) {
                            return;
                        }
                        await ApiRoute.price.rule.reImportShops.fn(params.get('id'))
                        window.location.reload()
                     } } className="btn-purple rounded max-sm:w-1/2 max-lg:py-2">دریافت مجدد فروشگاه ها</button>

                </div>

                <div className="main-content p-5 mt-3">
                    {data && <div className="grid grid-cols-1 lg:grid-cols-7 gap-4 lg:gap-8 text-xs">
                        <div>
                            <label className="grid lg:flex items-center gap-3">
                                <input onKeyUp={ (e) => {
                                    editData.title = e.target.value;
                                } } defaultValue={editData.title} className="bordered-input-7 flex-auto" type="text"
                                       placeholder="نام قانون را وارد کنید"/>
                            </label>
                        </div>

                        {!productType && <div className="lg:col-span-6 grid lg:flex items-center gap-3">
                            <div className="CustomMultiSelectStyle2 flex-auto">
                                <CategorySelect defaultValue={editData.categories} assignItem={ (v) => {
                                    let arr = []
                                    v.map(  (x) => {
                                        arr.push(x.slug)
                                    })
                                    editData.categories = arr
                                } }/>
                            </div>

                        </div>}

                    </div>}

                    {(data && !showBrands) && <RuleForm editData={editData} data={data}/>}

                    { (showBrands && editData?.brands?.length > 0) && <div className={`flex mt-5 flex-col gap-5`}>
                            <h3>برند ها</h3>
                        {
                            editData.brands.map( (br,i) => <div className={`w-full`} key={i}>

                                <div className={`flex items-center justify-between`}>
                                    <div onClick={ () => {
                                        if (selectedBrand !== null){setSelectedBrand(null)}else{setSelectedBrand(br)}
                                    } } className="bordered-input-7 cursor-pointer w-full">
                                        {br.title}
                                        {br.brand_slugs?.length < 1 && '⚠️'}
                                    </div>
                                    <FontAwesomeIcon onClick={ () => {
                                        editData.brands.splice(i,1)
                                        setUpdate(Date.now())
                                    } } className={`bg-red-1 text-white p-2`} icon={faTrash}/>
                                </div>

                                {selectedBrand?.id === br.id && <div className={`p-4 bordered-input-7`}>
                                    <input onKeyUp={ (e) => {
                                        br.title = e.target.value;
                                    } } className="bordered-input-7 w-full" defaultValue={br.title}/>

                                    <BrandSelect customDefaultValue={false} defaultValue={br.brand_slugs} assignItem={(s) => {
                                        let arr = []
                                        s.map( (v) => {
                                            arr.push(v.value)
                                        })
                                        br.brand_slugs = arr
                                    }}/>

                                    <div>
                                        <RuleForm editData={br} data={data}/>
                                    </div>

                                </div>}

                            </div>)
                        }

                    </div>}

                </div>


                <div className="col-span-4 flex justify-center mx-auto gap-5 my-5">
                    {/*<Link to="/employees" className="btn-gray w-28 py-1.5">لغو</Link>*/}
                    <button onClick={handleSubmit} className="btn-purple rounded w-28 py-1.5">بروزرسانی</button>
                </div>


                {createModal && <CreatePriceRuleBrandModal ruleId={params.get('id')}
                    closeModal={ () => {
                        setCreateModal(false);
                    } } event={ (res) => {
                    setUpdateQuery(Date.now())
                } }/>}

            </div>
        </Layout>
    )
}