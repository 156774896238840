import axios from "axios";
import {toast} from "react-toastify";
import useToken from "../useToken";
import {startLoader, stopLoader} from "./general";
import DeliveryDateClassUnavailable from "../pages/shipment/delivery-date-class-unavailable";

const API_URL = () => {
    if (process.env.NODE_ENV === 'development') {
        return "http://127.0.0.1:8000/v1/core"
    } else {
        return "https://api.entekhabcenter.com/v1/core"
    }
}
export const getEmployeeId = () => {
    const tokenString = localStorage.getItem('token_user');
    let r = JSON.parse(tokenString)
    return r?.employee?._id ?? null
};
const getAccessToken = () => {
    const tokenString = localStorage.getItem('token_user');
    let r = JSON.parse(tokenString)
    return r?.access_token ?? null
};
export const shopId = function (){
    const tokenString = localStorage.getItem('token_user');
    let r = JSON.parse(tokenString)
    return r?.employee?.shop_id ?? null
}

export const isPrimaryShop = function (){
    const tokenString = localStorage.getItem('token_user');
    let r = JSON.parse(tokenString)
    return r?.employee?.shop_info?.primary ?? false
}

export const shopInfo = function (name){
    const tokenString = localStorage.getItem('token_user');
    let r = JSON.parse(tokenString)
    return r?.employee?.shop_info?.[name] ?? null
}

let CURRENT_REQUEST = null
let DISABLE_LOADING = false

export const axiosConfig = {
    headers: {
        "ENTC-APP-KEY": "091583eae1af8da101eca5c678b4d662",
        "ENTC-SHOP-ID": shopId(),
        "ENTC-EMPLOYEE-ID": getEmployeeId(),
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
    },
    headersx: {
        "ENTC-APP-KEY": "091583eae1af8da101eca5c678b4d662",
        "ENTC-SHOP-ID": shopId(),
        "ENTC-EMPLOYEE-ID": getEmployeeId(),
        'Authorization': `Bearer ${getAccessToken()}`
    }
}

const getHttp = () => {
    if (!isDev()) {
        return axiosClient
    }
    return fetchClient
}

const axiosClient = axios.create({
    baseURL: API_URL(),
    maxBodyLength: Infinity,
    timeout: 50000,
    headers: axiosConfig.headers
});

axiosClient.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log(1111111111111111111111111)
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if(error.response.status === 498){
        localStorage.removeItem('token_user')
        window.location.replace('/')
    }

    return Promise.reject(error);
});

const fetchClient = {
    get: async (endpoint) => {
        return await fetch(API_URL() + endpoint, {
            method: 'GET',
            headers: axiosConfig.headers,
        })
    },
    post: async (endpoint, body) => {
        return await fetch(API_URL() + endpoint, {
            method: 'POST',
            headers: axiosConfig.headers,
            body: JSON.stringify(body)
        })
    },
    delete: async (endpoint, body = null) => {
        return await fetch(API_URL() + endpoint, {
            method: 'DELETE',
            headers: axiosConfig.headers
        })
    }
}

const getResponse = async (r) => {
    if (!isDev()) {
        return await r?.data ?? []
    }
    const d = await r.json();
    return d
}


export const uploadUrl = API_URL() + "/file-upload/s3"
export const readUploadUrl = API_URL() + "/party/load-file"

const ApiRoute = {
    reservation:{
        index:{
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/reservations' + queryParams, bodyQuery)
                })
            }
        },
        delete:{
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().delete('/reservations/delete/' + id)
                })
            }
        }
    },
    search:{
        content:{
            fn: async (search_text,search_type) => {
                return await wrapRequest(async () => {
                    return await getHttp().post('/search/product-page-content',{search_text,search_type})
                })
            }
        }
    },
    notification:{
        system: {
            fn: async () => {

                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    DISABLE_LOADING = true
                    return await getHttp().get('/notifications/system')
                })
            }
        },
        close: {
            fn: async (notification_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    DISABLE_LOADING = true
                    return await getHttp().get('/notifications/system/close/' + notification_id)
                })
            }
        },
    },
    comments:{
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/comments' + queryParams, bodyQuery)
                })
            }
        },
        approved: {
            fn: async (comment_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post(`/comments/update/status/${comment_id}`, {
                        status:"approved"
                    })
                })
            }
        },
        trash: {
            fn: async (comment_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post(`/comments/update/status/${comment_id}`, {
                        status:"trash"
                    })
                })
            }
        },
    },
    export:{
        order:{
            fn: async (from,to) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/export/order', {
                        from:from,
                        to:to
                    })
                })
            }
        }
    },
    analytics:{
        widget:{
            latestOrder: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/analytics/widget/latest-order' + queryParams, bodyQuery)
                    })
                }
            },
            shopInfo: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/analytics/widget/shop-info' + queryParams, bodyQuery)
                    })
                }
            },
            productInfo: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/analytics/widget/product-info' + queryParams, bodyQuery)
                    })
                }
            },
            customerInfo: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/analytics/widget/customer-info' + queryParams, bodyQuery)
                    })
                }
            },
            orderInfo: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/analytics/widget/order-info' + queryParams, bodyQuery)
                    })
                }
            },
            paymentInfo: {
                fn: async (paymentMethod,queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/analytics/widget/payment-info/' + paymentMethod + queryParams, bodyQuery)
                    })
                }
            },
            shaparakPaymentInfo: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/analytics/widget/shaparak-payment-info' + queryParams, bodyQuery)
                    })
                }
            },
            debitPaymentInfo: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/analytics/widget/debit-payment-info' + queryParams, bodyQuery)
                    })
                }
            },
            sellInfo: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/analytics/widget/sell-info' + queryParams, bodyQuery)
                    })
                }
            },
        }
    },
    form: {
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/forms' + queryParams, bodyQuery)
                })
            }
        },
        export: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/forms/export/csv/' + id)
                })
            }
        },
        inbox: {
            index: {
                fn: async (form_id, filterQuery) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/forms/inbox/form/' + form_id, filterQuery)
                    })
                }
            },
            read: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/forms/inbox/read/' + id)
                    })
                }
            }
        }
    },
    shop: {
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/shops' + queryParams, bodyQuery)
                })
            }
        },
        get: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/shops/get' + queryParams, bodyQuery)
                })
            }
        },
        single: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/shops/' + id)
                })
            }
        },
        update: {
            fn: async (id, bodyQuery) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/shops/update/' + id, bodyQuery)
                })
            }
        },
        create: {
            fn: async (bodyQuery) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/shops/create', bodyQuery)
                })
            }
        },
    },
    integrateTask: {
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/integrate/tasks' + queryParams, bodyQuery)
                })
            }
        },
        count: {
            fn: async () => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/integrate/tasks/count')
                })
            }
        },
        send: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/integrate/tasks/send/' + id)
                })
            }
        }
    },
    stock: {
        alert: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/products/alert-stock' + queryParams, bodyQuery)
                    })
                }
            },
            stats: {
                fn: async () => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        DISABLE_LOADING = true
                        return await getHttp().get('/products/alert-stock/stats')
                    })
                }
            },
            updateStatus: {
                fn: async (status , id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/alert-stock/status/'+ status +"/"+ id)
                    })
                }
            },
            delete: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().delete('/products/alert-stock/delete/' + id)
                    })
                }
            },
        },
        receiver: {
            delete: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().delete('/products/alert-stock-receivers/delete/' + id)
                    })
                }
            },  
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/products/alert-stock-receivers' + queryParams, bodyQuery)
                    })
                }
            },
            update: {
                fn: async (id, bodyQuery) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/alert-stock-receivers/update/' + id, bodyQuery)
                    })
                }
            },
            create: {
                fn: async (bodyQuery) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/alert-stock-receivers/create', bodyQuery)
                    })
                }
            },
        }
    },
    price: {
        timing: {
            index: {
                fn: async (bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/prices/timing', bodyQuery)
                    })
                }
            },
            update: {
                fn: async (id, bodyQuery) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/prices/timing/update/' + id, bodyQuery)
                    })
                }
            },
            create: {
                fn: async (bodyQuery) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/prices/timing/create', bodyQuery)
                    })
                }
            },
            delete: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().delete('/prices/timing/delete/' + id)
                    })
                }
            },
        },
        rule: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/prices/rules' + queryParams, bodyQuery)
                    })
                }
            },
            delete: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().delete('/prices/rules/delete/' + id)
                    })
                }
            },
            getProductRule: {
                fn: async (id,bodyQuery = {}) => {
                    return await wrapRequest(async () => {

                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/prices/rules/product/' + id,bodyQuery)
                    })
                }
            },
            getProductRuleMinPrice: {
                fn: async (id,ref_price) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/prices/rules/product/min-price/'+ id+"/"+ref_price)
                    })
                }
            },
            getProductRuleCheck: {
                fn: async (id,price,ref_price) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/prices/rules/product/check/'+ id+"/"+price+"/"+ref_price)
                    })
                }
            },
            single: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/prices/rules/' + id)
                    })
                }
            },
            update: {
                fn: async (id, bodyQuery) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/prices/rules/update/' + id, bodyQuery)
                    })
                }
            },
            updateAsProduct: {
                fn: async (id, bodyQuery) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/prices/rules/update/product/' + id, bodyQuery)
                    })
                }
            },
            create: {
                fn: async (bodyQuery) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/prices/rules/create', bodyQuery)
                    })
                }
            },
            createAsProduct: {
                fn: async (bodyQuery) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/prices/rules/create/product', bodyQuery)
                    })
                }
            },
            createBrand: {
                fn: async (id, bodyQuery) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/prices/rules/create/brand/' + id, bodyQuery)
                    })
                }
            },
            reImportShops: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/prices/rules/re-import-shops/' + id)
                    })
                }   
            }
        },
    },
    auth: {
        login: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/authentication/employee/login', bodyQuery)
                })
            }
        },
        loginWithId: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/authentication/employee/login-by-id/'+ id)
                })
            }
        },
        updateProfile: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/employees/profile-update/' + id, bodyQuery)
                })
            }
        }
    },
    coordinate: {
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/coordinates' + queryParams, bodyQuery)
                })
            }
        },
        search: {
            fn: async () => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/coordinates')
                })
            }
        },
        updateCity: {
            fn: async (state_id, city_uid, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/coordinates/update/city/' + state_id + "/" + city_uid, bodyQuery)
                })
            }
        },
        updateArea: {
            fn: async (state_id, city_uid, area_uid, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/coordinates/update/area/' + state_id + "/" + city_uid + "/" + area_uid, bodyQuery)
                })
            }
        },
        createArea: {
            fn: async (state_id, city_uid, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/coordinates/create/area/' + state_id + "/" + city_uid, bodyQuery)
                })
            }
        },
        createCity: {
            fn: async (state_id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/coordinates/create/city/' + state_id, bodyQuery)
                })
            }
        },        
        parsiMap: {
            fn: async (stateId, cityId,cityName) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get(`/coordinates/parsimap/code/${stateId}/${cityId}?city_name=${cityName}`)
                })
            }
        },
    },
    shipment: {
        quickCheck: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/shipment/classes/quick-check', bodyQuery)
                })
            }
        },
        transport: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/shipment/transports' + queryParams, bodyQuery)
                    })
                }
            },
            single: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/shipment/transports/' + id)
                    })
                }
            },
            create: {
                fn: async (body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/shipment/transports/create', body)
                    })
                }
            },
            delete: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().delete('/shipment/transports/delete/' + id)
                    })
                }
            },
            update: {
                fn: async (id, body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/shipment/transports/update/' + id, body)
                    })
                }
            },
        },
        logico: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/delivery/drivers' + queryParams, bodyQuery)
                    })
                }
            },
            create: {
                fn: async (body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/delivery/drivers/create', body)
                    })
                }
            },
            update: {
                fn: async (id, body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/delivery/drivers/update/' + id, body)
                    })
                }
            },
        },
        delivery: {
            getDeliveryPeriods: {
                fn: async ( bodyQuery ={}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/delivery/get-delivery-periods', bodyQuery)
                    })
                }
            },
            getDeliveryPickCapacity: {
                fn: async ( pick_id ) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/delivery/get-delivery-pick-capacity/'+pick_id)
                    })
                }
            },
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/delivery/date-classes' + queryParams, bodyQuery)
                    })
                }
            },
            create: {
                fn: async (body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/delivery/date-classes/create', body)
                    })
                }
            },
            update: {
                fn: async (id, body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/delivery/date-classes/update/' + id, body)
                    })
                }
            },
        },
        deliveryInPerson: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/delivery/in-person-date-classes' + queryParams, bodyQuery)
                    })
                }
            },
            create: {
                fn: async (body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/delivery/in-person-date-classes/create', body)
                    })
                }
            },
            update: {
                fn: async (id, body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/delivery/in-person-date-classes/update/' + id, body)
                    })
                }
            },
        },
        deliveryStartAt: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/delivery/date-classes-start-at' + queryParams, bodyQuery)
                    })
                }
            },
            create: {
                fn: async (body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/delivery/date-classes-start-at/create', body)
                    })
                }
            },
            update: {
                fn: async (id, body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/delivery/date-classes-start-at/update/' + id, body)
                    })
                }
            },
            delete: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().delete('/delivery/date-classes-start-at/destroy/' + id)
                    })
                }
            },
        },
        deliveryUnavailable: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/delivery/date-classes-unavailable' + queryParams, bodyQuery)
                    })
                }
            },
            create: {
                fn: async (body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/delivery/date-classes-unavailable/create', body)
                    })
                }
            },
            update: {
                fn: async (id, body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/delivery/date-classes-unavailable/update/' + id, body)
                    })
                }
            },
            delete: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().delete('/delivery/date-classes-unavailable/destroy/' + id)
                    })
                }
            },
        },
        classes: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/shipment/classes' + queryParams, bodyQuery)
                    })
                }
            },
            clone: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/shipment/classes/clone/' + id)
                    })
                }
            },
            update: {
                fn: async (id, body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/shipment/classes/update/' + id, body)
                    })
                }
            },
            create: {
                fn: async (body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/shipment/classes/create', body)
                    })
                }
            },
        }
    },
    customer: {
        convertToForeigner: {
            fn: async (customer_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/customers/convert-to-foreigner/' + customer_id)
                })
            }
        },
        getByUsernames: {
            fn: async (slugs) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/customers/get-by-usernames', {
                        usernames: slugs,
                        shop_id: shopId(),
                    })
                })
            }
        },
        getByIds: {
            fn: async (ids) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/customers/get-by-ids', {
                        ids: ids
                    })
                })
            }
        },
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/customers' + queryParams, bodyQuery)
                })
            }
        },

        stats: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/customers/stats' + queryParams, bodyQuery)
                })
            }
        },
        single: {
            fn: async (customer_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/customers/' + customer_id)
                })
            }
        },
        updateAsanId: {
            fn: async (customer_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/customers/asan/update/id/' + customer_id)
                })
            }
        },
        updateGeneral: {
            fn: async (customer_id, body) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/customers/update/general/' + customer_id, body)
                })
            }
        },
        updateLegalInfo: {
            fn: async (customer_id, body) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/customers/update/general/legal/' + customer_id, body)
                })
            }
        },
        create: {
            fn: async (body, thenCallback = null) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/customers/create', body)
                }, thenCallback)
            }
        },
        createAddress: {
            fn: async (customer_id, body, thenCallback = null) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/customers/addresses/create/' + customer_id, body)
                }, thenCallback)
            }
        },
        updateAddress: {
            fn: async (customer_id, address_id, body) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/customers/addresses/update/' + customer_id + '/' + address_id, body)
                })
            }
        },
        activeAddress: {
            fn: async (customer_id, address_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/customers/addresses/active/' + customer_id + '/' + address_id)
                })
            }
        },
        deleteAddress: {
            fn: async (customer_id, address_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await axios.delete('/customers/addresses/delete/' + customer_id + '/' + address_id)
                })
            }
        },
        depositWallet: {
            fn: async (customer_id, body) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/customers/wallet/deposit/gateway/' + customer_id, body)
                })
            }
        },
        refundWallet: {
            fn: async (customer_id, body) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/customers/wallet/refund/' + customer_id, body)
                })
            }
        },
        createWalletChargePaymentLink: {
            fn: async (customer_id, amount) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/customers/wallet/charge/create/' + customer_id,{
                        amount: amount
                    })
                })
            }
        }
    },
    product: {
        notifications: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/products/notifications' + queryParams, bodyQuery)
                })
            }
        },
        resetPriceExpired: {
            fn: async (product_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/products/inventory/update/price-expired/' + product_id)
                })
            }
        },
        clone: {
            fn: async (product_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/products/clone/' + product_id)
                })
            }
        },
        getBySlugs: {
            fn: async (slugs) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/products/slugs', {
                        slugs: slugs
                    })
                })
            }
        },
        getByIds: {
            fn: async (ids) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/products/ids', {
                        ids: ids
                    })
                })
            }
        },
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/products' + queryParams, bodyQuery)
                })
            }
        },

        statusStats: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/products/stats/status' + queryParams, bodyQuery)
                })
            }
        },

        single: {
            fn: async (product_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/products/' + product_id)
                })
            }
        },
        updateStatus: {
            fn: async (product_id, status) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/products/update/status/' + product_id + '/' + status)
                })
            }
        },
        create: {
            stepOne: {
                fn: async (bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/create/step-1', bodyQuery)
                    })
                }
            },
            stepTwo: {
                fn: async (product_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/create/step-2/' + product_id, bodyQuery)
                    })
                }
            },
            stepThree: {
                fn: async (product_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/create/step-3/' + product_id, bodyQuery)
                    })
                }
            },
            stepFour: {
                fn: async (product_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/create/step-4/' + product_id, bodyQuery)
                    })
                }
            },
            stepFive: {
                fn: async (product_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/create/step-5/' + product_id, bodyQuery)
                    })
                }
            }
        },

        category: {

            create: {
                fn: async (bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/categories/create', bodyQuery)
                    })
                }
            },

            update: {
                fn: async (id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/categories/update/' + id, bodyQuery)
                    })
                }
            },

            single: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/categories/' + id)
                    })
                }
            },

            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/categories' + queryParams, bodyQuery)
                    })
                }
            },
            getBySlugs: {
                fn: async (slugs) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/categories/slugs', {
                            slugs: slugs
                        })
                    })
                }
            }

        },

        tag: {

            index: {
                fn: async (bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/tags', bodyQuery)
                    })
                }
            },

            single: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/tags/' + id)
                    })
                }
            },

            create: {
                fn: async (bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/tags/create', bodyQuery)
                    })
                }
            },

            update: {
                fn: async (id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/tags/update/' + id, bodyQuery)
                    })
                }
            },

            getBySlugs: {
                fn: async (slugs) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/tags/slugs', {
                            slugs: slugs
                        })
                    })
                }
            }

        },


        brand: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/products/attributes/brands' + queryParams, bodyQuery)
                    })
                }
            },
            getByValue: {
                fn: async (bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/products/attributes/brands/label-by-value', bodyQuery)
                    })
                }
            }
        },

        attributes: {

            single: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/products/attributes/' + id)
                    })
                }
            },

            create: {
                fn: async (bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/attributes/create', bodyQuery)
                    })
                }
            },

            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/products/attributes' + queryParams, bodyQuery)
                    })
                }
            },

            updateByKey: {
                fn: async (id, key, value) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/attributes/update/key/' + id + '/' + key, {
                            value: value
                        })
                    })
                }
            },


            importFromAttributeGroup: {
                fn: async (product_id, group_id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/import/attributes/group/' + product_id + "/" + group_id)
                    })
                },
            },
            importFromAttribute: {
                fn: async (product_id, attribute_id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/import/attributes/' + product_id + "/" + attribute_id)
                    })
                },
            },

            byName: {
                fn: async (name, label = '') => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/products/attributes/name/' + name + `?label=${label}`)
                    })
                }
            },

            getAttributesByNames: {
                fn: async (name, queryString = {}) => {

                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/products/attributes/names/' + name + queryParams)
                    })
                }
            },

            createValue: {
                fn: async (attribute_name, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/attributes/create/value/' + attribute_name, bodyQuery)
                    })
                }
            },
            updateLabel: {
                fn: async (attribute_name, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/attributes/update/value/' + attribute_name, bodyQuery)
                    })
                }
            },
            proxy: {
                index: {
                    fn: async (queryString = {}, bodyQuery = {}) => {
                        const queryParams = '?' + toUrlQuery(queryString);
                        return await wrapRequest(async () => {
                            CURRENT_REQUEST = 'get'
                            return await getHttp().post('/products/attributes/proxy' + queryParams, bodyQuery)
                        })
                    }
                },
                create: {
                    fn: async (bodyQuery = {}) => {
                        return await wrapRequest(async () => {
                            CURRENT_REQUEST = 'post'
                            return await getHttp().post('/products/attributes/proxy/create', bodyQuery)
                        })
                    }
                },
                update: {
                    fn: async (id, bodyQuery = {}) => {
                        return await wrapRequest(async () => {
                            CURRENT_REQUEST = 'post'
                            return await getHttp().post('/products/attributes/proxy/update/' + id, bodyQuery)
                        })
                    }
                },
                remove: {
                    fn: async (id, bodyQuery = {}) => {
                        return await wrapRequest(async () => {
                            CURRENT_REQUEST = 'post'
                            return await getHttp().delete('/products/attributes/proxy/delete/' + id, bodyQuery)
                        })
                    }
                },
            },
            groups: {

                index: {
                    fn: async (queryString = {}, bodyQuery = {}) => {
                        const queryParams = '?' + toUrlQuery(queryString);
                        return await wrapRequest(async () => {
                            CURRENT_REQUEST = 'get'
                            return await getHttp().post('/products/attributes/group' + queryParams, bodyQuery)
                        })
                    }
                },

                create: {
                    fn: async (bodyQuery = {}) => {

                        return await wrapRequest(async () => {
                            CURRENT_REQUEST = 'post'
                            return await getHttp().post('/products/attributes/group/create', bodyQuery)
                        })

                    }
                },

                update: {
                    fn: async (id, bodyQuery = {}) => {

                        return await wrapRequest(async () => {
                            CURRENT_REQUEST = 'post'
                            return await getHttp().post('/products/attributes/group/update/' + id, bodyQuery)
                        })

                    }
                },

                remove: {
                    fn: async (id, bodyQuery = {}) => {

                        return await wrapRequest(async () => {
                            CURRENT_REQUEST = 'post'
                            return await getHttp().delete('/products/attributes/group/delete/' + id, bodyQuery)
                        })

                    }
                },


                get: {
                    fn: async () => {

                        return await wrapRequest(async () => {
                            CURRENT_REQUEST = 'get'
                            return await getHttp().get('/products/attributes/group/get')
                        })

                    }
                },
            }
        },

        inventory: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {

                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        const queryParams = '?' + toUrlQuery(queryString);
                        return await getHttp().post('/products/inventory' + queryParams, bodyQuery)
                    })

                }
            },

            stats: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        DISABLE_LOADING = true
                        return await getHttp().post('/products/inventory/stats' + queryParams, bodyQuery)
                    })
                }
            },

            update: {
                fn: async (id, bodyQuery = {}) => {

                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/inventory/update/' + id, bodyQuery)
                    })

                }
            },
            updateProductCode: {
                fn: async (id, code) => {

                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/inventory/update/code/' + id + "/" + code)
                    })

                }
            },
            updateProductPrivate: {
                fn: async (id, privateProduct) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/inventory/update/private/' + id, privateProduct)
                    })
                }
            },
            updateOnSale: {
                fn: async (id, onSale) => {

                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/inventory/update/on-sale/' + id, onSale)
                    })

                }
            },
            updateShipping: {
                fn: async (id, bodyQuery = {}) => {

                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/products/inventory/update/shipping/' + id, bodyQuery)
                    })


                }
            },
        },
        priceHistory: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/products/price-history/' + id+'?model=\\App\\PriceManager\\Model\\PriceLineExpanded')
                })
            }
        },

        question: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/questions' + queryParams, bodyQuery)
                    })
                }
            },
            update: {
                fn: async (id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/questions/update/' + id, bodyQuery)
                    })
                }
            },
            delete: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().delete('/questions/delete/' + id)
                    })
                }
            },
        }

    },
    payment: {
        method: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/payments/methods' + queryParams, bodyQuery)
                    })
                }
            },
            single: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/payments/methods/' + id)
                    })
                }
            },
            update: {
                fn: async (id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/payments/methods/update/' + id, bodyQuery)
                    })
                }
            },
            activeStatus: {
                fn: async (id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/payments/methods/update/active/' + id, bodyQuery)
                    })
                }
            },
        },
        transactionsByCustomerAndType: {
            fn: async (customer_id, type) => {
                const re = await getHttp().get('/payments/transactions/' + customer_id + '/' + type)

                let response = await getResponse(re)

                if (response?.errors) {
                    throw new Error(errorToString(response?.errors))
                }

                return response
            }
        },
    },
    menu: {
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/appearances/menus' + queryParams, bodyQuery)
                })
            }
        },
        create: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/appearances/menus/create', bodyQuery)
                })
            }
        },
        update: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/appearances/menus/update/' + id, bodyQuery)
                })
            }
        },
        updateChildren: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/appearances/menus/update/children/' + id, bodyQuery)
                })
            }
        },
        delete: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().delete('/appearances/menus/delete/' + id)
                })
            }
        },
    },
    template: {
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/appearances/template' + queryParams, bodyQuery)
                })
            }
        },
        getBySlugs: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/appearances/template/get-by-slugs', bodyQuery)
                })
            }
        },
        single: {
            fn: async (id,asSlug = '') => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/appearances/template/' + id+`?as-slug=${asSlug}`)
                })
            }
        },
        create: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/appearances/template/create', bodyQuery)
                })
            }
        },
        deleteBySlug: {
            fn: async (slug) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().delete(`/appearances/template/delete/slug/${slug}`)
                })
            }
        },
        update: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/appearances/template/update/' + id, bodyQuery)
                })
            }
        },
    },
    priceList: {
        index: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/price-list', bodyQuery)
                })
            }
        },

        single: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/price-list/' + id)
                })
            }
        },

        create: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/price-list/create', bodyQuery)
                })
            }
        },

        update: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/price-list/update/' + id, bodyQuery)
                })
            }
        },
    },
    page: {
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/pages' + queryParams, bodyQuery)
                })
            }
        },

        single: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/pages/' + id)
                })
            }
        },

        create: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/pages/create', bodyQuery)
                })
            }
        },

        update: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/pages/update/' + id, bodyQuery)
                })
            }
        },
    },

    employee: {
        editLock: {
            sync: {
                fn: async (object_id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        DISABLE_LOADING = true
                        return await getHttp().post('/employees/edit-lock/sync/' + object_id)
                    })
                }
            },
            takeOver: {
                fn: async (object_id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        DISABLE_LOADING = true
                        return await getHttp().post('/employees/edit-lock/take-over/' + object_id)
                    })
                }
            },
        },
        role: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/employees/roles' + queryParams, bodyQuery)
                    })
                }
            },
            single: {
                fn: async (id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().get('/employees/roles/' + id)
                    })
                }
            },
            update: {
                fn: async (id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/employees/roles/update/' + id, bodyQuery)
                    })
                }
            },
        },

        permission: {
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/employees/permissions' + queryParams, bodyQuery)
                    })
                }
            },
            getGroupByTitle: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/employees/permissions/group-by-title' + queryParams, bodyQuery)
                    })
                }
            },
        },

        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/employees' + queryParams, bodyQuery)
                })
            }
        },

        single: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/employees/' + id)
                })
            }
        },

        create: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/employees/create', bodyQuery)
                })
            }
        },

        update: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/employees/update/' + id, bodyQuery)
                })
            }
        },
        delete: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().delete('/employees/delete/' + id)
                })
            }
        },
    },

    coupon: {
        allParent: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/coupons/all-parent' + queryParams, bodyQuery)
                })
            }
        },
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/coupons' + queryParams, bodyQuery)
                })
            }
        },

        single: {
            fn: async (id, queryString = '') => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/coupons/' + id + queryString)
                })
            }
        },

        create: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/coupons/create', bodyQuery)
                })
            }
        },

        updateParent: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/coupons/update/parent/' + id, bodyQuery)
                })
            }
        },

        update: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/coupons/update/' + id, bodyQuery)
                })
            }
        },

        clone: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/coupons/clone/' + id, bodyQuery)
                })
            }
        },
        updateEnabled: {
            fn: async (enabled = true, id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/coupons/' + (enabled ? 'enable' : 'disable') + '/' + id, bodyQuery)
                })
            }
        },
        quickCheck: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/coupons/quick-check', bodyQuery)
                })
            }
        },
        delete: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().delete('/coupons/delete/' + id)
                })
            }
        },
    },
    campaign: {
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/campaigns' + queryParams, bodyQuery)
                })
            }
        },

        single: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/campaigns/' + id)
                })
            }
        },

        create: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/campaigns/create', bodyQuery)
                })
            }
        },

        update: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/campaigns/update/' + id, bodyQuery)
                })
            }
        },

        updateEnabled: {
            fn: async (enabled = true, id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/campaigns/' + (enabled ? 'enable' : 'disable') + '/' + id, bodyQuery)
                })
            }
        },

        delete: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().delete('/campaigns/delete/' + id)
                })
            }
        },
    },
    order: {
        getTransactionHistory: {
            fn: async (order_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/orders/payment/transaction-history/' + order_id)
                })
            }
        },
        delete: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().delete('/orders/destroy/' + id)
                })
            }
        },
        getOrderProductsWithRewardCredit: {
            fn: async (order_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/orders/product-with-reward-credits/' + order_id)
                })
            }
        },
        sendQuickPaymentLink: {
            fn: async (order_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/orders/send-quick-payment-link/' + order_id)
                })
            }
        },
        deleteTransaction: {
            fn: async (order_id, transaction_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().delete('/orders/payment/transaction/delete/' + order_id + '/' + transaction_id)
                })
            }
        },
        sync:{
            fn: async (order_id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/orders/sync/' + order_id)
                })
            }
        },
        updateOperator:{
            fn: async (order_id, empid) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/orders/operator/update/' + order_id + '/' + empid)
                })
            }
        },
        note: {
            index: {
                fn: async (order_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/orders/note/' + order_id, bodyQuery)
                    })
                }
            },
        },

        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/orders' + queryParams, bodyQuery)
                })
            }
        },
        single: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/orders/' + id)
                })
            }
        },
        stats: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    DISABLE_LOADING = true
                    return await getHttp().post('/orders/stats' + queryParams, bodyQuery)
                })
            }
        },
        shippingStats: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    DISABLE_LOADING = true
                    return await getHttp().post('/orders/shipping/stats' + queryParams, bodyQuery)
                })
            }
        },
        shippingProducts: {
            fn: async (id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    DISABLE_LOADING = true
                    return await getHttp().get('/orders/shipping/products/' + id, bodyQuery)
                })
            }
        },
        updateStatus: {
            fn: async (order_id, status , bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/orders/status/update/' + order_id + '/' + status , bodyQuery)
                })
            }
        },
        convertAddress: {
            fn: async (order_id, address_id , bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/orders/address/convert/' + order_id + '/' + address_id , bodyQuery)
                })
            }
        },
        action: {
            fn: async (action, order_id, bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/orders/action/' + action + "/" + order_id, bodyQuery)
                })
            }
        },
        payment: {
            snapppayUpdate: {
                fn: async (order_id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/payment/snapp-pay/update/${order_id}`)
                    })
                }
            },
            snapppayStatus: {
                fn: async (order_id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/payment/snapp-pay/status/${order_id}`)
                    })
                }
            },
            snapppayCancel: {
                fn: async (order_id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/payment/snapp-pay/cancel/${order_id}`)
                    })
                }
            },  
            updateMethod: {
                fn: async (order_id, payment_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/payment/method/change/${order_id}/${payment_id}`, bodyQuery)
                    })
                }
            },
            addBankDeposit: {
                fn: async (order_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/payment/bank-deposit/add/${order_id}`, bodyQuery)
                    })
                }
            },
            addWalletWithdraw: {
                fn: async (order_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/payment/wallet/withdraw/${order_id}`, bodyQuery)
                    })
                }
            },
        },
        shipment: {
            updateDeliveryTime: {
                fn: async (order_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/shipment/update/delivery/periods/${order_id}`, bodyQuery)
                    })
                }
            },
            updateRentPrice: {
                fn: async (order_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/shipment/update/rent-price/${order_id}`, bodyQuery)
                    })
                }
            },
            updateAddress: {
                fn: async (order_id,address_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/address/change/${order_id}/${address_id}`, bodyQuery)
                    })
                }
            },
        },
        item: {
            addProduct: {
                fn: async (order_id, product_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/item/product/add/${order_id}/${product_id}`, bodyQuery)
                    })
                }
            },
            addCoupon: {
                fn: async (order_id, coupon_id) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/item/coupon/add/${order_id}/${coupon_id}`)
                    })
                }
            },
            addShipment: {
                fn: async (order_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/item/shipment/add/${order_id}`, bodyQuery)
                    })
                }
            },
            addPrivateProduct: {
                fn: async (order_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/item/private-product/add/${order_id}`, bodyQuery)
                    })
                }
            },
            deletePrivateProduct: {
                fn: async (order_id, item_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().delete(`/orders/item/private-product/delete/${order_id}/${item_id}`, bodyQuery)
                    })
                }
            },
            addAdditionalRent: {
                fn: async (order_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/item/additional-rent/add/${order_id}`, bodyQuery)
                    })
                }
            },
            deleteAdditionalRent: {
                fn: async (order_id, item_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/item/additional-rent/delete/${order_id}/${item_id}`, bodyQuery)
                    })
                }
            },
            deleteShipment: {
                fn: async (order_id, item_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/item/shipment/delete/${order_id}/${item_id}`, bodyQuery)
                    })
                }
            },
            deleteCoupon: {
                fn: async (order_id, item_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/item/coupon/delete/${order_id}/${item_id}`, bodyQuery)
                    })
                }
            },
            deleteProduct: {
                fn: async (order_id, item_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/item/product/delete/${order_id}/${item_id}`, bodyQuery)
                    })
                }
            },
            deleteDirectDiscount: {
                fn: async (order_id, item_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/item/direct-discount/delete/${order_id}/${item_id}`, bodyQuery)
                    })
                }
            },
            directDiscount: {
                fn: async (order_id, item_id, bodyQuery = {}) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post(`/orders/item/direct-discount/create/${order_id}/${item_id}`, bodyQuery)
                    })
                }
            },
        },
        create: {
            fn: async (bodyQuery = {}) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/orders/create', bodyQuery)
                })
            }
        },
        getMessageTemplate:{
            fn: async (order_id , status) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    DISABLE_LOADING = true
                    return await getHttp().get(`/orders/status/message-template/${order_id}/${status}`)
                })
            }
        }
    },
    storage: {
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/storages' + queryParams, bodyQuery)
                })
            }
        },
        single: {
            fn: async (id) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().get('/storages/' + id)
                })
            }
        },
        create: {
            fn: async (body) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/storages/create', body)
                })
            }
        },
        update: {
            fn: async (id, body) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/storages/update/' + id, body)
                })
            }
        },
    },
    setting: {
        index: {
            fn: async (queryString = {}, bodyQuery = {}) => {
                const queryParams = '?' + toUrlQuery(queryString);
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'get'
                    return await getHttp().post('/settings' + queryParams, bodyQuery)
                })
            }
        },
        create: {
            fn: async (body) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/settings/create', body)
                })
            }
        },
        update: {
            fn: async (id, body) => {
                return await wrapRequest(async () => {
                    CURRENT_REQUEST = 'post'
                    return await getHttp().post('/settings/update/' + id, body)
                })
            }
        },
    },
    integrate: {
        snappShop:{
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/party/snapp-shop' + queryParams, bodyQuery)
                    })
                }
            },
            create: {
                fn: async (body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/party/snapp-shop/create', body)
                    })
                }
            },
            update: {
                fn: async (id, body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/party/snapp-shop/update/' + id, body)
                    })
                }
            },
            delete: {
                fn: async (id, body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().delete('/party/snapp-shop/delete/' + id, body)
                    })
                }
            },
        },
        dgShahrShop:{
            index: {
                fn: async (queryString = {}, bodyQuery = {}) => {
                    const queryParams = '?' + toUrlQuery(queryString);
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'get'
                        return await getHttp().post('/party/dgshahr-shop' + queryParams, bodyQuery)
                    })
                }
            },
            create: {
                fn: async (body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/party/dgshahr-shop/create', body)
                    })
                }
            },
            update: {
                fn: async (id, body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().post('/party/dgshahr-shop/update/' + id, body)
                    })
                }
            },
            delete: {
                fn: async (id, body) => {
                    return await wrapRequest(async () => {
                        CURRENT_REQUEST = 'post'
                        return await getHttp().delete('/party/dgshahr-shop/delete/' + id, body)
                    })
                }
            },
        }
    },
}

async function wrapRequest(func, thenCallback = null) {

    if (DISABLE_LOADING === false) {
        //toast.loading("", {toastId: "loading1", position: "bottom-left", theme: "dark"})
        startLoader()
    }

    let toastId = "loading1"

    let res
    let errors


    if (!isDev()) {
        const x = await func().catch(async (error) => {
            if (error.response) {

                errors = error.response.data.errors

            }

        })
        res = await x?.data ?? null
    } else {
        const x = await func()
        res = await x.json()
        errors = await res?.errors
    }

    stopLoader()

    if (errors) {

        let textErrors = errorToString(errors)
        for (const textError of textErrors.split('|')) {
            toast.error(textError)
        }
    } else {
        if (CURRENT_REQUEST == 'post') {
            if (res?.errors?.length > 0) {
                toast.success(res.errors);
            }else{
                if(res?.status_code){
                    toast.success("با موفقیت انجام شد");
                }else{
                    toast.warning("خطای غیر منتظره، لطفا مجددا تلاش کنید");
                }
                
            }
            if (thenCallback !== null) {
                return thenCallback(res)
            }
        }
    }

/*    setTimeout(() => {
        toast.done(toastId);
    }, 1000)*/
    DISABLE_LOADING = false
    return res
}

function beforeRequest(isGet = false) {
    try {
        toast.loading("", {toastId: "loading1", position: "bottom-left", theme: "dark"})
    } catch (e) {

    }
}

function errorToString(err) {
    //let errMsg = err.response.data.errors
    let errMsg = err
    if (typeof errMsg === 'string') {
        return errMsg
    }
    console.log(errMsg)
    let errors = []

    if (typeof errMsg === 'object') {

        console.log(errMsg)

        try {
            for (let _key in Object.keys(errMsg)) {
                errors.push(((errMsg[_key]).join(', ')))
            }
        } catch (e) {
            for (let _key in errMsg) {
                errors.push(((errMsg[_key]).join(', ')))
            }
        }

        return errors.join(' | ')
    }

    for (let i = 0; i < Object.keys(errMsg).length; i++) {

        if (typeof errMsg[Object.keys(errMsg)[i]] == 'string') {
            errors.push(errMsg[Object.keys(errMsg)[i]])
        } else {
            for (const _err of errMsg[Object.keys(errMsg)[i]]) {
                errors.push(_err)
            }
        }

    }
    return errors.join(' | ')
}

function toUrlQuery(obj) {
    var str = [];
    for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
    return str.join("&");
}

function isDev() {
    return false;
    return process.env.NODE_ENV === 'development';
}


export default ApiRoute