import ImageUploader from "../../Utilities/ImageUploader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faChevronDown, faChevronUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import FileUpload from "../../FileUpload";
import ComponentTitle from "./Common/ComponentTitle";
import { uploadUrl } from "../../../helper/api";
import {Editor} from "@tinymce/tinymce-react";

export default function ContentBox({component , remove , up, down , refresh = null}) {
    const [updateState, setUpdateState] = useState(1)
    const [items, setItems] = useState(component.data)
    const [open, setOpen] = useState(false)

    function sync() {
        setUpdateState(Date.now())
    }


    return (
        <div className="flex flex-auto my-3">

            <div className="w-full border border-gray-7 rounded p-1 grid gap-2">

                <ComponentTitle component={component} color={`#00ffb2`} title={"بلوک محتوا"} open={open} setOpen={ setOpen } remove={remove} up={up} down={down}/>

                {open && <div>
                    
                    <Editor
                        tinymceScriptSrc='/tinymce/tinymce.min.js'
                        initialValue={component?.data?.content ?? ''}
                        onEditorChange={(content) => {
                            component.data = component.data || {}
                            component.data.content = content
                        }}
                        
                        init={{
                            selector: 'textarea#open-source-plugins',
                            plugins: 'directionality link table lists code fullscreen preview textcolor',
                            menubar: 'edit view insert format',
                            toolbar: [
                                'undo redo | formatselect | fontsize | forecolor backcolor |',
                                'bold italic | align numlist bullist | link | table |',
                                'code fullscreen preview | rtl ltr | bootstrapButton'
                            ],
                            setup: function (editor) {
                                editor.ui.registry.addButton('bootstrapButton', {
                                    text: 'دکمه بوت‌استرپ',
                                    onAction: function () {
                                        editor.windowManager.open({
                                            title: 'تنظیمات دکمه بوت‌استرپ',
                                            body: {
                                                type: 'panel',
                                                items: [
                                                    {
                                                        type: 'input',
                                                        name: 'buttonText',
                                                        label: 'متن دکمه'
                                                    },
                                                    {
                                                        type: 'input',
                                                        name: 'buttonLink',
                                                        label: 'لینک دکمه'
                                                    },
                                                    {
                                                        type: 'colorinput',
                                                        name: 'textColor',
                                                        label: 'رنگ متن'
                                                    },
                                                    {
                                                        type: 'colorinput',
                                                        name: 'bgColor',
                                                        label: 'رنگ بکگراند'
                                                    },
                                                    {
                                                        type: 'selectbox',
                                                        name: 'fontSize',
                                                        label: 'سایز متن',
                                                        items: [
                                                            { text: '13px', value: '13px' },
                                                            { text: '14px', value: '14px' },
                                                            { text: '16px', value: '16px' },
                                                            { text: '18px', value: '18px' },
                                                            { text: '20px', value: '20px' }
                                                        ]
                                                    },
                                                    {
                                                        type: 'selectbox',
                                                        name: 'buttonSize',
                                                        label: 'سایز دکمه',
                                                        items: [
                                                            { text: 'بزرگ', value: 'btn-lg' },
                                                            { text: 'متوسط', value: 'btn-md' },
                                                            { text: 'کوچک', value: 'btn-sm' },
                                                            { text: 'تمام صفحه', value: 'btn-block' }
                                                        ]
                                                    }
                                                ]
                                            },
                                            buttons: [
                                                {
                                                    type: 'submit',
                                                    text: 'ایجاد'
                                                }
                                            ],
                                            onSubmit: function (api) {
                                                const data = api.getData();
                                                editor.insertContent(`<a href="${data.buttonLink}" class="btn ${data.buttonSize}" style="color: ${data.textColor}; background-color: ${data.bgColor}; font-size: ${data.fontSize};">${data.buttonText}</a>`);
                                                api.close();
                                            }
                                        });
                                    }
                                });
                            },
                            formats: {
                                h1: { block: 'h1' },
                                h2: { block: 'h2' },
                                h3: { block: 'h3' },
                                h4: { block: 'h4' },
                                h5: { block: 'h5' },
                                h6: { block: 'h6' }
                            },
                            fontsize_formats: '13px 14px 16px 18px 20px 24px 28px 32px 36px 48px 64px',
                            rtl_ui: true,
                            directionality: "rtl",
                            height: 400,
                            skin: 'oxide',
                            content_style: `
                                @import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;300;400;500;600;700;800;900&display=swap');
                                body { font-family:"Vazirmatn",Arial,sans-serif; font-size:16px; text-align:right; direction:rtl; }
                                .btn { display: inline-block; font-weight: 400; text-align: center; white-space: nowrap; vertical-align: middle; user-select: none; border: 1px solid transparent; padding: 0.375rem 0.75rem; font-size: 1rem; line-height: 1.5; border-radius: 0.25rem; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
                                .btn-lg { padding: 0.5rem 1rem; font-size: 1.25rem; line-height: 1.5; border-radius: 0.3rem; }
                                .btn-md { padding: 0.375rem 0.75rem; font-size: 1rem; line-height: 1.5; border-radius: 0.25rem; }
                                .btn-sm { padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; border-radius: 0.2rem; }
                                .btn-block { display: block; width: 100%; }
                            `
                        }}
                    />
                    

                    <select onChange={ (e) => {
                        component.layout = e.target.value
                        sync()
                    } } className={`input-x`}>
                        <option selected={component.layout == 'col-1'} value={`col-1`}>یک از دوازده</option>
                        <option selected={component.layout == 'col-2'} value={`col-2`}>دو از دوازده</option>
                        <option selected={component.layout == 'col-3'} value={`col-3`}>سه از دوازده</option>
                        <option selected={component.layout == 'col-4'} value={`col-4`}>چهار از دوازده</option>
                        <option selected={component.layout == 'col-5'} value={`col-5`}>پنج از دوازده</option>
                        <option selected={component.layout == 'col-6'} value={`col-6`}>نصف صفحه</option>
                        <option selected={component.layout == 'col-7'} value={`col-7`}>هفت از دوازده</option>
                        <option selected={component.layout == 'col-8'} value={`col-8`}>هشت از دوازده</option>
                        <option selected={component.layout == 'col-9'} value={`col-9`}>نه از دوازده</option>
                        <option selected={component.layout == 'col-10'} value={`col-10`}>ده از دوازده</option>
                        <option selected={component.layout == 'col-11'} value={`col-11`}>یازده از دوازده</option>
                        <option selected={component.layout == 'col-12'} value={`col-12`}>تمام صفحه</option>
                    </select>

                    <div className={`text-[13px] mt-3`}>صفحه بندی موبایل</div>
                    <select onChange={ (e) => {
                        component.layout_sm = e.target.value
                        sync()
                    } } className={`input-x`}>
                        <option selected={component.layout_sm == 'col-1'} value={`col-1`}>یک از دوازده</option>
                        <option selected={component.layout_sm == 'col-2'} value={`col-2`}>دو از دوازده</option>
                        <option selected={component.layout_sm == 'col-3'} value={`col-3`}>سه از دوازده</option>
                        <option selected={component.layout_sm == 'col-4'} value={`col-4`}>چهار از دوازده</option>
                        <option selected={component.layout_sm == 'col-5'} value={`col-5`}>پنج از دوازده</option>
                        <option selected={component.layout_sm == 'col-6'} value={`col-6`}>نصف صفحه</option>
                        <option selected={component.layout_sm == 'col-7'} value={`col-7`}>هفت از دوازده</option>
                        <option selected={component.layout_sm == 'col-8'} value={`col-8`}>هشت از دوازده</option>
                        <option selected={component.layout_sm == 'col-9'} value={`col-9`}>نه از دوازده</option>
                        <option selected={component.layout_sm == 'col-10'} value={`col-10`}>ده از دوازده</option>
                        <option selected={component.layout_sm == 'col-11'} value={`col-11`}>یازده از دوازده</option>
                        <option selected={component.layout_sm == 'col-12'} value={`col-12`}>تمام صفحه</option>
                    </select>

                    <div className={`flex text-13 gap-3 items-center`}>
                        <span>رنگ بکگراند</span>
                        <input onChange={ e => {
                            component.attributes = component.attributes || {}
                            component.attributes.bg = e.target.value
                        } } type={`color`} defaultValue={component.attributes.bg}/>
                    </div>

                    <div className={`flex text-13 gap-3 items-center mt-2`}>
                        <span>فاصله درونی</span>
                        <input placeholder="عدد وارد کنید" className="border rounded p-1" onChange={ e => {
                            component.attributes = component.attributes || {}
                            component.attributes.padding = e.target.value
                        } } type={`number`} defaultValue={component?.attributes?.padding}/>
                    </div>

                </div>}

            </div>
        </div>
    )
}