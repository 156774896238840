import React, {useEffect, useState} from "react";
import {DatePicker} from "zaman";
import moment from "jalali-moment";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Modal from "../Utilities/Modal";
import ApiRoute from "../../helper/api";
import { faNumberToEn } from "../../helper/general";
import PriceField from "../Common/PriceField";

export default function CustomerWalletChargeModal({customer, setUpdateState , closeModal}){

    const [isLoading, setIsLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [shop, setShop] = useState(null)
    const [data , setData] = useState({
        payment_method : 'debit_card',
        bank_account : '',
        reference_id : '',
        date : '',
        time : '',
        amount : '',
        description : '',
    })

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const res = await ApiRoute.shop.single.fn(customer.shop_id)
            setShop(res.results)
            setIsLoading(false)
        })()
    },[])

    async function handleSubmit(){
        setSending(true)
        const res = await ApiRoute.customer.depositWallet.fn(customer._id, data)
        if (res?.status_code === 200){
            setUpdateState(Date.now())
            closeModal()
        }
        setSending(false)
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div className="page-title mb-5"><span
                className="inline-block bg-white pl-3 text-sm text-purple-1">شارژ کیف پول</span>
            </div>
            <div className="form">
                <div className="grid grid-cols-2 lg:sgrid-cols-3 justify-items-stretch mb-5 gap-4">
{/*                     <label className="!grid col-span-full">
                        <span className="text-right mb-3 pr-1">روش پرداخت</span>
                        <select onChange={ (e) => {
                            data.payment_method = e.target.value;
                        } } className="!mr-0">
                            <option value="debit_card">واریز وجه کامل</option>
                            <option value="prepayment">واریز پیش پرداخت</option>
                        </select>
                    </label> */}
                    <label className="!grid">
                        <span className="text-right mb-3 pr-1">انتخاب شماره حساب</span>
                        <select onChange={ (e) => {
                            shop.bank_account_numbers.map( (item) => {
                                if (item.id === e.target.value){
                                    data.bank_account = item
                                }
                            } )

                        } } className="!mr-0">
                            <option value="">{isLoading ? 'در حال بارگذاری...' : 'انتخاب کنید'}</option>
                            {shop?.bank_account_numbers && shop.bank_account_numbers.map( (item) => <option value={item.id}>{item.title}</option> )}
                        </select>
                    </label>
                    <label className="!grid">
                        <span className="text-right mb-3 pr-1">شماره پیگیری</span>
                        <input onKeyUp={ e => {
                            data.reference_id = e.target.value
                        } } type="text" className="!mr-0 w-full"/>
                    </label>
                    <div className="!grid">
                        <span className="text-right text-13 mb-3 pr-1">تاریخ واریز</span>
                        <DatePicker
                            className={'z-[11111111]'}
                            onChange={(e) => {
                                data.date = faNumberToEn(moment(e.value,'Y-m-d').format('YYYY-MM-DD'));
                            } }
                        />
                    </div>
                    <div className="!grid">
                        <span className="text-right text-13 mb-3 pr-1">ساعت واریز</span>
                        <div className="flex gap-2">
                            <select 
                                className="!mr-0 w-1/2"
                                onChange={(e) => {
                                    const minute = e.target.value;
                                    const currentHour = data.time?.split(':')[0] || '00';
                                    data.time = `${currentHour}:${minute}`;
                                }}
                            >
                                <option value="">دقیقه</option>
                                {[...Array(60)].map((_, i) => (
                                    <option key={i} value={i.toString().padStart(2, '0')}>{i.toString().padStart(2, '0')}</option>
                                ))}
                            </select>
                            <select 
                                className="!mr-0 w-1/2"
                                onChange={(e) => {
                                    const hour = e.target.value.padStart(2, '0');
                                    const currentMinute = data.time?.split(':')[1] || '00';
                                    data.time = `${hour}:${currentMinute}`;
                                }}
                            >
                                <option value="">ساعت</option>
                                {[...Array(24)].map((_, i) => (
                                    <option key={i} value={i}>{i.toString().padStart(2, '0')}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    
                    <label className="!grid col-span-full">
                                    <span className="flex items-center mb-3">
                                    <span className="text-right pr-1">مبلغ</span>
                                    </span>
                        <PriceField className={`!mr-0`} onChange={ (v) => {
                            data.amount = parseInt(v)
                        } }/>
                    </label>
                </div>
                <div className="grid grid-cols-4 items-end gap-4">
                    <label className="col-span-3 !grid">
                        <span className="text-right mb-3 pr-1">شرح واریز</span>
                        <input onKeyUp={ e => {
                            data.description = e.target.value
                        } } type="text" className="!mr-0"/>
                    </label>
                    <div className="col-span-1">
                        <button disabled={sending} onClick={handleSubmit} className="btn-purple py-2 w-full">{sending ? '...' : 'افزودن'}</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}