import SwitchToggle from "../Utilities/SwitchToggle";
import {useEffect, useState} from "react";
import CategorySelect from "../Product/Category/CategorySelect";
import BrandSelect from "../Product/Category/BrandSelect";

export default function FilterInventory({SetFilterQuery, filterQuery}){

    const [query,setQuery] = useState({});
    const [filter,setFilter] = useState({});
    const [updateData, setUpdateData] = useState('0')
    const [applyFilter, setApplyFilter] = useState(false)

    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);

    useEffect(() => {
        let arr = []
        selectedCategory.map(category => arr.push(category.slug) )
        changeQuery(arr,"category")
    }, [selectedCategory]);

    useEffect(() => {
        let arr = []
        selectedBrand.map(brand => arr.push(brand.value) )
        changeQuery(arr,"brand")
    }, [selectedBrand]);

    useEffect(() => {
        setFilter(query)
    }, [query]);

    useEffect(() => {
        SetFilterQuery(filter)
    }, [updateData]);

    const handleMultiSelectCategory = (_selectedCategory) => {
        setSelectedCategory(_selectedCategory)
    }

    const handleMultiSelectBrand = (selectedBrand) => {
        setSelectedBrand(selectedBrand)
    }

    function changeQuery(value , name){
        query[name] = value;
        setQuery(query)
    }

    return (
        <div className={`show`}>
            <div className="pt-3 pb-4">
                <div className="main-content p-3">
                    <div className="grid-form text-xs grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-9 gap-3 2xl:gap-4 2xl:gap-y-5">

                        <div>
                            <label>
                                <span className="block">ویژه ها</span>
                                <select
                                    onChange={ (e) => {
                                        changeQuery(e.target.value,"price_sale")
                                    } }
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">ویژه ها</option>
                                    <option value="n">غیر ویژه ها</option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">تاریخ / غیر تاریخ</span>
                                <select
                                    onChange={ (e) => {
                                        changeQuery(e.target.value,"start_and_end_date")
                                    } }
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">تاریخ / غیر تاریخ</option>
                                    <option value="none">تاریخ ندارند</option>
                                    <option value="end">شروع دارند</option>
                                    <option value="start">پایان دارند</option>
                                    <option value="both">پایان و شروع دارد</option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">شناسه محصول</span>
                                <select
                                    onChange={ (e) => {
                                        changeQuery(e.target.value,"product_id")
                                    } }
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">دارد ندارد</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">وضعیت قیمت گذاری</span>
                                <select
                                    onChange={ (e) => {
                                        changeQuery(e.target.value,"price_min_max_status")
                                    } }
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="min">رد شدن از مرز حداقل</option>
                                    <option value="max">رد شدن از مرز حداکثر</option>
                                    <option value="floor">رد شدن از کف قیمت</option>
                                    <option value="ceil">رد شدن از سقف قیمت</option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">وضعیت قیمت گذاری فوری</span>
                                <select
                                    onChange={ (e) => {
                                        changeQuery(e.target.value,"price_sale_min_max_status")
                                    } }
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="min">رد شدن از مرز حداقل</option>
                                    <option value="max">رد شدن از مرز حداکثر</option>
                                    <option value="floor">رد شدن از کف قیمت</option>
                                    <option value="ceil">رد شدن از سقف قیمت</option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">وضعیت قیمت گذاری ویژه عادی</span>
                                <select
                                    onChange={ (e) => {
                                        changeQuery(e.target.value,"price_regular_min_max_status")
                                    } }
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="min">رد شدن از مرز حداقل</option>
                                    <option value="max">رد شدن از مرز حداکثر</option>
                                    <option value="floor">رد شدن از کف قیمت</option>
                                    <option value="ceil">رد شدن از سقف قیمت</option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">وضعیت</span>
                                <select
                                    onChange={ (e) => {
                                        changeQuery(e.target.value,"status")
                                    } }
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="draft">پیشنویس</option>
                                    <option value="publish">منتشر شده</option>
                                    <option value="private">خصوصی</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">کد آسان</span>
                                <select
                                    onChange={ (e) => {
                                        changeQuery(e.target.value,"has_asan_id")
                                    } }
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>

                        {/*stocks*/}
                        <div>
                            <label>
                                <span className="block">موجودی آسان</span>
                                <select onChange={ (e) => {
                                    changeQuery(e.target.value,"stock_asan")
                                } } className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">موجودی کوروش</span>
                                <select onChange={ (e) => {
                                    changeQuery(e.target.value,"stock_kourosh")
                                } } className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">موجودی ایران</span>
                                <select onChange={ (e) => {
                                    changeQuery(e.target.value,"stock_iran")
                                } } className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">موجودی فرانچایز</span>
                                <select onChange={ (e) => {
                                    changeQuery(e.target.value,"stock_franchise")
                                } } className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">موجودی شرکت</span>
                                <select onChange={ (e) => {
                                    changeQuery(e.target.value,"stock_company")
                                } } className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">موجودی پ.سفارش</span>
                                <select onChange={ (e) => {
                                    changeQuery(e.target.value,"stock_presell")
                                } } className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">دارد</option>
                                    <option value="n">ندارد</option>
                                </select>
                            </label>
                        </div>
                        {/*stocks*/}

                        <div>
                            <label>
                                <span className="block">پیش سفارش</span>
                                <select
                                    onChange={ (e) => {
                                        changeQuery(e.target.value,"has_presell")
                                    } }
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="y">بله</option>
                                    <option value="n">خیر</option>
                                </select>
                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">ارسال رایگان</span>
                                <select
                                    onChange={ (e) => {
                                        changeQuery(e.target.value,"has_free_shipping_class")
                                    } }
                                    className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="tehran_and_alborz">تهران و البرز</option>
                                    <option value="province">شهرستان ها</option>
                                    <option value="both">هر دو</option>
                                    <option value="none">هیچکدام</option>
                                </select>
                            </label>
                        </div>

                        {/*relations*/}
                        <div>
                            <label>
                                <span className="block">دسته بندی</span>
                                <CategorySelect
                                    assignItem={handleMultiSelectCategory}
                                    placeholder="انتخاب دسته"
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">برند</span>
                                <BrandSelect
                                    assignItem={handleMultiSelectBrand}
                                    placeholder="انتخاب برند"
                                />
                            </label>
                        </div>
                        {/*relations*/}


                        <div className="flex items-end">
                            <button
                                onClick={ e => {
                                    setApplyFilter(true)
                                    setUpdateData(Date.now().toString())
                                }}
                                className="btn-orange w-full py-2">اعمال فیلتر</button>

                            {applyFilter && (
                                <button
                                    onClick={() => {
                                        window.location.reload()
                                        setApplyFilter(false)
                                    }}
                                    className="btn-gray w-full py-2 mr-2"
                                >
                                    حذف فیلتر
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}