import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Toman from "../Utilities/Toman";
import {useEffect, useState} from "react";
import ApiRoute from "../../helper/api";
import Modal from "../Utilities/Modal";
import { toast } from 'react-toastify'
import CustomerWalletChargeModal from "./CustomerWalletChargeModal";
import CustomerWalletRefundModal from "./CustomerWalletRefundModal";
import { HasAccessComponent } from "../../helper/permissions";

function CustomerTransaction( {customer} ){
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})
    const [updateData, setUpdateData] = useState('0')
    const [chargeAmount, setChargeAmount] = useState('')
    const [isChargeModalOpen, setIsChargeModalOpen] = useState(false)
    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const res  = await ApiRoute.payment.transactionsByCustomerAndType.fn(customer._id , 'wallet_deposit,wallet_withdraw')
            setData(res.results)
            setIsLoading(false)
        })();
    },[updateData]);

    function TypeOfTransaction({type}){
        switch (type){
            case 'PaymentGatewayWalletShopCredit': return 'واریز فروشگاه/سایت (ادمین)'
            case 'PaymentGatewayWalletDeposit': return 'واریز سیستمی'
            case 'PaymentGatewayWalletWithdraw': return 'برداشت از کیف پول'
            case 'PaymentGatewayMellat': return 'درگاه ملت'
            case 'PaymentGatewaySadad': return 'درگاه سداد'
            case 'PaymentGatewayWalletRewardCredit': return 'اعتبار بازگشتی خرید محصول'
            case 'PaymentGatewayWalletOrderCanceled': return 'برگشت وجه واریزی سفارش'
            case 'PaymentGatewayWalletRefunded': return 'عودت وجه از کیف پول'
            default: return type
        }
    }

    function TransactionDesc({type , reference_id, transaction_id}){
        switch (type){
            case 'PaymentGatewayWalletShopCredit': return `افزایش موجودی کیف پول از طریق سایت به شماره تراکنش ${transaction_id}`
            case 'PaymentGatewayWalletDeposit': return `افزایش موجودی کیف پول به صورت سیستمی به شماره تراکنش ${transaction_id}`
            case 'PaymentGatewayWalletRewardCredit': return `اعتبار بازگشتی خرید محصول به شماره تراکنش ${transaction_id}`
            case 'PaymentGatewayWalletWithdraw': return `برداشت از کیف پول به شماره تراکنش ${transaction_id}`
            case 'PaymentGatewayWalletRefunded': return `عودت وجه به شماره تراکنش ${transaction_id}`
            default: return `افزایش موجودی کیف پول از طریق سایت به شماره تراکنش ${transaction_id}`
        }
    }
    return (
        <div className="main-content mx-auto p-5 mt-12">

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
            <div>

                <div className="page-title z-1 text-right text-sm">
                    <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">ایجاد لینک پرداخت مستقیم شارژ کیف پول</span>
                </div>

                <div className={`flex gap-2 w-full md:flex-row flex-col`}>

                    <input onKeyUp={ (e) => { setChargeAmount(e.target.value) } } className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="مبلغ شارژ"/>
                    <button onClick={ async () => {
                        if( chargeAmount < 10_000 ){
                            toast.error('مبلغ شارژ نمیتواند کمتر از 10000 تومان باشد')
                            return
                        }
                        setIsLoading(true)
                        const res = await ApiRoute.customer.createWalletChargePaymentLink.fn(customer._id, chargeAmount)
                        setIsLoading(false)
                    }} disabled={isLoading} className={`btn-green rounded`}>{isLoading ? '...' : 'ایجاد'}</button>

                </div>

                <p className="text-gray-3 text-xs mt-3">بعد از ایجاد موفقیت آمیز، لینک برای مشتری پیامک خواهد شد</p>

            </div>
            </Modal>

            {isChargeModalOpen && <CustomerWalletChargeModal customer={customer} setUpdateState={setUpdateData} 
                closeModal={() => setIsChargeModalOpen(false)}
            />}

            {isRefundModalOpen && <CustomerWalletRefundModal customer={customer} setUpdateState={setUpdateData} 
                closeModal={() => setIsRefundModalOpen(false)}
            />}

            <div className="flex w-full justify-between">
                <span className="text-purple-1">کیف پول</span>
                <div className="flex items-center gap-2">
                    <HasAccessComponent permission="customer wallet_refund">
                    <button className="btn-outline-purple py-1 px-8" onClick={() => {
                        setIsRefundModalOpen(true)
                    }}>عودت وجه از کیف پول</button>
                    </HasAccessComponent>
                    <HasAccessComponent permission="customer wallet_charge">
                    <button className="btn-outline-purple py-1 px-8" onClick={() => {
                        setIsChargeModalOpen(true)
                    }}>شارژ کیف پول</button>
                    </HasAccessComponent>
                    <HasAccessComponent permission="customer wallet_link_charge">
                    <button className="btn-outline-purple py-1 px-8" onClick={() => {
                        setIsModalOpen(true)
                    }}>ایجاد لینک پرداخت مستقیم شارژ کیف پول</button>
                    </HasAccessComponent>
                </div>
            </div>

            <div className="border border-gray-7 rounded-xl mt-3 p-3">
                <div className="grid gap-3 sm:flex w-full justify-between">
                    <span className="block text-purple-1 text-13">تراکنش های کیف پول مشتری</span>
                    <div className="flex items-center gap-3">
                    <span className="block bg-purple-5 text-white text-xs rounded px-2 pt-1 pb-0.5"> کیف پول اعتباری:{(customer.wallet_balance - customer.wallet_credit_balance).toLocaleString()} <Toman customClass={'fill-white d-inline-flex'}/></span>
                    <span className="block bg-blue-4 text-white text-xs rounded px-2 pt-1 pb-0.5"> کیف پول هدیه:{(customer.wallet_credit_balance).toLocaleString()} <Toman customClass={'fill-white d-inline-flex'}/></span>    
                    <span className="block bg-red-1 text-white text-xs rounded px-2 pt-1 pb-0.5"> جمع کل:{(customer.wallet_balance).toLocaleString()} <Toman customClass={'fill-white d-inline-flex'}/></span>    
                    </div>                    

                </div>

                {!isLoading ? (
                    <table className="table-auto w-full mx-auto mt-4 block md:table">
                        <tbody>
                        {data.data.map( (item,index) => (
                            <tr key={index} className={`text-xs before ${item.status === "paid" ? "checked" : ""}`}>
                                <td className="py-3 pr-5 text-right">
                                        {item.status === "paid" && <span className={`green-tag text-[11px] py-0 px-0 text-center`}>موفق</span>}
                                        {item.status != "paid" && <span className={`gray-tag text-[11px] py-0 px-0 text-center`}>ناموفق</span>}
                                </td>
                                <td className="py-3 pr-5 text-right">
                                    <span className="text-gray-2">نوع تراکنش:</span>
                                    <span className="text-gray-3 mr-1"><TypeOfTransaction type={item.payment_gateway}/></span>
                                </td>
                                <td className="py-3 pr-5 text-right">
                                    <div className="flex items-center">
                                        <span className="text-gray-2">مبلغ:</span>
                                        <span className="text-gray-3 flex items-center mr-1">{item.amount.toLocaleString()} <Toman customClass={'fill-gray-3 mr-0.5'}/></span>
                                    </div>
                                </td>
                                <td className="py-3 pr-5 text-right">
                                    <span className="text-gray-2">زمان تراکنش:</span>
                                    <span className="text-gray-3 mr-1">{new Intl.DateTimeFormat('fa-IR',{
                                        timeStyle: "medium",
                                        dateStyle: "medium",
                                    }).format((new Date(item.created_at)))}</span>
                                </td>
                                <td className="py-3 pr-5 text-right">
                                    <span className="text-gray-2">توضیحات:</span>
                                    <span className="text-gray-3 mr-1">
                                        {item?.metadata?.description ? item?.metadata?.description : <TransactionDesc type={item.payment_gateway} reference_id={item.reference_id} transaction_id={item.transaction_id}/>}
                                    </span>
                                </td>
                            </tr>
                        ) )}
                        </tbody>
                    </table>
                ) : ''}
        
            </div>
        </div>
    )
}
export default CustomerTransaction