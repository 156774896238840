import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faTrash,  faEdit
} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import {getDateAsReadableFormat} from "../../helper/general";
import ApiRoute from '../../helper/api';

function EmployeesTable(props) {
    const tableTitles = [
        'نام کارمند',
        'ایمیل',
        'گروه',
        'تاریخ ایجاد کارمند',
    ]
    
    // تابع حذف کارمند
    const handleDeleteEmployee = async (employeeId, employeeName) => {
        // نمایش کانفیرم برای تایید حذف
        const confirmDelete = window.confirm(`آیا از حذف کارمند "${employeeName}" اطمینان دارید؟`);
        
        if (confirmDelete) {
            // در اینجا منطق حذف از سرور را پیاده‌سازی کنید
            // به عنوان مثال: یک درخواست API به سرور برای حذف کارمند
            console.log(`درخواست حذف کارمند با آیدی ${employeeId}`);
            await ApiRoute.employee.delete.fn(employeeId)
            // اگر props.onDelete تعریف شده باشد، آن را فراخوانی می‌کنیم
            if (props.onDelete) {
                props.onDelete(employeeId);
            } else {
                alert('عملیات حذف با موفقیت انجام شد');
            }
        }
    }
    
    return (
        <table className="coupon-table table-auto w-full mt-4 block lg:table">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
                <td></td>
            </tr>
            </thead>
            <tbody>
            {props.tableData.map((tableData, id) => (
                        <tr className={`text-xs py-2`} key={id}>
                            <td>{tableData.name}</td>
                            <td>{tableData.username}</td>
                            <td>{tableData.role.label ?? 'ندارد'}</td>
                            <td>{getDateAsReadableFormat(tableData.created_at)}</td>
                            <td className="flex justify-center items-center py-3">
                                <Link className="mx-2" to={`/employees/edit?id=${tableData._id}`} title="ویرایش"><FontAwesomeIcon
                                    className="bg-purple-3 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></Link>
                                <button className="mx-2" type={`button`} onClick={() => {
                                    handleDeleteEmployee(tableData._id, tableData.name);
                                }} title="حذف کارمند"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                            </td>

                        </tr>
                )
            )}
            </tbody>
        </table>
    )
}

export default EmployeesTable