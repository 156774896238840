import Toman from "../../Utilities/Toman";
import Modal from "../../Utilities/Modal";
import {useState} from "react";
import ApiRoute, {shopId} from "../../../helper/api";
import {formatNumberWithDots, getDateAsReadableFormat} from "../../../helper/general";
import ProductSelect from "../../Product/ProductSelect";
import SelectCoordinates from "../../Coordinates/SelectCoordinates";
import PeriodTime from "../../PeriodTime";
import {toast} from "react-toastify";
import { Link } from "react-router-dom";

export default function SearchTextInPageAndProductsContent({closeModal}){
    const [product , setProduct] = useState(null)
    const [coordinate , setCoordinate] = useState(null)
    const [data , setData] = useState(null)
    const [searchText, setSearchText] = useState("")
    const [searchType, setSearchType] = useState("like")

    async function handleSubmit(){
        if (!searchText.trim()) {
            toast.error('متن جستجو را وارد کنید')
            return;
        }
        
        const res = await ApiRoute.search.content.fn(searchText,searchType);

        
        if (res?.status_code === 200){
            setData(res.results)
        }
    }

    return (
        <Modal isOpen={true} onClose={closeModal} modalWidth="w-full xl:w-1/2">
            <div className="page-title mb-3"><span className="inline-block bg-white text-xs text-purple-1 pl-3">جستجوی متن در محتوای صفحه و کالاها</span>
            </div>

            <div className="form grid-form grid grid-cols-1 text-xs gap-3 my-6">
                <div>
                    <label>متن جستجو</label>
                    <input 
                        type="text" 
                        className="input-x w-full" 
                        placeholder="متن مورد نظر را وارد کنید"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
                <div>
                    <label>شیوه جستجو</label>
                    <select 
                        className="input-x w-full"
                        value={searchType}
                        onChange={(e) => setSearchType(e.target.value)}
                    >
                        <option value="like">جستجوی مشابه</option>
                        <option value="exact">جستجوی دقیق</option>
                    </select>
                </div>

            </div>

            <div className="flex justify-end mt-4">
                <button onClick={handleSubmit} className="btn-purple px-5 py-2">جستجو</button>
            </div>

            {data && (data.product.length > 0 || data.page.length > 0) ? (
                <div className="mt-6">
                    <div className="max-h-[400px] overflow-y-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div>
                                <div className="text-sm font-medium mb-3">محصولات:</div>
                                {data.product.length > 0 ? (
                                    data.product.map((item, index) => (
                                        <div key={`product-${index}`} className="bg-gray-50 p-3 mb-2 rounded-lg text-sm">
                                            <Link className="!text-[#09cf]" to={`products/edit?product_id=${item._id}`} target="_blank">{item.title}</Link>
                                        </div>

                                    ))
                                ) : (
                                    <div className="text-gray-500 text-sm">محصولی یافت نشد</div>
                                )}
                            </div>
                            
                            <div>
                                <div className="text-sm font-medium mb-3">صفحات:</div>
                                {data.page.length > 0 ? (
                                    data.page.map((item, index) => (
                                        <div key={`page-${index}`} className="bg-gray-50 p-3 mb-2 rounded-lg text-sm">
                                            <Link className="!text-[#09cf]" to={`pages/edit?id=${item._id}`} target="_blank">{item.title}</Link>
                                        </div>

                                    ))
                                ) : (
                                    <div className="text-gray-500 text-sm">صفحه‌ای یافت نشد</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : data && data.product.length === 0 && data.page.length === 0 ? (
                <div className="text-center text-gray-500 mt-6">
                    نتیجه‌ای یافت نشد
                </div>
            ) : null}
        </Modal>

    )
}