import Layout from "../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDown, faArrowUp,
    faBarsStaggered,
    faCheckDouble,
    faClose,
    faEdit,
    faSortDown,
    faSpinner,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import SearchBox from "../../components/SearchBox";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import {useNavigate} from "react-router-dom";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {library} from "@fortawesome/fontawesome";
import { v4 as uuidv4 } from 'uuid'
function Menus(){

    const [vv, setVV] = useState(1)
    const [editSelected, setEditSelected] = useState([])
    const [selected, setSelected] = useState(null)
    const [openChildren, setOpenChildren] = useState(null)
    const [editChild, setEditChild] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const [newMenu, setNewMenu] = useState({
        title: "",
        url: "",
        is_megamenu: "0",
        location: "main_navigation",
    })
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [title, setTitle] = useState('')
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState([])
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'cellphone'
    })
    const [filterQuery, setFilterQuery] = useState({})

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.menu.index.fn({
                shop_id: shopId(),
                quick_search : searchQuery,
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);
    // watch filterQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    async function createMenu(){
        await ApiRoute.menu.create.fn(newMenu)
        setUpdateData(Date.now().toString())
    }

    function changeSelectedItem(value , name , tableData){
        if (!editSelected[tableData._id]){
            editSelected[tableData._id] = [];
        }
        editSelected[tableData._id][name] = value;
        setEditSelected(editSelected)

    }

    async function editHandle(item){

        const x = editSelected[item._id]
        const myObjectFromSpread = { ...x };
        await ApiRoute.menu.update.fn(item._id , myObjectFromSpread)
        setUpdateData(Date.now().toString())

    }

    function getLastOrder(childs){
        let maxOrder = 0
        childs.sort((firstItem, secondItem) => firstItem.menu_order - secondItem.menu_order).forEach(item => {
            maxOrder = item.menu_order
        })
        return maxOrder + 1
    }

    function Xxx({shit,link,index,secIndex,mainIndex,exlink,addable}){
        return <div className={`flex hover:bg-[#bddeb97a] flex-col mr-4 `} key={link.id}>

            <div data-x={mainIndex} className={`flex px-2 items-center gap-1 justify-between`}>
                <input
                    onKeyUp={ e => {
                        link.title = e.target.value;
                    } }
                    className={`input-x`}
                    defaultValue={link.title}/>
                <input
                    onKeyUp={ e => {
                        link.url = e.target.value;
                    } }
                    className={`input-x text-left`} dir={`ltr`} defaultValue={link.url}/>

                {shit?.links?.length > 0 && <>
                    <FontAwesomeIcon onClick={() => {
                        moveData(selected,shit.links,index,'down')
                    }} className="bg-red-2 text-white p-1 w-4 h-4 rounded" icon={faArrowDown}/>
                    <FontAwesomeIcon onClick={() => {
                        moveData(selected,shit.links,index,'up')
                    }} className="bg-red-2 text-white p-1 w-4 h-4 rounded" icon={faArrowUp}/>
                </>}

                <FontAwesomeIcon onClick={() => {
                    try {
                        link.links.push({
                            title:"عنوان تست منو",
                            menu_order:getLastOrder(shit.links),
                            url:'#',
                            id:uuidv4(),
                            links:[]
                        })
                        setVV(Date.now())
                    }catch (e) {

                    }
                }} className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faPlus}/>


                <FontAwesomeIcon onClick={ () => {

                    if(link == exlink) {
                        selected.children.map((c,ci) => {
                            c.links.map((lc,lci) => {
                                if (link == lc){
                                    c.links.splice(lci, 1);
                                }
                            })
                        })
                    }
                    exlink.links?.map((l, i) => {
                        try {
                            if (l == link){
                                exlink.links.splice(i, 1);
                            }
                        }catch (e) {
                            console.log(e)
                        }
                    } )
                    //setSelected(selected)
                    setVV(Date.now())

                } } className="bg-red-1 text-gray-2 p-1 w-4 h-4 rounded" icon={faClose}/>

            </div>

            {
                link.links?.length > 0 && link.links.map((xlink,xindex) => <>
                    <Xxx mainIndex={mainIndex} index={index} secIndex={xindex} addable={false} exlink={link} link={xlink}/>
                </>)
            }

        </div>
    }

    function moveData(component,data, index, action = 'up') {
        try {
            let inx = index - 1
            if (action != 'up') {
                inx = index + 1
            }

            if (index == 0 && action == 'up') {
                return;
            }

            let el = data[index].menu_order;

            data[index].menu_order = data[inx].menu_order;
            data.map((item, i) => {
                if (i == (inx)) {
                    item.menu_order = el
                }
            })

            console.log(data)
            setVV(Date.now())
        }catch (e) {
            console.error(e)
        }
    }

    return(
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">فهرست ها</span>
                </div>

                <div className="main-content mx-auto p-5 mt-6">
                    <div className="grid grid-cols-1 lg:grid-cols-5 xl:grid-cols-7 gap-4">

                        {selected === null && <div className="lg:col-span-2 border border-gray-7 rounded-md p-4 h-max form">
                            <div className="grid gap-5 justify-items-stretch">
                                <label className="!grid">
                                    <span className="text-right mb-2">عنوان</span>
                                    <input onKeyUp={ e => {
                                        newMenu.title = e.target.value
                                        setNewMenu(newMenu)
                                    } } className="!mr-0" type="text" placeholder="عنوان را وارد کنید"/>
                                </label>
                                <label className="!grid">
                                    <span className="text-right mb-2">آدرس پیشفرض</span>
                                    <input onKeyUp={ e => {
                                        newMenu.url = e.target.value
                                        setNewMenu(newMenu)
                                    } } className="!mr-0 text-left" dir={`ltr`} type="text" placeholder="آدرس را وارد کنید"/>
                                </label>
                                <label className="!grid">
                                    <span className="text-right mb-2">نوع</span>
                                    <select onChange={ e => {
                                        newMenu.is_megamenu = e.target.value
                                        setNewMenu(newMenu)
                                    } } className="!mr-0">
                                        <option value="0">پیشفرض</option>
                                        <option value="1">مگامنو</option>
                                    </select>
                                </label>
                                <label className="!grid">
                                    <span className="text-right mb-2">موقعیت</span>
                                    <select onChange={ e => {
                                        alert(e.target.value)
                                        newMenu.location = e.target.value
                                        setNewMenu(newMenu)
                                    } } className="!mr-0">
                                        <option value="main_navigation">پیشفرض (هدر)</option>
                                        <option value="footer">فوتر</option>
                                    </select>
                                </label>
                                <button onClick={createMenu} className="btn-green w-max rounded mr-auto py-2">افزودن</button>
                            </div>
                        </div>}

                        {(selected == null) && <div className="lg:col-span-3 xl:col-span-5 border border-gray-7 rounded-md p-2">

                            {isLoading ? (
                                <div className="flex justify-center items-center py-10">
                                    <FontAwesomeIcon icon={faSpinner} spin className="text-3xl text-purple-600" />
                                </div>
                            ) : (
                                <table className="table-borderd-tr table-auto w-full mt-2 mx-auto block sm:table">
                                    <thead>
                                    <tr>
                                        <td>عنوان</td>
                                        <td>آدرس</td>
                                        <td>نوع</td>
                                        <td>مکان</td>
                                        <td>فرزند</td>
                                        <td></td>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {data && tableData.map( item => (<tr key={item._id}>
                                        <td><input
                                            onKeyUp={ (v) => {
                                                changeSelectedItem(v.target.value, 'title', item);
                                            } }
                                            className="border border-gray-3 rounded py-1 w-full px-3" type="text" defaultValue={item.title}/></td>
                                        <td><input
                                            onKeyUp={ (v) => {
                                                changeSelectedItem(v.target.value, 'url', item);
                                            } }
                                            dir={`ltr`} className="border text-left text-13 border-gray-3 rounded py-1 w-full px-3" type="text" defaultValue={item.url}/></td>
                                        <td>
                                            <select
                                                onChange={ (v) => {
                                                    changeSelectedItem(v.target.value, 'is_megamenu', item);
                                                } }
                                                className={`input-x`}>
                                                <option selected={!item.is_megamenu} value="0">پیشفرض</option>
                                                <option selected={item.is_megamenu} value="1">مگامنو</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                onChange={ (v) => {
                                                    changeSelectedItem(v.target.value, 'location', item);
                                                } }
                                                className={`input-x`}>
                                                <option selected={item.location === `main_navigation`} value="main_navigation">هدر</option>
                                                <option selected={item.location === `footer`} value="footer">فوتر</option>
                                                <option selected={item.location === `price_list_menu`} value="price_list_menu">لیست قیمت</option>
                                            </select>
                                        </td>
                                        <td>{item.children?.length ?? 0}</td>
                                        <td className="flex justify-center items-center py-1">

                                            <button className="mx-2 leading-3 my-1" onClick={ async () => {await editHandle(item)} } title="ویرایش"><FontAwesomeIcon
                                                className="bg-green-1 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></button>

                                            <button className="mx-2 leading-3 my-1" onClick={ () => {
                                                setSelected(item)
                                            } } title="مشاهده / ویرایش"><FontAwesomeIcon
                                                className="bg-purple-1 text-white p-1 w-4 h-4 rounded" icon={faEye}/></button>

                                            <button className="mx-2 leading-3 my-1" onClick={ async () => {
                                                if (!window.confirm('آیا از حذف این آیتم اطمینان دارید؟')) {
                                                    return;
                                                }
                                                await ApiRoute.menu.delete.fn(item._id)
                                                setUpdateData(Date.now())
                                            } } title="حذف"><FontAwesomeIcon
                                                className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                                        </td>
                                    </tr>) )}

                                    </tbody>
                                </table>
                            )}
                        </div>}


                        {selected && <div className="col-span-12 border border-gray-7 rounded-md p-2">
                            <div className={`px-4 py-1 bg-[#000] text-white flex justify-between items-center`}>

                                <div className={`flex items-center gap-1`}>
                                    <FontAwesomeIcon onClick={ () => {
                                        setOpenChildren(null)
                                        setSelected(null)
                                    } } className="bg-white cursor-pointer text-gray-2 p-1 w-4 h-4 rounded" icon={faArrowRight}/>
                                    <div>ویرایش فهرست</div>
                                </div>

                                <div className={`flex items-center gap-3`}>
                                    <FontAwesomeIcon onClick={async () => {
                                        await ApiRoute.menu.updateChildren.fn(selected._id , selected)
                                    }} className="bg-green-1 cursor-pointer text-white p-1 w-4 h-4 rounded" icon={faSave}/>
                                    <FontAwesomeIcon onClick={() => {
                                        selected.children.push({
                                            title:'عنوان منوی یک',
                                            url:'#',
                                            id:uuidv4(),
                                            menu_order:getLastOrder(selected.children),
                                            links:[]
                                        })
                                        setSelected(selected)
                                        setVV(Date.now())
                                    }} className="bg-blue-1 cursor-pointer text-white p-1 w-4 h-4 rounded" icon={faPlus}/>
                                </div>
                            </div>

                            <div className={`mt-4 text-13`}>

                                {selected.children?.length > 0 && selected.children.sort((firstItem, secondItem) => firstItem.menu_order - secondItem.menu_order).map( (item , index) => (
                                    <div className={`border border-gray-4 px-2 mb-2 rounded`} key={item.id}>
                                       <div className={`flex flex-col`}>


                                           <div className={`flex items-center gap-2 justify-between`}>
                                               <input  onKeyUp={ e => {
                                                   item.title = e.target.value;
                                               } } className={`input-x`} defaultValue={item.title}/>
                                               <input onKeyUp={ e => {
                                                   item.url = e.target.value;
                                               } } className={`input-x text-left`} dir={`ltr`} defaultValue={item.url}/>
                                               <FontAwesomeIcon onClick={() => {
                                                   if (index === openChildren){
                                                       setOpenChildren(null)
                                                   }else {
                                                       setOpenChildren(index)
                                                   }
                                               }} className="bg-purple-1 text-white p-1 w-4 h-4 rounded" icon={faEye}/>

                                               <FontAwesomeIcon onClick={() => {
                                                   moveData(selected,selected.children,index,'down')
                                               }} className="bg-red-2 text-white p-1 w-4 h-4 rounded" icon={faArrowDown}/>
                                               <FontAwesomeIcon onClick={() => {
                                                   moveData(selected,selected.children,index,'up')
                                               }} className="bg-red-2 text-white p-1 w-4 h-4 rounded" icon={faArrowUp}/>

                                               <FontAwesomeIcon onClick={() => {
                                                   console.log(selected.children[index])
                                                   selected.children[index].links.push({
                                                       title:'عنوان تست',
                                                       url:'#',
                                                       id:uuidv4(),
                                                       menu_order:getLastOrder(selected.children[index].links),
                                                       links:[]
                                                   })
                                                   setOpenChildren(index)
                                                   setSelected(selected)
                                                   setVV(Date.now())
                                               }} className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faPlus}/>

                                               <FontAwesomeIcon onClick={ () => {
                                                   selected.children.splice(index, 1);
                                                   setVV(Date.now())
                                               } } className="bg-red-1 text-gray-2 p-1 w-4 h-4 rounded" icon={faClose}/>

                                           </div>


                                           {/*{openChildren === index && <Links mainIndex={index} index={index} items={item.links}/>}*/}
                                           {openChildren === index &&

                                               item.links?.length > 0 && item.links.sort((firstItem, secondItem) => firstItem.menu_order - secondItem.menu_order).map((link,index) => <>
                                                   <Xxx shit={item} mainIndex={index} index={index} exlink={link} secIndex={index} addable={false} link={link}/>
                                               </>)

                                           }


                                       </div>
                                    </div>
                                ) )}
                            </div>

                        </div>}

                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Menus